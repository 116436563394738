import ProductList from '../../../../components/commercetools-ui/products/product-list';
import NoResultsFound from '../../../../components/commercetools-ui/products/product-list/algolia-search/no-results-found';
import { useContext } from 'react';
import { UserContext } from 'frontastic/lib/renderer';

function ProductListTastic({ data }) {
  if (!data?.data?.dataSource) return <></>;

  const { items, facets, category, previousCursor, nextCursor, total, totalItems } = data.data.dataSource;
  return parseInt(total) > 0 ? (
    <ProductList
      products={items}
      totalProducts={total}
      facets={facets}
      category={items[0]?.categories[0]?._url}
      previousCursor={previousCursor}
      nextCursor={nextCursor}
      searching={false}
      facetKey={''}
    />
  ) : (
    <NoResultsFound />
  );
}

export default ProductListTastic;
