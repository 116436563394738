import React, { useState } from 'react';
import Markdown from 'components/commercetools-ui/content/markdown';
import Typography from 'components/commercetools-ui/typography';
// import Accordion from './accordion';
// import AccordionItem from './accordionItem';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { useAccount } from 'frontastic';
import { useRouter } from 'next/router';
// const AccordionItem = ({ header, ...rest }) => (
//   <Item
//     {...rest}
//     header={({ state: { isEnter } }) => (
//       <>
//         {header}
//         <img
//           className={`ml-auto transition-transform duration-200 ease-in-out ${
//             isEnter && "rotate-180"
//           }`}
//           src={chevron}
//           alt="Chevron"
//         />
//       </>
//     )}
//     buttonProps={{
//       className: ({ isEnter }) =>
//         `flex w-full  ${
//           isEnter && ""
//         }`
//     }}
//   />
// );

const ContentBuyerSeller = ({ data }) => {
  const [buyer, setBuyer] = useState(true);
  const [seller, setSeller] = useState(false);
  const buyerHandler = () => {
    setBuyer(!buyer);
    setSeller(!seller);
  };
  const sellerHandler = () => {
    setBuyer(!buyer);
    setSeller(!seller);
  };
  //   const title = data?.data?.dataSource??.fields?.t?itle;
  //   const body = data?.data?.dataSource??.fields?.b?ody;
  const model2 = data?.data?.dataSource?.attributes;
  const { account } = useAccount();
  const router = useRouter();
  const requestForQuoteUrl = () => {
   if (!account) {
      router.push({pathname:'/login',query: router.asPath});
    } else {
      router.push('quotes/request-for-quote')
    }
  };
  return (
    <>
      {/* <pre className="mt-2 text-lg">{JSON.stringify(data?.data?.dataSource?, null, 2)}?</pre> */}
      <div className="container-fluid bs">
        <div className="row">
          <div className="buyer-seller justify-center">
          <button onClick={buyerHandler} className={`${buyer ? 'btn-buyer' : 'btn-seller'}`}>
              For Buyer
            </button>
            <button onClick={sellerHandler} className={`${seller ? 'btn-buyer' : 'btn-seller'}`}>
              For Seller
            </button>
          </div>
          {buyer && (
            <div className="container-fluid buyer">
              <div className="row">
                <div className="homepageBuySellWrap">
                  <div className="buyers-tab">
                    <div className="num1">
                      <p>{data?.data?.dataSource?.attributes?.buyer.content.fields.counterTitle[0]}</p>
                      <span>{data?.data?.dataSource?.attributes?.buyer.content.fields.counterDescription[0]}</span>
                    </div>
                    <div className="num1">
                      <p>{data?.data?.dataSource?.attributes?.buyer.content.fields.counterTitle[1]}</p>
                      <span>{data?.data?.dataSource?.attributes?.buyer.content.fields.counterDescription[1]}</span>
                    </div>
                    <div className="num1">
                      <p>{data?.data?.dataSource?.attributes?.buyer.content.fields.counterTitle[2]}</p>
                      <span>{data?.data?.dataSource?.attributes?.buyer.content.fields.counterDescription[2]}</span>
                    </div>
                    <div className="num1">
                      <p>{data?.data?.dataSource?.attributes?.buyer.content.fields.counterTitle[3]}</p>
                      <span>{data?.data?.dataSource?.attributes?.buyer.content.fields.counterDescription[3]}</span>
                    </div>
                    <div className="num1">
                      <p>{data?.data?.dataSource?.attributes?.buyer.content.fields.counterTitle[4]}</p>
                      <span>{data?.data?.dataSource?.attributes?.buyer.content.fields.counterDescription[4]}</span>
                    </div>
                  </div>



                  <div className="buyerSellerDesktopAcc md:grid lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 2xl:gap-[20px] ">
                    <div className="col-span-1">
                    <div className="buyers-works">
                    <div className="buyers-title">
                      <h1>{data?.data?.dataSource?.attributes?.buyer.content.fields.title}</h1>
                    </div>
                    <div className="buyers-desc">
                      <p>{data?.data?.dataSource?.attributes?.buyer.content.fields.description}</p>
                    </div>
                  </div>
                <Accordion className=''>
                  <AccordionItem header={data?.data?.dataSource?.attributes?.buyer.content.fields.title1} initialEntered>
                  {data?.data?.dataSource?.attributes?.buyer.content.fields.description1}
                  </AccordionItem>

                  <AccordionItem header={data?.data?.dataSource?.attributes?.buyer.content.fields.title2} initialEntered>
                  {data?.data?.dataSource?.attributes?.buyer.content.fields.description2}
                  </AccordionItem>

                  <AccordionItem header={data?.data?.dataSource?.attributes?.buyer.content.fields.title3} initialEntered>
                  {data?.data?.dataSource?.attributes?.buyer.content.fields.description3}
                  </AccordionItem>
                </Accordion>
                    </div>

                    <div className="accordion-img col-span-1">
                      <img
                        src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="accordion  buyerSellerMobileAcc">

                  <div className="buyers-works">
                    <div className="buyers-title">
                      <h1>{data?.data?.dataSource?.attributes?.buyer.content.fields.title}</h1>
                    </div>
                    <div className="buyers-desc">
                      <p>{data?.data?.dataSource?.attributes?.buyer.content.fields.description}</p>
                    </div>
                  </div>
                    <Accordion className=''>
                      <AccordionItem header={data?.data?.dataSource?.attributes?.buyer.content.fields.title1} initialEntered>
                        {data?.data?.dataSource?.attributes?.buyer.content.fields.description1}
                        <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                      </AccordionItem>

                      <AccordionItem header={data?.data?.dataSource?.attributes?.buyer.content.fields.title2} initialEntered>
                        {data?.data?.dataSource?.attributes?.buyer.content.fields.description2}
                        <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                      </AccordionItem>

                      <AccordionItem header={data?.data?.dataSource?.attributes?.buyer.content.fields.title3} initialEntered>
                        {data?.data?.dataSource?.attributes?.buyer.content.fields.description3}
                        <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                      </AccordionItem>
                    </Accordion>
                    {/* <Accordion defaultIndex="1" onItemClick={console.log}>
                      <AccordionItem label={data?.data?.dataSource?.attributes?.buyer.content.fields.title1} index="1">
                        {data?.data?.dataSource?.attributes?.buyer.content.fields.description1}
                        <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                      </AccordionItem>
                      <AccordionItem label={data?.data?.dataSource?.attributes?.buyer.content.fields.title2} index="2">
                        {data?.data?.dataSource?.attributes?.buyer.content.fields.description2}
                        <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                      </AccordionItem>
                      <AccordionItem label={data?.data?.dataSource?.attributes?.buyer.content.fields.title3} index="3">
                        {data?.data?.dataSource?.attributes?.buyer.content.fields.description3}
                        <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                      </AccordionItem>
                    </Accordion> */}
                  </div>

                  <div className="buyers-process">
                    <div className="icon1">
                      <img
                        className=""
                        src={data?.data?.dataSource?.attributes?.buyerIcons.content.fields.icon.fields.file.url}
                        alt=""
                      />
                      <h3 className="process-title">
                        {data?.data?.dataSource?.attributes?.buyerIcons.content.fields.title}
                      </h3>
                      <p>{data?.data?.dataSource?.attributes?.buyerIcons.content.fields.description}</p>
                    </div>

                    <div className="icon2">
                      <img
                        className=""
                        src={data?.data?.dataSource?.attributes?.buyerIcons2.content.fields.icon.fields.file.url}
                        alt=""
                      />
                      <h3 className="process-title">
                        {data?.data?.dataSource?.attributes?.buyerIcons2.content.fields.title}
                      </h3>
                      <p>{data?.data?.dataSource?.attributes?.buyerIcons2.content.fields.description}</p>
                    </div>
                    <div className="icon3">
                      <img
                        className=""
                        src={data?.data?.dataSource?.attributes?.buyerIcons3.content.fields.icon.fields.file.url}
                        alt=""
                      />
                      <h3 className="process-title">
                        {data?.data?.dataSource?.attributes?.buyerIcons3.content.fields.title}
                      </h3>
                      <p>{data?.data?.dataSource?.attributes?.buyerIcons3.content.fields.description}</p>
                    </div>
                    <div className="icon4">
                      <img
                        className=""
                        src={data?.data?.dataSource?.attributes?.buyerIcons4.content.fields.icon.fields.file.url}
                        alt=""
                      />
                      <h3 className="process-title">
                        {data?.data?.dataSource?.attributes?.buyerIcons4.content.fields.title}
                      </h3>
                      <p>{data?.data?.dataSource?.attributes?.buyerIcons4.content.fields.description}</p>
                    </div>
                  </div>

                  <div>
                    <img
                      className="lower"
                      src={
                        data?.data?.dataSource?.attributes?.buyerIconsBackground.content.fields.gallery[1].fields.file
                          .url
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="upper"
                      src={
                        data?.data?.dataSource?.attributes?.buyerIconsBackground.content.fields.gallery[0].fields.file
                          .url
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="lower1"
                      src={
                        data?.data?.dataSource?.attributes?.buyerIconsBackground.content.fields.gallery[1].fields.file
                          .url
                      }
                      alt=""
                    />
                  </div>
                  <div className="product-enq">
                    <div className="buyer-enquiry">
                      {data?.data?.dataSource?.attributes?.buyerBanner.content.fields.title}
                    </div>
                    <button className="btn-product" onClick={requestForQuoteUrl}>
                       {data?.data?.dataSource?.attributes?.buyerBanner.content.fields.buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {seller && (
            <div className="container-fluid buyer">
              <div className="row">
                <div className="homepageBuySellWrap">
                  <div className="buyers-tab">
                    <div className="num1">
                      <p>{data?.data?.dataSource?.attributes?.seller.content.fields.counterTitle[0]}</p>
                      <span>{data?.data?.dataSource?.attributes?.seller.content.fields.counterDescription[0]}</span>
                    </div>
                    <div className="num1">
                      <p>{data?.data?.dataSource?.attributes?.seller.content.fields.counterTitle[1]}</p>
                      <span>{data?.data?.dataSource?.attributes?.seller.content.fields.counterDescription[1]}</span>
                    </div>
                    <div className="num1">
                      <p>{data?.data?.dataSource?.attributes?.seller.content.fields.counterTitle[2]}</p>
                      <span>{data?.data?.dataSource?.attributes?.seller.content.fields.counterDescription[2]}</span>
                    </div>
                    <div className="num1">
                      <p>{data?.data?.dataSource?.attributes?.seller.content.fields.counterTitle[3]}</p>
                      <span>{data?.data?.dataSource?.attributes?.seller.content.fields.counterDescription[3]}</span>
                    </div>
                    {/* <div className="num1">
                      <p>{data?.data?.dataSource?.attributes?.seller.content.fields.counterTitle[4]}</p>
                      <span>{data?.data?.dataSource?.attributes?.seller.content.fields.counterDescription[4]}</span>
                    </div> */}
                  </div>


                  <div className="buyerSellerDesktopAcc md:grid lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 2xl:gap-[20px] ">

                    <div className="col-span-1">
                    <div className="buyers-works">
                    <div className="buyers-title">
                      <h1>{data?.data?.dataSource?.attributes?.seller.content.fields.title}</h1>
                    </div>
                    <div className="buyers-desc">
                      <p>{data?.data?.dataSource?.attributes?.seller.content.fields.description}</p>
                    </div>
                  </div>

                <Accordion className="col-span-1">
                  <AccordionItem header={data?.data?.dataSource?.attributes?.seller.content.fields.title1} initialEntered>
                  {data?.data?.dataSource?.attributes?.seller.content.fields.description1}
                  </AccordionItem>

                  <AccordionItem header={data?.data?.dataSource?.attributes?.seller.content.fields.title2} initialEntered>
                  {data?.data?.dataSource?.attributes?.seller.content.fields.description2}
                  </AccordionItem>

                  <AccordionItem header={data?.data?.dataSource?.attributes?.seller.content.fields.title3} initialEntered>
                  {data?.data?.dataSource?.attributes?.seller.content.fields.description3}
                  </AccordionItem>
                </Accordion>
                  {/* <Accordion className="col-span-1" defaultIndex="1" onItemClick={console.log}>
                      <AccordionItem label={data?.data?.dataSource?.attributes?.seller.content.fields.title1} index="1">
                        {data?.data?.dataSource?.attributes?.seller.content.fields.description1}
                      </AccordionItem>
                      <AccordionItem label={data?.data?.dataSource?.attributes?.seller.content.fields.title2} index="2">
                        {data?.data?.dataSource?.attributes?.seller.content.fields.description2}
                      </AccordionItem>
                      <AccordionItem label={data?.data?.dataSource?.attributes?.seller.content.fields.title3} index="3">
                        {data?.data?.dataSource?.attributes?.seller.content.fields.description3}
                      </AccordionItem>
                    </Accordion> */}
                    </div>

                    <div className="accordion-img col-span-1">
                      <img
                        src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="accordion buyerSellerMobileAcc">
                  <div className="buyers-works">
                    <div className="buyers-title">
                      <h1>{data?.data?.dataSource?.attributes?.seller.content.fields.title}</h1>
                    </div>
                    <div className="buyers-desc">
                      <p>{data?.data?.dataSource?.attributes?.seller.content.fields.description}</p>
                    </div>
                  </div>

                  <Accordion >
                  <AccordionItem header={data?.data?.dataSource?.attributes?.seller.content.fields.title1} initialEntered>
                  {data?.data?.dataSource?.attributes?.seller.content.fields.description1}
                  <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                  </AccordionItem>

                  <AccordionItem header={data?.data?.dataSource?.attributes?.seller.content.fields.title2} initialEntered>
                  {data?.data?.dataSource?.attributes?.seller.content.fields.description2}
                  <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                  </AccordionItem>

                  <AccordionItem header={data?.data?.dataSource?.attributes?.seller.content.fields.title3} initialEntered>
                  {data?.data?.dataSource?.attributes?.seller.content.fields.description3}
                  <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                  </AccordionItem>
                </Accordion>

                    {/* <Accordion defaultIndex="1" onItemClick={console.log}>
                      <AccordionItem label={data?.data?.dataSource?.attributes?.seller.content.fields.title1} index="1">
                        {data?.data?.dataSource?.attributes?.seller.content.fields.description1}
                        <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                      </AccordionItem>
                      <AccordionItem label={data?.data?.dataSource?.attributes?.seller.content.fields.title2} index="2">
                        {data?.data?.dataSource?.attributes?.seller.content.fields.description2}
                        <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                      </AccordionItem>
                      <AccordionItem label={data?.data?.dataSource?.attributes?.seller.content.fields.title3} index="3">
                        {data?.data?.dataSource?.attributes?.seller.content.fields.description3}
                        <img
                          className="mobile-img"
                          src={data?.data?.dataSource?.attributes?.buyer.content.fields.image2.fields.file.url}
                          alt=""
                        />
                      </AccordionItem>
                    </Accordion> */}
                  </div>

                  <div className="buyers-process">
                    <div className="icon1">
                      <img
                        className=""
                        src={data?.data?.dataSource?.attributes?.sellerIcons.content.fields.icon.fields.file.url}
                        alt=""
                      />
                      <h3 className="process-title">
                        {data?.data?.dataSource?.attributes?.sellerIcons.content.fields.title}
                      </h3>
                      <p>{data?.data?.dataSource?.attributes?.sellerIcons.content.fields.description}</p>
                    </div>

                    <div className="icon2">
                      <img
                        className=""
                        src={data?.data?.dataSource?.attributes?.sellerIcons2.content.fields.icon.fields.file.url}
                        alt=""
                      />
                      <h3 className="process-title">
                        {data?.data?.dataSource?.attributes?.sellerIcons2.content.fields.title}
                      </h3>
                      <p>{data?.data?.dataSource?.attributes?.sellerIcons2.content.fields.description}</p>
                    </div>
                    <div className="icon3">
                      <img
                        className=""
                        src={data?.data?.dataSource?.attributes?.sellerIcons3.content.fields.icon.fields.file.url}
                        alt=""
                      />
                      <h3 className="process-title">
                        {data?.data?.dataSource?.attributes?.sellerIcons3.content.fields.title}
                      </h3>
                      <p>{data?.data?.dataSource?.attributes?.sellerIcons3.content.fields.description}</p>
                    </div>
                    <div className="icon4">
                      <img
                        className=""
                        src={data?.data?.dataSource?.attributes?.sellerIcons4.content.fields.icon.fields.file.url}
                        alt=""
                      />
                      <h3 className="process-title">
                        {data?.data?.dataSource?.attributes?.sellerIcons4.content.fields.title}
                      </h3>
                      <p>{data?.data?.dataSource?.attributes?.sellerIcons4.content.fields.description}</p>
                    </div>
                  </div>

                  <div>
                    <img
                      className="lower"
                      src={
                        data?.data?.dataSource?.attributes?.buyerIconsBackground.content.fields.gallery[1].fields.file
                          .url
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="upper"
                      src={
                        data?.data?.dataSource?.attributes?.buyerIconsBackground.content.fields.gallery[0].fields.file
                          .url
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="lower1"
                      src={
                        data?.data?.dataSource?.attributes?.buyerIconsBackground.content.fields.gallery[1].fields.file
                          .url
                      }
                      alt=""
                    />
                  </div>
                  <div className="product-enq">
                    <div className="buyer-enquiry">
                      {data?.data?.dataSource?.attributes?.buyerBanner.content.fields.title}
                    </div>
                    <button className="btn-product" onClick={requestForQuoteUrl}>
                       {data?.data?.dataSource?.attributes?.buyerBanner.content.fields.buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <p className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:text-4xl">
        {data?.data?.dataSource?.title}
   ?   </p>
      <div>title is &quot;{title}&quot;</div>
      <div>body is &quot;{body}&quot;</div>
      hello
      {model?.bannerTitle?.content}
      <pre className="mt-2 text-lg">{JSON.stringify(data?.data?.dataSource?, null, 2)}?</pre>
      <pre className="mt-2 text-lg">{JSON.stringify(data?.data?.dataSource?, null, 2)}?</pre> */}
    </>
  );
};

export default ContentBuyerSeller;
