import React, { useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import {
  STATIC_GET_SELLER_DETAILS,
  STATIC_QUOTE_DETAILS,
  STATIC_VIEW_QUOTE,
  STATIC_QUOTE_ACTION,
} from 'helpers/constants/statisURLs';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { backendAPIClient, commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import toast from 'react-hot-toast';
import { addItem } from 'frontastic/actions/cart';
import QuoteProductDetail from './quoteProductDetail';

interface Props {
  selectedQuote: any;
  onReject: () => void;
}

export function QuoteDetails({ selectedQuote, onReject }) {
  const [isAccepted, setIsAccepted] = useState(false);
  const [quoteDetails, setQuoteDetails] = useState<any>();
  const [message, setMessage] = useState('');

  const [quoteID, setQuoteID] = useState<any>();
  const [sellerDetails, setSellerDetails] = useState<any>();
  const [deactivteRejectBtn, setDeactivteRejectBtn] = useState(false);
  const router = useRouter();

  const onAccept = async () => {
    if (isAccepted) {
      let apiCallCount = 0;
      for (let i = 0; i < quoteDetails?.lineItems?.length; i++) {
        const variantData = {
          sku: quoteDetails?.lineItems[i]?.variant?.sku,
          count: quoteDetails?.lineItems[i]?.quantity,
        };
        await addItem(variantData, quoteDetails?.lineItems[i]?.quantity, '', '', '').then((response) => {
          apiCallCount++;
        });
      }
      router.push('/checkout');
      return;
    }
    toast.success('Quote Accepted successfully!');
    if (!quoteDetails) {
      return;
    }
    const reqBody = {
      quoteId: quoteID,

      quoteState: 'Accepted',
    };

    try {
      const acceptQuote = await backendAPIClient.post(STATIC_QUOTE_ACTION, reqBody);
      if (acceptQuote.status.toString() === '200') {
        const _quoteDetails = acceptQuote?.data;
        setQuoteDetails(_quoteDetails);
      }
      setIsAccepted(true);
    } catch (err) {}
  };
  useEffect(() => {
    quoteDetailsHandler();
  }, []);

  useEffect(() => {
    qouteView();
  }, []);

  const reqBody1 = {
    quoteRequestId: selectedQuote?.id,
  };

  const reqBody = {
    quoteId: selectedQuote?.id,
    quoteNumber: selectedQuote?.version,
  };
  const quoteDetailsHandler = async () => {
    try {
      const response = await backendAPIClient.post(STATIC_VIEW_QUOTE, reqBody1);

      const _quoteDetails = response?.data?.quote;
      setQuoteDetails(_quoteDetails);

      const sert = response.data.quote.id;

      setQuoteID(sert);

      //get seller details.
      const sellerCode: string = _quoteDetails?.lineItems[0]?.variant?.attributes.find((el) => el.name == 'vendor-code')
        ?.value?.['en-IN'];

      if (sellerCode) {
        const sellerDetailRes = await backendAPIClient.get(`${STATIC_GET_SELLER_DETAILS}/?vendorCode=${sellerCode}`);
        setSellerDetails(sellerDetailRes?.data);
      }
      setDeactivteRejectBtn(_quoteDetails?.quoteRequestState === 'Cancelled');
      setIsAccepted(_quoteDetails?.quoteRequestState === 'Accepted');
    } catch (error) {}
  };

  useEffect(() => {
    console.log('Sert:', quoteID);
  }, [quoteID]);

  const qouteView = async () => {
    try {
      const response1 = await backendAPIClient.post(STATIC_QUOTE_DETAILS, reqBody);
      const _quoteDetails1 = response1?.data?.quote;
      const sellerCode1: string = _quoteDetails1?.lineItems[0]?.variant?.attributes.find(
        (el) => el.name == 'vendor-code',
      )?.value?.['en-IN'];
    } catch (error) {}
  };

  const getFormattedDate = (dateValue) => {
    const x = new Date(dateValue * 1000);
    return `${x.getDate()}/${x.getMonth() + 1}/${x.getFullYear()}`;
  };

  const onClickReject = async () => {
    if (!quoteDetails) {
      return;
    }
    const reqBody = {
      quoteId: quoteID,

      quoteState: 'Declined',
    };

    try {
      const rejectQuote = await backendAPIClient.post(STATIC_QUOTE_ACTION, reqBody);
      setMessage('');

      if (rejectQuote.status.toString() === '200' || '201') {
        const _quoteDetails = rejectQuote?.data;
        toast.success('Quote Rejected successfully!');
        setQuoteDetails(_quoteDetails);
        setDeactivteRejectBtn(true);
        router.push('/quotes/request-for-quote');

        onReject();
      }
    } catch (err) {}
  };

  const respondreqBody1 = {
    quoteId: quoteID,

    quoteState: 'DeclinedForRenegotiation',

    buyerComments: message,
  };

  const onRespondBack = async () => {
    const respondBack = await backendAPIClient.post(STATIC_QUOTE_ACTION, respondreqBody1);
    setMessage('');
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <div className="">
      <div className="quote-details-section">
        <div className="quote-title">
          <p className="quote-details-heading text-[20px] font-semibold tracking-[1px] text-[#003769] lg:text-[24px]">
            Quote Details
          </p>
        </div>
        <div className="quote-details-section-body">
          <div className="quote-details-row">
            <div className="quote-details-col-flex">
              <div className="quote-details-col-key">RFQ Number :</div>
              <div className="quote-details-col-val">{quoteDetails?.custom?.fields?.quoteNumber}</div>
            </div>
            <div className="quote-details-col">
              <div className="quote-details-col-key">RFQ Name :</div>
              <div className="quote-details-col-val">{quoteDetails?.custom?.fields?.RFQTitle}</div>
            </div>
            <div className="quote-details-col">
              <div className="quote-details-col-key">Quote Version :</div>
              <div className="quote-details-col-val">{quoteDetails?.version}</div>
            </div>
          </div>
          <div className="quote-details-row">
            <div className="quote-details-col-flex">
              <div className="quote-details-col-key">Seller Name :</div>
              <div className="quote-details-col-val">
                <span className="quote-details-col-val-seller">
                  {quoteDetails?.lineItems?.[0]?.variant?.attributes?.find((i) => i.name === 'vendor-name')?.value?.[
                    'en-IN'
                  ] || 'N/A'}
                </span>
              </div>
            </div>
            <div className="quote-details-col">
              <div className="quote-details-col-key">Quote Expiry :</div>
              <div className="quote-details-col-val">{quoteDetails?.['expiry-date']}</div>
            </div>
            <div className="quote-details-col">
              <div className="quote-details-col-key">Quote Status :</div>
              <div className="quote-details-col-val quote-details-col-val-status">{quoteDetails?.quoteState}</div>
            </div>
          </div>
          <div className="quote-details-row">
            <div className="quote-details-col-flex m-quote-details-col-flex">
              <div>
                <div style={{ display: 'flex' }}>
                  <div className="quote-details-col-key">Date Created :</div>
                  <div className="quote-details-col-val">{getFormattedDate(quoteDetails?.createdAt)}</div>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', marginTop: '14px' }}>
                  <div className="quote-details-col-key">Date Updated :</div>
                  <div className="quote-details-col-val">{getFormattedDate(quoteDetails?.lastModifiedAt)}</div>
                </div>
              </div>
            </div>
            <div className="quote-details-col-flex">
              <div className="quote-details-col-key">Seller :</div>
              <div className="quote-details-col-val">
                {sellerDetails &&
                  `${sellerDetails?.value?.address?.streetName}, ${sellerDetails?.value?.address?.city}, ${sellerDetails?.value?.address?.state}, ${sellerDetails?.value?.address?.country}`}
              </div>
            </div>
            <div className="quote-details-col-flex">
              <div className="quote-details-col-key">RFQ Description :</div>
              <div className="quote-details-col-val quote-details-comment">{quoteDetails?.comment}</div>
            </div>
          </div>
        </div>
      </div>
      {quoteDetails &&
        quoteDetails?.lineItems?.map((product) => <QuoteProductDetail key={product.id} product={product} />)}
      <hr />
      <div className="xs:flex-none mx-[18px] mt-[40px] justify-around text-[14px] md:flex">
        <div className="xs:flex-none pt-[10px] text-left md:flex-[75%]">
          <h1 className="font-bold">Terms and Conditions</h1>
          <ul>
            <li>* All prices shown exclude GST</li>
            <li>* All information is subject to change without notice</li>
          </ul>
        </div>
        <br />
        <div className="xs:flex-none md:flex-[25%]">
          <div className="flex">
            <div className="flex-1 text-left">
              <ul className="xs:text-[12px] md:text-[18px]">
                <li>Subtotal</li>
                <li>Tax</li>
                <li className="xs:text-[14px] mt-5  md:text-[18px]">Total</li>
              </ul>
            </div>
            <div className="xs:text-left flex-1 md:text-right">
              <ul className="xs:text-[12px] font-bold md:text-[18px]">
                <li>{CurrencyHelpers.formatForCurrency(quoteDetails?.taxedPrice?.totalGross)}</li>
                <li>{CurrencyHelpers.formatForCurrency(quoteDetails?.taxedPrice?.totalTax)}</li>
                <li className="xs:text-[14px] mt-5 md:text-[18px]">
                  {CurrencyHelpers.formatForCurrency(quoteDetails?.taxedPrice?.totalNet)}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="my-10 mx-[20px] flex flex-wrap-reverse justify-end gap-5 text-center md:flex-nowrap">
          {quoteDetails?.quoteState !== 'Accepted' &&
            quoteDetails?.quoteState !== 'DeclinedForRenegotiation' &&
            quoteDetails?.quoteState !== 'Rejected' && (
              <a className="link link-primary w-[100%] self-center px-1 md:w-auto" onClick={onRespondBack} href="#">
                Respond back
              </a>
            )}
          {quoteDetails?.quoteState !== 'Accepted' &&
            quoteDetails?.quoteState !== 'DeclinedForRenegotiation' &&
            quoteDetails?.quoteState !== 'Rejected' && (
              <div>
                <button
                  className="btn btn-outline w-[100%] md:w-auto"
                  onClick={onClickReject}
                  disabled={deactivteRejectBtn}
                >
                  Reject
                </button>
              </div>
            )}

          {quoteDetails?.quoteState !== 'Accepted' &&
            quoteDetails?.quoteState !== 'DeclinedForRenegotiation' &&
            quoteDetails?.quoteState !== 'Rejected' && (
              <button className="btn-blue btn w-[100%] md:w-auto" onClick={onAccept} disabled={deactivteRejectBtn}>
                {isAccepted ? 'Continue to Checkout' : 'Accept'}
              </button>
            )}
        </div>
        {quoteDetails?.quoteState !== 'Accepted' &&
          quoteDetails?.quoteState !== 'DeclinedForRenegotiation' &&
          quoteDetails?.quoteState !== 'Rejected' && (
            <div className="flex justify-end">
              <input type="text" value={message} onChange={handleMessageChange} />
            </div>
          )}
      </div>
    </div>
  );
}
