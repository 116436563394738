import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { largeDesktop, mobile } from 'helpers/utils/screensizes';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

function VendorListItems({ letter, items, setOpenTab, index }) {
  const [isMobileSize] = useMediaQuery(mobile);
  const [isLargeDesktop] = useMediaQuery(largeDesktop);
  const [ref, inView] = useInView({
    rootMargin: !isLargeDesktop ? (!isMobileSize ? '-30% 0px -30% 0px' : '-42% 0px -42% 0px') : '-40% 0px -40% 0px',
    threshold: !isLargeDesktop ? (!isMobileSize ? 0.5 : 0.22) : 0.4,
    // delay: 250,
  });
  const router = useRouter();
  const handleDetailPageRoute = (vendorCode) => {
    if (vendorCode) {
      router.push(`/vendor-details?vendor-code=${vendorCode}`);
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (inView) {
      setOpenTab(index);
    }
  }, [inView]);
  return (
    <div key={letter} id={letter} ref={ref} className="py-8 md:py-10">
      <h2 className="vendor-list-section-heading">{letter}</h2>
      <div className="vendor-list-companies">
        {/* {inView?setOpenTab(index):null} */}
       {items.map((item, index) => (
          <div className="cursor-pointer" key={index} onClick={() => handleDetailPageRoute(item.value.vendorCode)}>
            <p className="vendor-list-name">{item.value.vendorName}</p>
            <div className="vendor-list-address">
            <p>{item?.value?.address?.streetName ? item?.value?.address?.streetName + ', ' : ''}</p>
              <p>{item?.value?.address?.city ? item?.value?.address?.city + ', ' : ''} {item?.value?.address?.state ? item?.value?.address?.state + ', ' : ''}</p>
             <span>{item?.value?.address?.postalCode ? item?.value?.address?.postalCode + ', ' : ''} <p>{item?.value?.address?.country ? item?.value?.address?.country : ''}</p></span>
           </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VendorListItems;
