import { useEffect, useState } from 'react';
import Link from 'next/link';
import { connectStateResults } from 'react-instantsearch-dom';

function RfqHits({
  searchState,
  searchResults,
  setSearchOutput,
  setSearchQuery,
  setRecentSearchIndex,
  hideSearchDropdown,
  setHideSearchDropdown,
  handleCasCallback,
}) {
  const [slicedHits, setSlicedHits] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [categoryMatches, setCategoryMatches] = useState([
    {
      name: '',
      slug: '',
    },
  ]);

  const getCategories = (searchResults, searchState) => {
    const allCategories = searchResults?.hits[0]?.productProjections?.categories[0]?.obj.ancestors;
    const categoryNames = [];

    if (Object.keys(searchState).length > 0 && allCategories) {
      allCategories.forEach((element) => {
        categoryNames.push({
          name: element.obj.name['en-IN'].toLowerCase(),
          slug: element.obj.slug['en-IN'],
        });
      });
      categoryNames.splice(0, 1);
      setCategoryMatches(categoryNames);
    }
  };

  const getRecentSearches = () => {
    if (typeof window !== 'undefined') {
      const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
      setRecentSearches(recentSearches);
    }
  };

  useEffect(() => {
    getRecentSearches();
    getCategories(searchResults, searchState);
    if (searchResults) {
      setSlicedHits(searchResults?.hits.slice(0, 5));
    }
    setSearchOutput(searchResults);
    setSearchQuery(searchState.query);
  }, [searchResults, searchState, setRecentSearchIndex]);

  const handleRecentSearchClick = (query, productsName, productDetail) => {
    handleCasCallback(query, productsName, productDetail);
    setHideSearchDropdown(true);
  };

  const validQuery = searchState.query?.trim().length >= 3;
  return searchState.query && !hideSearchDropdown && validQuery ? (
    <div className="rfq-searchmodal-wrapper" style={{ left: '0px' }}>
      <div className="mobile-searchmodal-scroller">
        <div className="mobile-searchmodal-items-wrapper" style={{ width: 'unset' }}>
          {searchResults?.hits.length === 0 && (
            <div className="mobile-searchmodal-item-heading-category">No results found!</div>
          )}
          {searchResults?.hits.length > 0 &&
            slicedHits.map((hit, index) => (
              <>
                <div
                  key={index}
                  className="mobile-searchmodal-items"
                  onClick={() =>
                    handleRecentSearchClick(
                      hit.productProjections.masterVariant.attributes[0].value['en-IN'],
                      hit.productProjections.name['en-IN'],
                      hit.productProjections,
                    )
                  }
                >
                  <p className="mobile-searchmodal-item-heading">{hit.productProjections.name['en-IN']}</p>
                  <p className="mobile-searchmodal-casno">
                    CAS No:
                    {hit.productProjections.masterVariant.attributes[0].value['en-IN']}
                  </p>
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  ) : (
    <div className="mobile-searchmodal-wrapper-empty"></div>
  );
}
export default connectStateResults(RfqHits);
