import React from 'react';
import { Product } from '@Types/product/Product';
import Slider, { SliderProps } from 'components/commercetools-ui/slider';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mobile, tablet, desktop, largeDesktop, hugeDesktop } from 'helpers/utils/screensizes';
import APITile from './api-tile';

export interface Props {
  recentlyViewedProducts?: Product[];
  popularProducts?: any;
  mostPopularTile?: boolean;
}

export default function APISlider({popularProducts}: Props) {
  const [isMobileSize] = useMediaQuery(tablet);
  const [isTabletSize] = useMediaQuery(desktop);
  const [isDesktopSize] = useMediaQuery(largeDesktop);
  const [isLargeDesktopSize] = useMediaQuery(hugeDesktop);

  const sliderFixedMood: SliderProps = {
    slidesPerView: isMobileSize ? (isTabletSize ? (isDesktopSize ? (isLargeDesktopSize ? 5 : 4) : 4) : 2) : 2,
    arrows: isTabletSize ? true : false,

    dots: true,
  };
  const sliderConfiguration: SliderProps = sliderFixedMood;
  
  return (
    <div className=" mt-8">
      <div className=" overflow-x-auto ">
          <Slider {...sliderConfiguration}>
              {popularProducts &&
                Array.isArray(popularProducts) &&
                popularProducts
                  ?.slice(0, 15)
                  ?.map((product, index: number) => (
                    <APITile
                      key={index}
                      image={product?.masterData?.current?.masterVariant.images[0]?.url}
                      title={product?.masterData?.current?.name['en-IN']}
                      subtitle={product?.masterData?.current?.masterVariant.attributes[0].value['en-IN']}
                      grade={product?.masterData?.current?.masterVariant.attributes[1].value}
                      price={product?.masterData?.current?.masterVariant?.prices[0]?.value}
                      _url={`/${product?.masterData?.current?.masterVariant.sku}/p/${product?.masterData?.current?.slug['en-IN']}`}
                      product={product}
                    />
                  
                  ))}
              </Slider>
           </div>
    </div>
  );
}
