import Link from 'next/link';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import Typography from 'components/commercetools-ui/typography';
import { SLIDER_PRODUCT_CLICKED } from 'helpers/constants/events';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
export interface TileProps {
  image?: string;
  title?: string;
  subtitle?: string;
  grade?: string;
  price?: any;
  _url?: string;
  product?: Product;
}

const APITile: React.FC<TileProps> = ({ image, title, subtitle, grade, price, _url, product }) => {
  const handleProductTileAnalytics = (product: Product) => {
    gtag('event', SLIDER_PRODUCT_CLICKED, product);
  };
  const { account } = useAccount();
  const router = useRouter();
  const prepareTitle = (title: string): string => {
    if (title.split(' ').length === 2 && title.length <= 26) {
      return title;
    } else if (title.length >= 42) {
      return `${title.slice(0, 35)}...`;
    }
    return title;
  };

  const mTitle = prepareTitle(title);
  const requestForQuote = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      router.push('quotes/request-for-quote');
    }
  };
  return (
    <div className="api-tile">
      {image && (
        <div className="api-tile-img-container">
          <img src={image} className="api-tile-img" alt={title} />
        </div>
      )}
      <div className="api-tile-divider"></div>
      <div className="api-tile-container">
        <div className="cas-no">
          <Typography>{subtitle}</Typography>
        </div>
        <Link href={`${_url}`}>
          <h2 className="api-tile-title cursor-pointer">
            {mTitle.endsWith('...') ? (
              <span className="api-title tooltip tooltip-bottom" data-tip={title} style={{ textAlign: 'start' }}>
                {mTitle}
              </span>
            ) : (
              <Typography>{mTitle}</Typography>
            )}
          </h2>
        </Link>

        <p className="api-tile-grade">Grade: {grade}</p>
        {price?.centAmount != 0 ? (
          <p className="api-tile-price">{CurrencyHelpers.formatForCurrency(price)}</p>
        ) : (
          <button className="first_review ask-price-btn flex" onClick={requestForQuote}>
            Ask for price
          </button>
        )}
        <div className="api-tile-weight">
          <select defaultValue="grm">
            <option defaultValue="">100 gm</option>
            <option defaultValue="">200 gm</option>
            <option defaultValue="">300 gm</option>
          </select>
          <Link href={`${_url}`}>
            <button className="api-know-more-btn" onClick={() => handleProductTileAnalytics(product)}>
              Know More
              <img src="https://drl-allverz-qa.s3.ap-south-1.amazonaws.com/ui-icons/test-next-arrow-new.svg" alt="" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default APITile;
