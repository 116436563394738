import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Order } from '@Types/cart/Order';
import Price from 'components/commercetools-ui/price';
import { useFormat } from 'helpers/hooks/useFormat';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { useCart } from 'frontastic/provider';
import Item from '../cart/item';
import VendorwiseTotal from '../cart/vendorwiseTotal';
import Loader from '../loader';
import CancelOrder from './cancel-order';
import CancelSuccess from './cancel-success';

const ProductListInOrderDetails = ({ id, getOrderDetails }) => {
  const [apiResponse, setApiResponse] = useState<any>({});
  const [vendorWiseItems, setVendorWiseItems] = useState([]);
  const [orderNumber, setOrderNumber] = useState('');
  const [stateResponse, setStateResponse] = useState('');

  const [orderStatus, setOrderStatus] = useState('');
  const { data: cart, removeItem, updateItem, getOrderDetails: orderDetailsTax } = useCart();
  const [orderData, setOrderData] = useState<Order>();
  let totalTaxes = orderData?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0);
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [showImportProjectModal, setShowImportProjectModal] = useState(false);
  const [orderState, setOrderState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showCancelSuccess, setShowCancelSuccess] = useState(false);
  const shippingCharges = 2000;

  const router = useRouter();

  const fetchDetails = useCallback(async () => {
    try {
      const res = await orderDetailsTax(id.toString());
      setOrderData(res);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    getApi();
    getState();
    fetchDetails();
    if (apiResponse?.orderState == 'Cancelled') {
      setOrderState(false);
    } else {
      setOrderState(true);
    }

    totalTaxes = cart?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0);
  }, [apiResponse?.orderState]);

  useEffect(() => {
    if (apiResponse) {
      const vendorData: any = apiResponse?.lineItems?.reduce((group, item) => {
        const vendorName = item.variant?.attributes.find((element) => element.name === 'vendor-name').value['en-IN'];
        group[vendorName] = group[vendorName] || [];
        group[vendorName].push(item);
        setLoading(false);
        return group;
      }, {});

      getOrderDetails(apiResponse);
      setVendorWiseItems(vendorData);
      setOrderNumber(apiResponse?.orderNumber);
      setOrderStatus(apiResponse?.orderState);
    }
  }, [apiResponse]);

  const reqHeaders = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  };

  const getState = async () => {
    if (stateID) {
      const orderStateResponse = await commercetoolsAPIClient.get(`/states/${stateID}`, reqHeaders);
      console.log('orderDetailsResponse', orderStateResponse?.data?.name?.en);
      setStateResponse(orderStateResponse?.data?.name?.en);
    }
  };

  console.log('stateResponsestateResponse', stateResponse);

  const getApi = async () => {
    const orderDetailsResponse = await commercetoolsAPIClient.get(`/orders/${id}`, reqHeaders);

    setApiResponse(orderDetailsResponse?.data);
  };

  const stateID = apiResponse?.state?.id;

  console.log('apirespd.ose - ', apiResponse?.state?.id);

  const goToProductPage = (_url: string) => router.push(_url);

  const vendorTotal = (vendorName: string) => {
    const items = vendorWiseItems[vendorName];
    return (
      <div>
        <>
          <VendorwiseTotal items={items} />{' '}
        </>
      </div>
    );
  };
  const handleStatusChange = (status) => {
    setOrderStatus(status);
  };

  const handleStatus = (state) => {
    setOrderState(state);
    setShowCancelSuccess(true);
  };

  return (
    <>
      {loading && <Loader />}
      <div style={{ width: '100%' }}>
        <div className="flex items-center justify-between gap-4">
          <div className="my-cart-text flex text-2xl font-bold">
            Order Num {orderNumber}
            <div className="ml-2 rounded-md bg-blue-300 p-1 text-sm font-bold text-blue-600">{stateResponse}</div>
          </div>
          {orderState && (
            <button
              className="btn-sm-mobile btn btn-sm"
              onClick={() => {
                setShowImportProjectModal(true);
              }}
            >
              Cancel Order
            </button>
          )}
          {showImportProjectModal && (
            <CancelOrder
              setOpenModal={setShowImportProjectModal}
              apiResponse={apiResponse}
              onStatusChange={handleStatusChange}
              onStatus={handleStatus}
            />
          )}
        </div>

        <h1 style={{ marginTop: '5px' }}>Reference number NA</h1>
        <div className="cart-items-section">
          {console.log('apirespose - ', apiResponse)}
          <h1 className="my-cart-text text-xl font-bold">Order Summary ({apiResponse?.lineItems?.length}) </h1>
          {vendorWiseItems &&
            Object.keys(vendorWiseItems).map((vendor, index) => (
              <>
                <div key={vendor} className="cart-vendor-header-main">
                  <div className="cart-vendor-header">
                    <span className="cart-vendor-sold">Sold and Fulfilled by : </span>
                    <span style={{ fontSize: '16px', fontWeight: '700', borderBottom: '1px solid' }}>{vendor}</span>
                  </div>
                  <ul className="" role="list">
                    {vendorWiseItems[vendor].map((lineItem, i) => {
                      return (
                        <li key={i} className="cart-vendor-li">
                          {i < cart?.lineItems?.length && <hr className="md:hidden" />}
                          <Item
                            lineItem={lineItem}
                            editItemQuantity={updateItem}
                            goToProductPage={goToProductPage}
                            removeItem={() => {}}
                            isFromOrderDetails={true}
                          />
                        </li>
                      );
                    })}
                  </ul>
                  <div className="cart-list-bottom" style={{ marginLeft: '25px' }}>
                    <div className="cart-list-bottom-terms">
                      {/* <TermsConditions key={vendor} vendor={vendor} /> */}
                    </div>
                    <div className="shippingBlock" style={{ flex: '3' }}>
                      {vendorTotal(vendor)}
                    </div>
                  </div>
                </div>
                {vendorWiseItems && index !== Object.keys(vendorWiseItems).length - 1 && (
                  <div className="iem-separator"></div>
                )}
              </>
            ))}

          <div className="order-total-amount-section">
            <div className="order-total-terms">
              <div className="terms-sidebar px-5 tracking-[1px]" style={{ fontSize: '14px' }}>
                <p className="font-bold text-[#000000]" style={{ marginBottom: '10px', marginLeft: '-15px' }}>
                  Allverz Terms and Conditions
                </p>
                <ul className="terms-list font-medium">
                  <li>All prices shown exclude GST</li>
                  <li>All information is subject to change without information</li>
                  <li>All prices shown exclude GST</li>
                  <li>All information is subject to change without information</li>
                </ul>
              </div>
            </div>
            <div className="order-total-amount">
              <dl className="mt-9">
                <div className="sum-row flex items-center justify-between">
                  <dt className=" price_checkout_heading text-[14px] lg:text-[17px] 2xl:text-[20px]">
                    {formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}
                  </dt>
                  {console.log('cart - ', cart)}
                  <dd>
                    <Price
                      price={{
                        centAmount: apiResponse?.totalPrice?.centAmount,
                        currencyCode: cart?.sum?.currencyCode,
                      }}
                      className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
                    />
                  </dd>
                </div>

                <div className="flex items-center justify-between pt-4">
                  <dt className=" price_checkout_heading text-[14px] lg:text-[17px] 2xl:text-[20px]">
                    <span>{formatCartMessage({ id: 'shipping.estimate', defaultMessage: 'Shipping estimate' })}</span>
                  </dt>
                  <dd>
                    <Price
                      price={{
                        centAmount: 2000,
                        currencyCode: cart?.sum?.currencyCode,
                      }}
                      className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
                    />
                  </dd>
                </div>

                <div className="flex items-center justify-between pt-4">
                  <dt className=" price_checkout_heading text-[14px] lg:text-[17px] 2xl:text-[20px]">
                    <span>{formatCartMessage({ id: 'discounts', defaultMessage: 'Discount' })}</span>
                  </dt>
                  <dd>
                    <Price
                      price={
                        {
                          fractionDigits: 0,
                          centAmount: 0,
                          currencyCode: 'INR',
                        } || {}
                      }
                      className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
                    />
                  </dd>
                </div>

                {orderData?.taxed && (
                  <div className="flex items-center justify-between pt-4">
                    <dt className="price_checkout_heading text-[14px] lg:text-[17px] 2xl:text-[20px]">
                      <span>{orderData?.taxed.taxPortions[0].name}: </span>
                    </dt>
                    <dd>
                      <Price
                        price={
                          {
                            fractionDigits: 2,
                            centAmount: orderData?.taxed?.taxPortions[0]?.amount.centAmount,
                            currencyCode: orderData?.sum?.currencyCode,
                          } || {}
                        }
                        className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
                      />
                    </dd>
                  </div>
                )}

                {orderData?.taxed && (
                  <div className="flex items-center justify-between pt-4">
                    <dt className="price_checkout_heading text-[14px] lg:text-[17px] 2xl:text-[20px]">
                      <span>{orderData?.taxed.taxPortions[1]?.name}: </span>
                    </dt>
                    <dd>
                      <Price
                        price={
                          {
                            fractionDigits: 2,
                            centAmount: orderData?.taxed?.taxPortions[1]?.amount.centAmount,
                            currencyCode: orderData?.sum?.currencyCode,
                          } || {}
                        }
                        className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
                      />
                    </dd>
                  </div>
                )}

                <div className="cart-grand-total flex items-center justify-between pt-4">
                  <dt className="price_checkout_heading text-[16px] lg:text-[23px]  2xl:text-[30px]">
                    {formatCartMessage({ id: 'orderTotal', defaultMessage: 'Order total' })}
                  </dt>
                  <dd>
                    <Price
                      price={{
                        centAmount: orderData?.sum?.centAmount + totalTaxes + 2000,
                        currencyCode: orderData?.sum?.currencyCode,
                      }}
                      className="price_checkout text-[18px] lg:text-[25px] 2xl:text-[30px]"
                    />
                  </dd>
                  {showCancelSuccess && <CancelSuccess setOpenModal={setShowCancelSuccess} />}
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductListInOrderDetails;
