import React, { useState } from 'react';

const Stepper = ({
  stepperData,
  isHorizontal = true,
  showCurrentStepOnly = true,
  activeStep = 0,
  hideSteps = [],
  isClickable = true,
}) => {
  const [currentStep, setCurrentStep] = useState(activeStep);

  const canShowStep = (stepIndex): boolean => {
    if (hideSteps.length === 0) return true;
    return hideSteps.indexOf(stepIndex) === -1;
  };

  return (
    <div className="space-y-4" style={{ width: '100%', display: 'flex' }}>
      <ul className={isHorizontal ? 'step-wrapper-horizontal' : 'step-wrapper'}>
        {stepperData.map(
          (step, index) =>
            canShowStep(index) && (
              <li
                key={index}
                className={isHorizontal ? 'step-item-horizontal' : 'step-item'}
                onClick={() => {
                  isClickable && setCurrentStep(index);
                }}
              >
                <span className={`stepper-span ${index <= currentStep ? 'active-step' : ''}`}></span>
                <div style={{ display: 'flex', flex: '12', flexDirection: 'column' }}>
                  <p className="step-heading">{step.label}</p>
                  <div style={{ marginTop: '10px' }}>
                    {showCurrentStepOnly ? currentStep === index && step.component : step.component}
                  </div>
                </div>
              </li>
            ),
        )}
      </ul>
    </div>
  );
};

export default Stepper;
