import React, { useState, useEffect, useMemo } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic/provider';
import { LineItem } from '@Types/cart/LineItem';
import Price from 'components/commercetools-ui/price';
import Link from 'next/link';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import NextLink from 'next/link';
import { STATIC_ORDERS_URL } from 'helpers/constants/statisURLs';
import Loader from 'components/commercetools-ui/loader';
const CartSum = ({ cartData }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { getShippingMethods } = useCart();
  const cart = useCart();
  const [shippingAmount, setShppingAmount] = useState<number>(0);
  const [currencyCode, setCurrencyCode] = useState<string>('INR');
  const [loading, setLoading] = useState(true);
  const totalTaxes = cartData?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0);
  const vendorWiseItems = cartData?.lineItems?.reduce((group, item) => {
    const vendorName = item.variant?.attributes['vendor-name'];
    group[vendorName] = group[vendorName] || [];
    group[vendorName].push(item);
    return group;
  }, {});

  useMemo(() => {
    if (vendorWiseItems) {
      getShippingMethods().then((data: any) => {
        setShppingAmount(data[0]?.rates[0]?.price?.centAmount * Object.keys(vendorWiseItems).length);
        setCurrencyCode(data[0]?.rates[0]?.price?.currencyCode);
        setLoading(false);
      });
    }
  }, []);
  const productPrice = cartData?.lineItems?.reduce((a, b: LineItem) => {
    if (b.discountedPrice) {
      return a + b.discountedPrice.centAmount * b.count;
    } else {
      return a + b.prices?.centAmount * b.count;
    }
  }, 0);

  const discountPrice = cartData?.lineItems?.reduce((a, b) => {
    return (
      a +
      b.count *
        b.discounts.reduce((x, y) => {
          return x + y.discountedAmount.centAmount;
        }, 0)
    );
  }, 0);
  //   const reqBody = {
  //     id: cartData?.cartId,
  //     version: cartData?.cartVersion && JSON.parse(cartData?.cartVersion),
  //   };
  //   const handlePlaceOrder = async () => {
  //     try {
  //       const response = await commercetoolsAPIClient.post(STATIC_ORDERS_URL, reqBody, {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${window.localStorage.getItem('token')}`,
  //         },
  //       });
  //       console.log('placeORderResponse', response.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  return (
    <>
      {loading && <Loader />}
      <div>
        <section aria-labelledby="summary-heading" className="summary-heading">
          <p className="order-summary-heading ml-[0px] pt-[10px] text-left">
            Shopping Cart Total <span>({cartData?.lineItems?.length})</span>
          </p>
          <dl className="mt-9">
            <div className="sum-row flex items-center justify-between">
              <dt className=" price_checkout_heading text-[14px] lg:text-[17px] 2xl:text-[20px]">
                {formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}
              </dt>
              <dd>
                <Price
                  price={{
                    centAmount: productPrice,
                    currencyCode: cartData?.sum?.currencyCode,
                  }}
                  className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
                />
              </dd>
            </div>

            <div className="flex items-center justify-between pt-4">
              <dt className=" price_checkout_heading text-[14px] lg:text-[17px] 2xl:text-[20px]">
                <span>{formatCartMessage({ id: 'shipping.estimate', defaultMessage: 'Shipping estimate' })}</span>
              </dt>
              <dd>
                <Price
                  price={{
                    centAmount: shippingAmount,
                    currencyCode: currencyCode,
                  }}
                  className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
                />
              </dd>
            </div>

            <div className="flex items-center justify-between pt-4">
              <dt className=" price_checkout_heading text-[14px] lg:text-[17px] 2xl:text-[20px]">
                <span>{formatCartMessage({ id: 'discounts', defaultMessage: 'Discount' })}</span>
              </dt>
              <dd>
                <Price
                  price={
                    {
                      fractionDigits: 0,
                      centAmount: discountPrice == 0 ? 0 : -discountPrice,
                      currencyCode: cartData?.sum?.currencyCode,
                    } || {}
                  }
                  className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
                />
              </dd>
            </div>

            {cartData?.taxed && (
              <div className="flex items-center justify-between pt-4">
                <dt className="price_checkout_heading text-[14px] lg:text-[17px] 2xl:text-[20px]">
                  <span>{cartData?.taxed?.taxPortions[0]?.name}: </span>
                </dt>
                <dd>
                  <Price
                    price={
                      {
                        fractionDigits: 2,
                        centAmount: cartData?.taxed?.taxPortions[0]?.amount.centAmount,
                        currencyCode: cartData?.sum?.currencyCode,
                      } || {}
                    }
                    className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
                  />
                </dd>
              </div>
            )}

            {cartData?.taxed && (
              <div className="flex items-center justify-between pt-4">
                <dt className="price_checkout_heading text-[14px] lg:text-[17px] 2xl:text-[20px]">
                  <span>{cartData?.taxed?.taxPortions[1]?.name}: </span>
                </dt>
                <dd>
                  <Price
                    price={
                      {
                        fractionDigits: 2,
                        centAmount: cartData?.taxed?.taxPortions[1]?.amount.centAmount,
                        currencyCode: cartData?.sum?.currencyCode,
                      } || {}
                    }
                    className="price_checkout text-[14px] lg:text-[18px] 2xl:text-[20px]"
                  />
                </dd>
              </div>
            )}

            <div className="cart-grand-total flex items-center justify-between pt-4">
              <dt className="price_checkout_heading text-[16px] lg:text-[23px]  2xl:text-[30px]">
                {formatCartMessage({ id: 'orderTotal', defaultMessage: 'Order total' })}
              </dt>
              <dd>
                <Price
                  price={{
                    centAmount: cartData?.sum?.centAmount + shippingAmount + totalTaxes,
                    // centAmount: shippingAmount,
                    currencyCode: cartData?.sum?.currencyCode,
                  }}
                  className="price_checkout text-[18px] lg:text-[25px] 2xl:text-[30px]"
                />
              </dd>
            </div>
          </dl>
          <div className="flex flex-col-reverse lg:flex-col">
            <div className="flex flex-col rounded-md pt-4 shadow-sm" role="group">
              {/* <NextLink href="/payment"> */}
              <NextLink href="/payment">
                <button className="view_quotes-button mt-[15px] h-[37px] bg-[#0280C7] text-center text-[14px] font-medium tracking-[1px] text-[#FFFFFF] lg:mt-[25px]">
                  Payment
                </button>
              </NextLink>
              {/* </NextLink> */}
              <NextLink href="/checkout">
                <button className="continue-browsing mt-[15px] h-[37px] text-center text-[14px] font-medium tracking-[1px] text-[#0280c7] lg:mt-[15px] lg:px-[24px]">
                  Back
                </button>
              </NextLink>
            </div>
            <div className="terms-sidebar px-5 tracking-[1px]" style={{ fontSize: '14px' }}>
              <p className="font-bold text-[#000000]" style={{ marginBottom: '10px', marginLeft: '-15px' }}>
                Allverz Terms and Conditions
              </p>
              <ul className="terms-list font-medium">
                <li>All prices shown exclude GST</li>
                <li>All information is subject to change without information</li>
                <li>All prices shown exclude GST</li>
                <li>All information is subject to change without information</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CartSum;
