import React, { useState } from 'react';
import toast from 'react-hot-toast';
import AppModal from '../../modal/app-modal';
import { useFormat } from 'helpers/hooks/useFormat';
import { FileUpload, FormSearchInput, FormCheckbox } from '../../form';
import { useAccount } from 'frontastic';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_IMPORT_PROJECT } from 'helpers/constants/statisURLs';
import { mutate } from 'swr';

const templateBaseUrl = process.env['ALLVERZ_BACKEND_BASE_URL'] || 'https://drl-allverz-qa.s3.ap-south-1.amazonaws.com';

export default function ImportProject({ setOpenModal }) {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const { account } = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [projects, setProjects] = useState([
    { id: 1, project: 'Placeholder text for contents1', isChecked: false },
    { id: 2, project: 'Placeholder text for contents2', isChecked: false },
    { id: 3, project: 'Placeholder text for contents3', isChecked: false },
    { id: 4, project: 'Placeholder text for contents4', isChecked: false },
    { id: 5, project: 'Placeholder text for contents5', isChecked: false },
    { id: 6, project: 'Placeholder text for contents6', isChecked: false },
    { id: 7, project: 'Placeholder text for contents7', isChecked: false },
    { id: 8, project: 'Placeholder text for contents8', isChecked: false },
    { id: 9, project: 'Placeholder text for contents9', isChecked: false },
  ]);

  const onFileChange = (file: File) => {
    setIsFileUploaded(false);
    setFile(file);
  };

  const onSelectProject = (checked: boolean, id: string) => {
    const projectsCopy = [...projects];
    projectsCopy.find((elem) => elem?.id?.toString() === id).isChecked = checked;
    setProjects(projectsCopy);
  };

  const showAlert = (type: string, id: string, defaultMessage: string) => {
    if (type === 'success')
      toast.success(
        formatCartMessage({ id: 'importProj.fileupload.done', defaultMessage: 'File Uploaded successfully.' }),
      );
    else
      toast.error(
        formatCartMessage({
          id: 'importProj.fileupload.error',
          defaultMessage: 'Something went wrong. Please try again.',
        }),
      );
  };

  const onUploadFile = async (file: File) => {
    showAlert('success', 'importProj.fileupload.done', 'File Uploaded successfully.');
    setIsFileUploaded(true);
  };

  const clearUpload = () => {
    setFile(null);
    setIsFileUploaded(false);
  };

  const onSearch = (searchValue: string) => {};

  const onImport = async () => {
    if (isLoading) return;
    if (!account?.accountId) return toast.error('Please login.');
    if (!file) return toast.error('Please attach the file');
    if (!isFileUploaded) return toast.error('Please upload the file');
    setIsLoading(true);
    const toastUploadFile = toast.loading('Imporing project...');
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('cust', account.accountId);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      await backendAPIClient
        .post(STATIC_IMPORT_PROJECT, formData, { headers })
        .then(async (res) => {
          if (res && (res.status === 201 || res.status === 200)) {
            toast.success('Project imported successfully!');
            setIsFileUploaded(true);
            await mutate('/action/cart/getCart'); //relaod cart items.
          } else {
            showAlert('failure', '', 'Something went wrong. Please try again.');
          }
        })
        .catch((err) => {
          showAlert('failure', '', 'Something went wrong. Please try again.');
        })
        .finally(() => {
          setIsLoading(false);
          toast.dismiss(toastUploadFile);
          setOpenModal(false);
        });
    } catch (err) {
      showAlert('failure', '', 'Something went wrong. Please try again.');
    }
  };

  return (
    <AppModal setOpenModal={() => setOpenModal(false)}>
      <div className="mb-[30px] ml-[10px] mr-[10px] md:mb-[62px] md:ml-[30px] md:mr-[30px] lg:mt-[39px] lg:ml-[63px] lg:mr-[62px]">
        <p className="text-[32px] font-bold text-[#1E1E1E] lg:text-[32px]">
          {formatCartMessage({ id: 'importProj.heading', defaultMessage: 'Import Project' })}
        </p>
        <p className="hidden text-[14px] font-normal leading-5 md:block">
          {formatCartMessage({
            id: 'importProj.headNote',
            defaultMessage: 'Add your product list to the cart, you can use the existing list or create new.',
          })}
        </p>
        <p className="mt-[27px] text-[12px] font-normal lg:mt-[27px] lg:text-[14px]">
          {formatCartMessage({
            id: 'importProj.template.download',
            defaultMessage: 'For Bulk Order please use this template',
          })}
          <a
            className="link link-primary ml-2 font-bold"
            download
            href={templateBaseUrl + '/excelfile/Bulk_Add_to_cart.xlsx'}
          >
            {formatCartMessage({ id: 'importProj.link.download', defaultMessage: 'Download Project Template' })}
          </a>
        </p>

        <h1 className="mt-[20px] text-[14px] font-bold">
          {formatCartMessage({ id: 'importProj.doc.upload', defaultMessage: 'Upload above document' })}
        </h1>
        <FileUpload
          file={file}
          name="file_upload"
          label={formatCartMessage({ id: 'importProj.addDocument', defaultMessage: 'Add document' })}
          accept=".xlsx"
          isFileUploaded={isFileUploaded}
          onFileChange={onFileChange}
          onUpload={onUploadFile}
          clearUpload={clearUpload}
        />

        <h1 className="mt-[20px] text-[14px] font-bold">
          {formatCartMessage({ id: 'importProj.existingProject', defaultMessage: 'Existing Project' })}
        </h1>
        <FormSearchInput
          onSearch={onSearch}
          value=""
          name="txt_serach"
          inputType="text"
          buttonText={formatCartMessage({ id: 'importProj.search.button', defaultMessage: 'Search' })}
        />

        <div className="mt-4">
          {projects.map((item, i) => (
            <FormCheckbox
              key={item.id}
              id={item.id.toString()}
              name="select_projects"
              containerClassNames="mt-2 flex space-x-2"
              label={item.project}
              checked={item.isChecked}
              onChange={onSelectProject}
            />
          ))}
        </div>

        <div className="!mt-[10px]">
          <a className="link link-primary text-[14px] font-bold" href="#">
            {formatCartMessage({ id: 'importProj.seeMoreProject', defaultMessage: 'See More Project' })}
          </a>
        </div>
        <div className="flex">
          <button className="btn btn-outline mt-[25px] flex-1" onClick={() => setOpenModal(false)}>
            {formatCartMessage({ id: 'importProj.button.cancel', defaultMessage: 'Cancel' })}
          </button>
          <button className="btn-stretch btn-blue btn ml-[10px] mt-[25px] flex-1" onClick={onImport}>
            {formatCartMessage({ id: 'importProj.button.import', defaultMessage: 'Import' })}
          </button>
        </div>
      </div>
    </AppModal>
  );
}
