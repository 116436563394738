/* eslint-disable tailwindcss/no-arbitrary-value */
import { useRef, useState } from 'react';
import { FileUpload } from '../form';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_QUOTE_DOCUMENTS_UPLOAD } from 'helpers/constants/statisURLs';

const RequestQuoteProductDetails = ({
  selectedProduct,
  quantityCallBack,
  packageSizeCallback,
  descriptionCallBack,
  fileUploadCallback,
  isMobile = false,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [purity, setPurity] = useState('');
  const [country, setCountry] = useState('');
  const [packSize, setPackSize] = useState('');
  const [file, setFile] = useState<File>();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const purityArray = [];
  const countryArray = [];
  const packSizeArray = [];
  const standardsArray = [];
  const dosageArray = [];

  console.log('selectedProduct - ', selectedProduct);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const accept = '.doc, .docx, .xls, .xlsx, .pdf';
  const updateQuantity = (qty) => {
    setQuantity(qty);
    quantityCallBack(qty);
  };

  const handleDescription = (value) => {
    descriptionCallBack(value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return e.preventDefault();
  };

  const onFileChange = (file: File) => {
    setIsFileUploaded(false);
    setFile(file);
  };

  const onUploadFile = async (file: any) => {
    try {
      const formData = new FormData();
      for (const item of file) {
        formData.append('file', item);
      }
      formData.append('cartId', 'ec56f1ae-182c-4ddf-8083-bcca5d704f7b');

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      await backendAPIClient.post(STATIC_QUOTE_DOCUMENTS_UPLOAD, formData, { headers }).then((res) => {
        fileUploadCallback(res);
        setIsFileUploaded(true);
      });
    } catch (err) {}
  };

  const clearUpload = () => {
    setFile(null);
    setIsFileUploaded(false);
  };

  const inputElement = (
    <input
      className="file_upload_button  h-[44px]"
      id="fileUpload"
      type="file"
      ref={inputRef}
      onChange={() => {}}
      accept={accept}
      style={{ display: 'none' }}
    />
  );

  const buttonElement = (
    <button className="btn ml-2 !w-[30%]" onClick={() => {}} disabled={false}>
      Upload File
    </button>
  );

  function formatMessage(arg0: { id: string; defaultMessage: string }): import('react').ReactNode {
    throw new Error('Function not implemented.');
  }

  return (
    <div className="quote-product-details-container">
      {!isMobile && <div className="quote-product-details-title">Product Details</div>}
      <div className="quote-flex-container">
        <div className="quote-form-element">
          <label className="quote-form-element-label">
            Purity<span className="ml-[3px] text-[#EB4747]">*</span>
          </label>
          <select
            id="selected-tab"
            name="selected-tab"
            className="mt-1 block w-full rounded-sm border-gray-300 stroke-accent-400 py-2 pr-10 pl-3 text-base text-accent-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
          >
            {selectedProduct?.variants
              .filter((_, index) => index !== 0)
              .map((item, index) =>
                item.attributes
                  .filter((data) => data.name === 'purity')
                  .map((element) => {
                    if (!purityArray.includes(element.value)) {
                      purityArray.push(element?.value);
                      return (
                        <option
                          className="values"
                          key={index}
                          defaultValue={element?.value}
                          onClick={() => {
                            setPurity(element?.value);
                          }}
                        >
                          {element?.value}
                        </option>
                      );
                    }
                  }),
              )}
          </select>
        </div>
        <div className="quote-form-element">
          <label className="quote-form-element-label">
            Country of Origin<span className="ml-[3px] text-[#EB4747]">*</span>
          </label>
          <select id="selected-tab" name="selected-tab" className="pdp_search_filter w-[50%] 2xl:w-auto">
            {selectedProduct?.variants
              .filter((_, index) => index !== 0)
              .map((item, index) =>
                item.attributes
                  .filter((data) => data.name === 'country-of-origin')
                  .map((element) => {
                    if (!countryArray.includes(element.value.label)) {
                      countryArray.push(element?.value?.label);
                      return (
                        <option
                          className="values"
                          key={index}
                          defaultValue={element?.value?.label}
                          onClick={() => {
                            setCountry(element?.value?.label);
                          }}
                        >
                          {element?.value?.label}
                        </option>
                      );
                    }
                  }),
              )}
          </select>
        </div>
        <div className="quote-form-element">
          <label className="quote-form-element-label">
            Pack Size<span className="ml-[3px] text-[#EB4747]">*</span>
          </label>
          <select
            id="selected-tab"
            name="selected-tab"
            className="pdp_search_filter w-[50%] 2xl:w-auto"
            onClick={(element: any) => {
              setPackSize(element.target.value);
              packageSizeCallback(element.target.value);
            }}
          >
            {selectedProduct?.variants
              .filter((_, index) => index !== 0)
              .map((item, index) =>
                item.attributes
                  .filter((data) => data.name === 'packaging-size')
                  .map((element) => {
                    if (!packSizeArray.includes(element?.value)) {
                      packSizeArray.push(element?.value);
                      return (
                        <option
                          className="values"
                          key={index}
                          defaultValue={element?.value}
                          onClick={() => {
                            setPackSize(element?.value);
                          }}
                        >
                          {element?.value}
                        </option>
                      );
                    }
                  }),
              )}
          </select>
        </div>
        <div className="quote-form-element">
          <label className="quote-form-element-label mb-1">Quantity</label>
          <div className="gms flex gap-[10px]">
            <div className="qty plpQTY" style={{ width: '100%' }}>
              <div
                className="grms-qty bg-[white]"
                style={{ width: '100%', justifyContent: 'space-between', borderColor: '#ABABAB' }}
              >
                <button
                  className="qtyminus bg-[white]"
                  onClick={() => {
                    quantity > 1 && updateQuantity(quantity - 1);
                  }}
                >
                  <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2079 2H0.992188V0H13.2079V2Z" fill="#0068A3" />
                  </svg>
                </button>
                <input className="plp-weight" type="text" value={quantity} />
                <button
                  className="qtyplus bg-[white]"
                  onClick={() => {
                    updateQuantity(quantity + 1);
                  }}
                >
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.1649 8H7.92961V14H6.18451V8H0.949219V6H6.18451V0H7.92961V6H13.1649V8Z"
                      fill="#0068A3"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="quote-flex-container">
        <div className="quote-flex-container-one">
          <div className="quote-flex-container-one-one">
            <div className="quote-form-element-50">
              <label className="quote-form-element-label">Standards</label>
              <select id="selected-tab" name="selected-tab" className="pdp_search_filter w-[50%] 2xl:w-auto">
                {selectedProduct?.variants
                  .filter((_, index) => index !== 0)
                  .map((item, index) =>
                    item.attributes
                      .filter((data) => data.name === 'pharmacopoea-standard')
                      .map((element) => {
                        if (!standardsArray.includes(element?.value)) {
                          standardsArray.push(element?.value);
                          return (
                            <option
                              className="values"
                              key={index}
                              defaultValue={element?.value}
                              onClick={() => {
                                setPackSize(element?.value);
                              }}
                            >
                              {element?.value}
                            </option>
                          );
                        }
                      }),
                  )}
              </select>
            </div>
            {
              <div className="quote-form-element-50">
                {selectedProduct?.variants[0].attributes.filter((data) => data.name === 'dosage').length !== 0 && (
                  <>
                    <label className="quote-form-element-label">
                      Dosage Forms <span className="ml-[3px] text-[#EB4747]">*</span>
                    </label>

                    <select id="selected-tab" name="selected-tab" className="pdp_search_filter w-[50%] 2xl:w-auto">
                      {selectedProduct?.variants
                        .filter((_, index) => index !== 0)
                        .map((item, index) =>
                          item.attributes
                            .filter((data) => data.name === 'dosage')
                            .map((element) => {
                              if (!dosageArray.includes(element?.value['en-IN'])) {
                                dosageArray.push(element?.value['en-IN']);
                                return (
                                  <option
                                    className="values"
                                    key={index}
                                    defaultValue={element?.value['en-IN']}
                                    onClick={() => {
                                      setPackSize(element?.value['en-IN']);
                                    }}
                                  >
                                    {element?.value['en-IN']}
                                  </option>
                                );
                              }
                            }),
                        )}
                    </select>
                  </>
                )}
              </div>
            }
          </div>
          <div className="quote-details-file-upload">
            <label className="quote-form-element-label">Product Specification Document</label>
            <div className="upload_file_pdp ml-[-5px]" style={{ width: '100%' }}>
              {file ? (
                <div className="mt-5 flex gap-2" style={{ alignItems: 'center' }}>
                  <div className="multi-file-wrapper">
                    {Object.values(file).map((element, index) => (
                      <div key={index}>
                        <div className="multi-file-wrapper">
                          <span
                            className="fileclearbutton text-[#0280c7]"
                            style={{ marginRight: '5px' }}
                            onClick={clearUpload}
                          >
                            X
                          </span>
                          {element.name}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    <button className="btn" disabled={isFileUploaded} onClick={() => onUploadFile(file)}>
                      {isFileUploaded ? 'Done' : 'Upload'}
                    </button>
                  </div>
                </div>
              ) : (
                <FileUpload
                  file={file}
                  name="file_upload"
                  label="Add Document"
                  accept={accept}
                  isFileUploaded={isFileUploaded}
                  onFileChange={onFileChange}
                  onUpload={onUploadFile}
                  clearUpload={clearUpload}
                  forQuote={true}
                  isMultiple={true}
                />
              )}
            </div>
          </div>
        </div>
        <div className="quote-details-description">
          <div style={{ width: '100%' }}>
            <label className="quote-form-element-label">Description</label>
            <textarea
              required
              name="Description"
              id="Description"
              maxLength={500}
              placeholder="Enter a Description"
              autoComplete="given-Description"
              className="review_description_box quote_description_box mt-[5px] text-[12px]"
              onChange={(e) => {
                handleDescription(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestQuoteProductDetails;
