
import React from 'react';
import { QuoteDetails } from './Quote-details-component/quote-details'

function QuotesDetail() {
  return (
    <div className="aboutUsTopContainer container mx-auto mt-[20px]  md:px-0 lg:px-0">
      <div className="flex">
        <div className="quote-details-component">
          <QuoteDetails selectedQuote={{}} onReject={() => {}} />
        </div>
      </div>
    </div>
  );
}

export default QuotesDetail