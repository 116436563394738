import { useFormat } from 'helpers/hooks/useFormat';
import React, { ChangeEvent, FC, MouseEvent, useRef, useState } from 'react';
import toast from 'react-hot-toast';

export interface Props {
  /*
   * File
   */
  readonly file?: File;
  /*
   * input name, must be unique on page
   */
  readonly name: string;
  /*
   * the label text displayed to the user
   */
  readonly label: string;
  /*
   * File extenstions Accepted. Ex: '.png, .jpg, .jpeg' | '.xls, .xlsx, .csv'
   */
  readonly accept?: string;
  /*
   * Maximum file size allowed.
   */
  readonly maxFileSizeInMB?: number;
  /*
   * Flag to identified the file upload status.
   */
  readonly isFileUploaded?: boolean;
  /*
   * replaces the label's className field if provided
   */
  readonly labelClassNames?: string;
  /*
   * function called when file is changed
   */
  onFileChange?: (file: any) => void;
  /*
   * function called when file is upload
   */
  onUpload: (file: File) => void;
  /*
   * function called when file is removed
   */
  clearUpload: () => void;
  forQuote?: boolean;
  isMultiple?: boolean;
}

const FileUpload: FC<Props> = ({
  file,
  name,
  label,
  isFileUploaded,
  accept = '.png, .jpg, .jpeg',
  maxFileSizeInMB = 5,
  labelClassNames,
  onFileChange = () => {},
  onUpload,
  clearUpload = () => {},
  forQuote = false,
  isMultiple = false,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

  // !acceptedFileExt.includes('.' + file?.name?.split('.').pop())

  const checkFileFormat = (acceptedFileExt, file) => {
    let isFormatWrong = false;
    for(const item of file) {
      if (!acceptedFileExt.includes('.' + item?.name?.split('.').pop())) {
        isFormatWrong = true;
      }
    }

    return isFormatWrong;
  };

  const checkFileSize = (file) => {
    let isFileSizeExceeingLimit = false;

    for(const element of file) { 
      if (element.size > maxFileSizeInMB * 1000000) {
        isFileSizeExceeingLimit = true;
      }
    }
    return isFileSizeExceeingLimit;
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file:any = e.target.files;
    
    if (!file) return e.preventDefault();
    const acceptedFileExt = accept.replaceAll(' ', '').split(',');
    if (checkFileFormat(acceptedFileExt, file)) {
      toast.error(
        formatErrorMessage({
          id: 'fileUpload.invalidFile',
          defaultMessage: 'Please upload the correct format of the file',
        }),
      );
      return e.preventDefault();
    } else if (checkFileSize(file)) {
      toast.error(
        formatErrorMessage({
          id: 'fileUpload.maxFileSize',
          defaultMessage: `The file size should not exceed ${maxFileSizeInMB}MB.`,
          values: { maxSize: '5' },
        }),
      );
      return e.preventDefault();
    } else {
      if (isMultiple) {
        onFileChange(file);
      } else {
        onFileChange(file[0]);
      }
    }
  };

  const handleUploadClick = () => {
    if (!file) return toast.error('Please attach the file');
    onUpload(file);
  };

  const onClearUpload = (e: MouseEvent) => {
    inputRef.current.value = '';
    e.preventDefault();
    clearUpload();
  };

  const labelElement = (
    <label
      htmlFor={name}
      className={labelClassNames ? labelClassNames : 'documentfont block text-gray-700 dark:text-light-100'}
    >
      {label}
    </label>
  );

  const inputElement = (
    <input
      className="file_upload_button  h-[44px]"
      id="fileUpload"
      type="file"
      ref={inputRef}
      onChange={handleFileChange}
      accept={accept}
      style={{ display: 'none' }}
      multiple={isMultiple}
    />
  );

  const mainClasses = forQuote ? 'upload_file_pdp !w-[50%]' : 'upload_file_pdp ml-[-5px] !w-[80%]';
  const buttonClasses = forQuote ? 'btn ml-2 !w-30%]' : 'btn ml-2 !w-[30%]';

  const buttonElement = (
    <button className={buttonClasses} onClick={handleUploadClick} disabled={isFileUploaded}>
      {formatErrorMessage({
        id: isFileUploaded ? 'file.uploaded' : 'file.upload',
        defaultMessage: isFileUploaded ? 'Done!' : 'Upload File',
      })}
    </button>
  );

  return (
    <>
      {labelElement}
      <div className="flex gap-1">
        <div className={mainClasses}>
          {inputElement}
          <label
            htmlFor="fileUpload"
            className="add-file-label border-1 !ml-0 !mt-[-7px] flex items-center !rounded-[6px] border-solid !border-[#ABABAB] !py-[0px] !pr-[0px] !pb-[1px] !pl-[2px] text-[12px] font-semibold !text-[#ABABAB]"
          >
            {file ? (
              <>
                <span className="filename py-[12px]">{file.name}</span>
                <span className="fileclearbutton text-[#0280c7]" onClick={(e) => onClearUpload(e)}>
                  X
                </span>
              </>
            ) : (
              <>
                <span className="browse-file px-[8px] py-[10px]">
                  {formatMessage({ id: 'file.browse', defaultMessage: 'Browse' })}
                </span>
                <span className="ml-1">{formatMessage({ id: 'file.add', defaultMessage: 'Add File' })}</span>
              </>
            )}
          </label>
        </div>
        {buttonElement}
      </div>
      <p className="mt-[8px] text-[12px] font-normal text-[#676767]">
        {formatMessage({
          id: 'file.spec',
          defaultMessage: `Only ${accept} files. ${maxFileSizeInMB.toString()} MB max file size.`,
          values: { accept: accept, maxSize: maxFileSizeInMB.toString() },
        })}
      </p>
    </>
  );
};

export default FileUpload;
