import React, { useState } from 'react';
import Image from 'frontastic/lib/image';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';

const QuoteItem = ({ item, sellers, quantity, isItemDeleted, isQuantityChanged, productPackage }) => {
  const [hideSellers, setHideSellers] = useState(true);
  const [itemQuantity, setItemQuantity] = useState(quantity);
  const [qtyError, setQtyError] = useState('');
  const { masterVariant = {} } = item;

  const getAttribute = (attributeName: string): string => {
    const attr = masterVariant.attributes.filter((attr) => attr.name === attributeName);
    if (attr.length > 0 && attr[0].value) {
      return attr[0].value['en-IN'] || attr[0].value;
    }
    return '';
  };

  const handleHideSellers = () => {
    setHideSellers(!hideSellers);
  };

  const handleDeleteButton = () => {
    isItemDeleted(item.id);
  };

  const handleIncrement = () => {
    setItemQuantity(itemQuantity + 1);
    isQuantityChanged(item.id, itemQuantity + 1);
  };

  const handleDecrement = () => {
    if (itemQuantity > 1) {
      setItemQuantity(itemQuantity - 1);
      isQuantityChanged(item.id, itemQuantity - 1);
    }
  };
  const handleQuantityChange = (e) => {
    if (isNaN(e) || e == 0 || e == '' || e < 1) {
      setQtyError(`Minimum Quantity should be 1`);
      return 0;
    } else {
      setQtyError('');
      setItemQuantity(e);
    }
  };

  return (
    <div className="quote-item">
      <div className="quote-item-one">
        <div className="quote-item-image">
          <Image src={masterVariant?.images[0]?.url} alt={item.name} className="" />
        </div>
        <div className="quote-item-details">
          <div className="quote-item-casno">{getAttribute('cas-no')}</div>
          <div className="quote-item-name">{item.name['en-IN']}</div>
          <div className="quote-item-line">Dosage: {getAttribute('dosage')}</div>
          <div className="quote-item-line">Package: {productPackage}</div>
          <div className="quote-item-line">Purity: {getAttribute('purity')}</div>
          <div className="quote-item-line">Country of Origin: en-IN</div>
          <div className="quote-item-line">
            Seller List:{' '}
            <span className="quote-item-hide" onClick={handleHideSellers}>
              <u>{hideSellers ? 'View' : 'Hide'}</u>
            </span>
          </div>
        </div>
        <div className="quote-item-buttons">
          <div className="cart-item-qty" style={{ width: 'unset' }}>
            <span className="cart-item-qty-label">Quantity : </span>
            <div className="cart-item-qty-buttons">
              <button aria-label="Reduce" type="button" className={`cart-item-qty-btn`} onClick={handleDecrement}>
                <MinusIcon width={17} />
              </button>
              <input
                className="plp-weight pdp-weight"
                type="text"
                name="qty"
                id="qty"
                step="1"
                value={itemQuantity || 0}
                onChange={(e) => handleQuantityChange(e.target.value)}
                // onChange={() => {}}
              />
              {/* <span className="">{itemQuantity}</span> */}
              <button
                type="button"
                aria-label="Increment"
                className={`cart-item-qty-btn cursor-pointer`}
                onClick={handleIncrement}
              >
                <PlusIcon width={17} />
              </button>
            </div>
          </div>
          {/* <button className="btn">Edit</button> */}
          {qtyError && <p className="request-quote-error">{qtyError}</p>}
          <button className="btn">Edit</button>
          <button className="btn btn-outline" onClick={handleDeleteButton}>
            Delete
          </button>
        </div>
      </div>
      {!hideSellers && (
        <div className="quote-item-two">
          <div className="quote-item-two-text">You have selected below Sellers to get quotes from</div>
          <div>
            {sellers.map((s, index) => (
              <div key={index} className="quote-item-seller">
                {s.name}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="quote-item-divider">&nbsp;</div>
    </div>
  );
};

export default QuoteItem;
