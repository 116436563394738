export const STATIC_SSO_URL = '/auth/login/sso';
export const STATIC_GET_OTP_URL = '/customer/send/otp';
export const STATIC_VERIFY_OTP_URL = '/customer/verify/otp';
export const STATIC_POPULAR_PRODUCTS_URL = '/search/popularProducts?searchString=';
export const STATIC_REVIEWS_URL = '/reviews';
export const STATIC_ORDERS_URL = '/orders';
export const STATIC_QUICK_RFQ = '/cart/quickRFQ';
export const STATIC_QUICK_RFQ_NUM = '/cart/getQuoteNumber';
export const STATIC_QUOTE_LIST = '/cart/myQuoteList';
export const STATIC_QUOTE_DETAILS = '/cart/quoteDetails';
export const STATIC_QUOTE_NOTIFICATIONS = '/cart/quote/notifications';
export const STATIC_IMPORT_PROJECT = '/cart/excelCart';
export const STATIC_CREATE_QUOTE_CART = '/cart/createQuoteCart';
export const STATIC_CREATE_QUOTE_REQUEST = '/cart/createQuoteRequest';
export const STATIC_QUOTE_DOCUMENTS_UPLOAD = '/cart/quoteDocumentsUpload';
export const STATIC_GET_SELLER_DETAILS = '/cart/getSellerDetails';
export const STATIC_SELLER_PO = 'cart/sellerPOResponse';
export const STATIC_BANK_CHEQUE_UPLOAD = 'customer/bankChequeUpload';
export const STATIC_GET_CONTACT_QUERY_TYPE = '/customer/getContactQueryType';
export const STATIC_SEND_QUERY = '/customer/sendQuery';
export const STATIC_GET_VENDOR_DETAILS = '/cart/getSellerDetails';
export const STATIC_CANCEL_ORDER = '/cart/update-order-status';
export const STATIC_GET_MEGA_MENU_DETAIL = '/search/mega-menu';
export const STATIC_VIEW_QUOTE = '/cart/viewQuote';
export const STATIC_QUOTE_ACTION = '/cart/quoteAction';
export const STATIC_ORDER_PUBLISH ='cart/publish/orders'
