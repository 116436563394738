import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { concat } from '@apollo/client';
import { Popover } from '@headlessui/react';
import Typography from 'components/commercetools-ui/typography';

const MegaMenuContent = ({ category, categoryIdx }) => {
  //i18n messages
  const [showSubCategories, setShowSubCategories] = useState(0);
  const [counter, setCounter] = useState(5);
  const [showSeeLessButton, setShowLessButton] = useState(false);
  const [showSeeMoreButton, setShowMoreButton] = useState(true);
  const viewMoreCategories = () => {
    setCounter(category?.children[showSubCategories].children.length);
    setShowLessButton(true);
    setShowMoreButton(false);
  };
  const viewLessCategories = () => {
    setCounter(5);
    setShowMoreButton(true);
    setShowLessButton(false);
  };
  return (
    <div className="megaMenuContainerTop relative bg-[#FFFFFF] ">
      <div className="megaMenuContainer mx-auto max-w-7xl">
        <div className="grid grid-cols-1 items-start gap-x-8 gap-y-10 ">
          <div className="grid grid-cols-2 ">
            <div className="megaMenuRightWrap" style={{ height: 'auto', maxHeight: '300px', overflowY: 'auto' }}>
              <p
                id={`desktop-featured-heading-${categoryIdx}`}
                className="pt-[20px] pl-[10px] text-center text-[16px] font-semibold tracking-[1px] text-[#0280C7]"
              >
                {/* Categories */}
              </p>
              <ul
                role="list"
                aria-labelledby={`desktop-featured-heading-${categoryIdx}`}
                className="megaMenuRightList my-6 space-y-6 sm:my-4 sm:space-y-4"
              >
                {category.children &&
                  category.children.map((item, index) => (
                    <NextLink href={item.slug} key={item.id}>
                      <li
                        className={
                          showSubCategories === index
                            ? 'first_level_subCategory megaMenuRightListItem flex justify-center bg-[#cce6f44d] px-4 py-2 pl-[3rem] text-[14px] font-medium  text-[#182F43] hover:font-bold'
                            : 'megaMenuRightListItem flex justify-center px-4 py-2 pl-[3rem] text-[14px] font-medium  text-[#182F43] hover:font-bold'
                        }
                        onClick={() => setShowSubCategories(index)}
                        onMouseOver={() => setShowSubCategories(index)}
                      >
                        <Popover.Button className="l2_heading cursor-pointer text-left text-[14px] font-bold text-[#182F43] hover:font-bold hover:underline">
                          <Typography>{item.name}</Typography>
                        </Popover.Button>
                        <span className="l2_heading_arrow cursor-pointer hover:font-bold">❯</span>
                      </li>
                    </NextLink>
                  ))}
              </ul>
            </div>
            <div
              className="megaMenuLeftWrap bg-[#cce6f44d]"
              style={{ height: 'auto', maxHeight: '300px', overflowY: 'auto' }}
            >
              <p
                id={`desktop-featured-heading-${categoryIdx}`}
                className="pt-[20px] pl-[5px] text-center text-[16px] font-semibold tracking-[1px] text-[#0280C7]"
              >
                {/* Sub Categories */}
              </p>
              <ul
                role="list"
                aria-labelledby={`desktop-featured-heading-${categoryIdx}`}
                className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
              >
                {console.log(category)}
                {category?.children[showSubCategories] &&
                  category?.children[showSubCategories].children.slice(0).map((item) => (
                    <NextLink
                      href={
                        category?.name === 'Chemicals' ||
                        category?.name === 'MRO' ||
                        category?.name === 'API' ||
                        category?.name === 'Life Sciences'
                          ? item.slug
                          : '/l1-products'
                      }
                      key={item.id}
                    >
                      <li className="second_level_subCategory mt-6 flex justify-start space-y-6 sm:mt-4 sm:space-y-4">
                        <Popover.Button className="l3_heading cursor-pointer pl-[5%] text-[14px] font-bold leading-7 text-[#303030] hover:font-bold hover:underline">
                          <Typography>{item.name}</Typography>
                        </Popover.Button>
                      </li>
                    </NextLink>
                  ))}
              </ul>
              {category?.children[showSubCategories].children.length > 5 && showSeeMoreButton && (
                <button className="see_more_reviews lg:ml-[19px]" onClick={viewMoreCategories}>
                  See More
                </button>
              )}
              {showSeeLessButton && (
                <button className="see_more_reviews lg:ml-[19px]" onClick={viewLessCategories}>
                  See Less
                </button>
              )}
            </div>
            {/* <div>
              <p id="desktop-categories-heading" className="font-medium text-gray-900">
                {formatMessage({ id: 'categories', defaultMessage: 'Categories' })}
              </p>
              <ul
                role="list"
                aria-labelledby="desktop-categories-heading"
                className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
              >
                {category.categories.map((item) => (
                  <li key={item.name} className="flex">
                    <NextLink href={item.href}>
                      <a className="hover:text-gray-800">
                        <Typography>{item.name}</Typography>
                      </a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-8 gap-y-10">
            <div>
              <p id="desktop-collection-heading" className="font-medium text-gray-900">
                {formatMessage({ id: 'collection', defaultMessage: 'Collection' })}
              </p>
              <ul
                role="list"
                aria-labelledby="desktop-collection-heading"
                className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
              >
                {category.collection.map((item) => (
                  <li key={item.name} className="flex">
                    <NextLink href={item.href}>
                      <a className="hover:text-gray-800">
                        <Typography>{item.name}</Typography>
                      </a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <p id="desktop-brand-heading" className="font-medium text-gray-900">
                {formatMessage({ id: 'brands', defaultMessage: 'Brands' })}
              </p>
              <ul role="list" aria-labelledby="desktop-brand-heading" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                {category.brands.map((item) => (
                  <li key={item.name} className="flex">
                    <NextLink href={item.href}>
                      <a className="hover:text-gray-800">
                        <Typography>{item.name}</Typography>
                      </a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegaMenuContent;
