import { useFormat } from 'helpers/hooks/useFormat';
import React, { useState } from 'react';
import Checkbox from './checkbox';

const MobileMembersList = ({
  memberDetails,
  handleSelect,
  selectedIdForStatusUpdate,
  handleAddMember,
  handleApproveMember,
  handleDeclineMember,
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const { formatMessage } = useFormat({ name: 'common' });
  const [showButton, setShowButton] = useState(false);
  const [limit, setLimit] = useState(10);
  const [visibleMembers, setVisibleMembers] = useState(10);
  const [showAll, setShowAll] = useState(false);

  const handleSelectAll = () => {
    if (isCheckAll) {
      setIsCheck([]);
    } else {
    }
    setIsCheckAll(!isCheckAll);
  };
  const handleSeeMoreClick = () => {
    setVisibleMembers((prevVisibleMembers) => prevVisibleMembers + 10);
    setLimit((prevLimit) => prevLimit + 10);
  };

  return (
    <>
      <div className="list-main">
        <div className="member-list-main">
          <div className="quote-title">Members List({memberDetails?.length - 1 || 0})</div>
          <p className="member_p">List of the members associated with your organisation</p>
        </div>
        <div className="allbuttons">
          {/* {!inEdit && ( */}
          <button className="addMember" onClick={handleAddMember}>
            {formatMessage({ id: 'add-member', defaultMessage: 'Add Member' })}
          </button>
          <button
            className="approveMember"
            disabled={!selectedIdForStatusUpdate}
            style={{ backgroundColor: !selectedIdForStatusUpdate ? 'gray' : '' }}
            onClick={handleApproveMember}
          >
            {formatMessage({ id: 'approve-member', defaultMessage: 'Approve Member' })}
          </button>
          <button
            className="declineMember"
            disabled={!selectedIdForStatusUpdate}
            style={{ backgroundColor: !selectedIdForStatusUpdate ? 'gray' : '' }}
            onClick={handleDeclineMember}
          >
            {formatMessage({ id: 'decline-member', defaultMessage: 'Decline Member' })}
          </button>
          {/* )} */}
        </div>
        <div className="search-member">
          <svg
            //onClick={() => ''}
            className="plp_search_Icon mx-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
              stroke="#003769"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.0004 21L16.6504 16.65"
              stroke="#003769"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            className="search-member-field"
            type="text"
            id="message"
            name="message"
            placeholder="Search by Order and Reference no."
          />
          <div>
            <button className="btn-member-search">Search</button>
          </div>
        </div>
        <div className="memberlist_filter_sortby">
          <button
            className="plpFilterModalBtn"
            type="button"
            onClick={() => {
              //   setShowModal(true);
            }}
          >
            Filters
          </button>
          <button
            className="plpFilterModalBtn"
            type="button"
            onClick={() => {
              //   setShowSortByModal(true);
            }}
          >
            Sort By
          </button>
        </div>
        <div className="select_member">
          <span className="select_All">select All</span>
          <label>
            {
              <Checkbox
                type="checkbox"
                name="selectAll"
                id="selectAll"
                handleClick={handleSelectAll}
                isChecked={isCheckAll}
              />
            }
          </label>
        </div>
        <div className="mobile-member-list">
          {memberDetails
            ?.filter((data) => data.role !== 'Admin')
            ?.map((elements, index) => (
              <div key={index} className="cards-grid-members">
                <ul className="memberlist_top_status">
                  <li>
                    <span className="member_status">{elements?.status}</span>
                  </li>
                  <li className="action_checkbox">
                    <Checkbox
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={() => {
                        handleSelect(elements.id);
                      }}
                      isChecked={isCheckAll || selectedIdForStatusUpdate == elements.id}
                    />
                  </li>
                </ul>
                <ul className="member_fields">
                  <li className="member_data">First Name </li>
                  <li className="member_details">{elements?.firstName}</li>
                </ul>
                <ul className="member_fields">
                  <li className="member_data">Last Name </li>
                  <li className="member_details">{elements?.lastName}</li>
                </ul>
                <ul className="member_fields">
                  <li className="member_data">Email </li>
                  <li className="member_details">{elements?.email}</li>
                </ul>
                <ul className="member_fields">
                  <li className="member_data">Location </li>
                  <li className="member_details">{elements?.address?.city}</li>
                </ul>
                <ul className="member_fields">
                  <li className="member_data">Role </li>
                  <li className="member_details">{elements.role}</li>
                </ul>
                {showAll && (
                  <ul className="member_fields">
                    <li className="member_data">Status </li>
                    <li className="member_details">{elements.status}</li>
                  </ul>
                )}
                <div className="showbutton">
                  {<button onClick={() => setShowAll(!showAll)}>{showAll ? 'View Less' : 'View More'}</button>}
                </div>
              </div>
            ))}
        </div>

        <div className="showMoreBtnMain">
          {showButton && (
            <button className="orderListMoreBTN" onClick={handleSeeMoreClick}>
              See more
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default MobileMembersList;
