import React from 'react';
import { logout } from 'frontastic/actions/account';
import { Account } from '@Types/account/Account';
import { useState } from 'react';
import MegaMenuMobile from './mega-menu/mega-menu-mobile';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import NextLink from 'next/link';

interface HeaderMobileProps {
  account: Account;
  megaMenuData: any;
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({ account, megaMenuData }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const handleLogout = () => {
    logout();
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      instance.logoutPopup();
      logout();
    }
  };

  const [open, setOpen] = useState(false);

  return (
    <div className="block lg:hidden">
      <ul className="myAccountMainHB">
        {megaMenuData && <MegaMenuMobile megaMenuData={megaMenuData} />}
        <li className="myAccountItemsHB">
          <span className="myAccountIconHB">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 21.9888V19.9888C20 18.9279 19.5786 17.9105 18.8284 17.1603C18.0783 16.4102 17.0609 15.9888 16 15.9888H8C6.93913 15.9888 5.92172 16.4102 5.17157 17.1603C4.42143 17.9105 4 18.9279 4 19.9888V21.9888"
                stroke="#003769"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 11.9888C14.2091 11.9888 16 10.1979 16 7.98877C16 5.77963 14.2091 3.98877 12 3.98877C9.79086 3.98877 8 5.77963 8 7.98877C8 10.1979 9.79086 11.9888 12 11.9888Z"
                stroke="#003769"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <NextLink href="account">My Profile</NextLink>{' '}
        </li>
        <li className="myAccountItemsHB">
          <span className="myAccountIconHB">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 6.98877V4.98877H10V6.98877H14ZM4 8.98877V19.9888H20V8.98877H4ZM20 6.98877C21.11 6.98877 22 7.87877 22 8.98877V19.9888C22 21.0988 21.11 21.9888 20 21.9888H4C2.89 21.9888 2 21.0988 2 19.9888L2.01 8.98877C2.01 7.87877 2.89 6.98877 4 6.98877H8V4.98877C8 3.87877 8.89 2.98877 10 2.98877H14C15.11 2.98877 16 3.87877 16 4.98877V6.98877H20Z"
                fill="#676767"
              />
            </svg>
          </span>
          My Projects{' '}
        </li>
        <li className="myAccountItemsHB">
          <span className="myAccountIconHB">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 6.98877V4.98877H10V6.98877H14ZM4 8.98877V19.9888H20V8.98877H4ZM20 6.98877C21.11 6.98877 22 7.87877 22 8.98877V19.9888C22 21.0988 21.11 21.9888 20 21.9888H4C2.89 21.9888 2 21.0988 2 19.9888L2.01 8.98877C2.01 7.87877 2.89 6.98877 4 6.98877H8V4.98877C8 3.87877 8.89 2.98877 10 2.98877H14C15.11 2.98877 16 3.87877 16 4.98877V6.98877H20Z"
                fill="#676767"
              />
            </svg>
          </span>
          <NextLink href="quotes/quotes-list">RFQ Listing</NextLink>{' '}
        </li>
        <li className="myAccountItemsHB">
          <span className="myAccountIconHB">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 6.98877V4.98877H10V6.98877H14ZM4 8.98877V19.9888H20V8.98877H4ZM20 6.98877C21.11 6.98877 22 7.87877 22 8.98877V19.9888C22 21.0988 21.11 21.9888 20 21.9888H4C2.89 21.9888 2 21.0988 2 19.9888L2.01 8.98877C2.01 7.87877 2.89 6.98877 4 6.98877H8V4.98877C8 3.87877 8.89 2.98877 10 2.98877H14C15.11 2.98877 16 3.87877 16 4.98877V6.98877H20Z"
                fill="#676767"
              />
            </svg>
          </span>
          <NextLink href="quotes/request-for-quote">Request For Quote</NextLink>{' '}
        </li>
        <li className="myAccountItemsHB">
          <span className="myAccountIconHB">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 2.48877C6.47715 2.48877 2 6.96592 2 12.4888C2 18.0116 6.47715 22.4888 12 22.4888C17.5228 22.4888 22 18.0116 22 12.4888C22 6.96592 17.5228 2.48877 12 2.48877Z"
                stroke="#676767"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 16.4888L12 12.4888"
                stroke="#676767"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 8.48877L11.99 8.48877"
                stroke="#676767"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          Company Information{' '}
        </li>
        {account && (
         <>
         <li className="myAccountItemsHB">
            <span className="myAccountIconHB">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 2.48877C6.5 2.48877 2 6.98877 2 12.4888C2 17.9888 6.5 22.4888 12 22.4888C17.5 22.4888 22 17.9888 22 12.4888C22 6.98877 17.5 2.48877 12 2.48877ZM13 4.55877C16.61 5.00877 19.48 7.88877 19.93 11.4888H13V4.55877ZM4 12.4888C4 8.42877 7.07 5.04877 11 4.55877V20.4288C7.07 19.9288 4 16.5488 4 12.4888ZM13 20.4188V13.4888H19.93C19.48 17.0988 16.61 19.9688 13 20.4188Z"
                  fill="#676767"
                />
              </svg>
            </span>
            <NextLink href="admin-dashboard">Admin Account</NextLink>{' '}
          </li>
          <li className="myAccountItemsHB">
            <span className="myAccountIconHB">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 2.48877C6.5 2.48877 2 6.98877 2 12.4888C2 17.9888 6.5 22.4888 12 22.4888C17.5 22.4888 22 17.9888 22 12.4888C22 6.98877 17.5 2.48877 12 2.48877ZM13 4.55877C16.61 5.00877 19.48 7.88877 19.93 11.4888H13V4.55877ZM4 12.4888C4 8.42877 7.07 5.04877 11 4.55877V20.4288C7.07 19.9288 4 16.5488 4 12.4888ZM13 20.4188V13.4888H19.93C19.48 17.0988 16.61 19.9688 13 20.4188Z"
                  fill="#676767"
                />
              </svg>
            </span>
            <NextLink href="members/members-list">Member List</NextLink>{' '}
          </li>
          </>
        )}

        <li className="myAccountItemsHB">
          <span className="myAccountIconHB">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.5 3.48877C8.53 3.48877 4.5 7.51877 4.5 12.4888H1.5L5.39 16.3788L5.46 16.5188L9.5 12.4888H6.5C6.5 8.61877 9.63 5.48877 13.5 5.48877C17.37 5.48877 20.5 8.61877 20.5 12.4888C20.5 16.3588 17.37 19.4888 13.5 19.4888C11.57 19.4888 9.82 18.6988 8.56 17.4288L7.14 18.8488C8.77 20.4788 11.01 21.4888 13.5 21.4888C18.47 21.4888 22.5 17.4588 22.5 12.4888C22.5 7.51877 18.47 3.48877 13.5 3.48877ZM12.5 8.48877V13.4888L16.75 16.0088L17.52 14.7288L14 12.6388V8.48877H12.5Z"
                fill="#676767"
              />
            </svg>
          </span>
          <NextLink href="orders/order-list">Order History</NextLink>{' '}
        </li>
        {account && (
          <li className="myAccountItemsHB">
            <span className="myAccountIconHB">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2461_156718)">
                  <path
                    d="M21 10.4888C21 17.4888 12 23.4888 12 23.4888C12 23.4888 3 17.4888 3 10.4888C3 8.10182 3.94821 5.81264 5.63604 4.12481C7.32387 2.43698 9.61305 1.48877 12 1.48877C14.3869 1.48877 16.6761 2.43698 18.364 4.12481C20.0518 5.81264 21 8.10182 21 10.4888Z"
                    stroke="#676767"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 13.4888C13.6569 13.4888 15 12.1456 15 10.4888C15 8.83192 13.6569 7.48877 12 7.48877C10.3431 7.48877 9 8.83192 9 10.4888C9 12.1456 10.3431 13.4888 12 13.4888Z"
                    stroke="#676767"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2461_156718">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.48877)" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            Addresses{' '}
          </li>
        )}

        <li className="myAccountItemsHB">
          <span className="myAccountIconHB">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 22.4888C17.5228 22.4888 22 18.0116 22 12.4888C22 6.96592 17.5228 2.48877 12 2.48877C6.47715 2.48877 2 6.96592 2 12.4888C2 18.0116 6.47715 22.4888 12 22.4888Z"
                stroke="#676767"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.08984 9.48885C9.32495 8.82052 9.789 8.25696 10.3998 7.89798C11.0106 7.53901 11.7287 7.40779 12.427 7.52756C13.1253 7.64734 13.7587 8.01038 14.2149 8.55238C14.6712 9.09438 14.9209 9.78037 14.9198 10.4889C14.9198 12.4889 11.9198 13.4889 11.9198 13.4889"
                stroke="#676767"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 17.4888H12.01"
                stroke="#6900EF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          Support{' '}
        </li>

        {account && (
          <li className="myAccountItemsHB logoutBTN" onClick={handleLogout}>
            <span className="myAccountIconHB">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.5 8.48877L16.09 9.89877L17.67 11.4888H9.5V13.4888H17.67L16.09 15.0688L17.5 16.4888L21.5 12.4888L17.5 8.48877ZM5.5 5.48877H12.5V3.48877H5.5C4.4 3.48877 3.5 4.38877 3.5 5.48877V19.4888C3.5 20.5888 4.4 21.4888 5.5 21.4888H12.5V19.4888H5.5V5.48877Z"
                  fill="#0068A3"
                />
              </svg>
            </span>
            Logout{' '}
          </li>
        )}
      </ul>
    </div>
  );
};

export default HeaderMobile;
