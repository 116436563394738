import React, { useState, useRef, useEffect } from 'react';
import { StarIcon } from '@heroicons/react/outline';
import Rate from './Rate';
import ReviewSuccess from './reviewSucessModal';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_REVIEWS_URL } from 'helpers/constants/statisURLs';
import { REVIEW_ID } from 'helpers/constants/envVariables';
import { useFormat } from 'helpers/hooks/useFormat';
import { FileUpload } from 'components/commercetools-ui/form';
import toast from 'react-hot-toast';

const imageMimeType = /image\/(png|jpg|jpeg)/i;
export default function AddReview({ setOpenModal, sellerName, productId, handleReviewCallBack, vendorCode }) {
  const [rating, setRating] = useState(0);
  const [title, setTiltle] = useState('');
  const [description, setDescription] = useState('');
  const [addReview, setAddReview] = useState(null);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [filename, setFileName] = useState('');
  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [fileDataURL, setFileDataURL] = useState(null);
  const [showReviewSuccessModal, setShowReviewSuccessModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [error, setError] = useState('');
  const [ratingError, setRatingError] = useState('');
  const [getReviews, setGetReviews] = useState([]);
  const [openFile, setOpenFile] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert('Image mime type is not valid');
      return;
    }
    setFile(file);
  };
  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);
  const showAlert = (type: string, id: string, defaultMessage: string) => {
    if (type === 'success')
      toast.success(
        formatCartMessage({ id: 'importProj.fileUpload.done', defaultMessage: 'File uploaded successfully...' }),
      );
    else
      toast.error(
        formatCartMessage({
          id: 'importProj.fileUpload.error',
          defaultMessage: 'Something went wrong. Please try again.',
        }),
      );
  };
  const handleUploadClick = () => {
    inputRef.current?.click();
    showAlert('success', 'importProj.fileupload.done', 'File Uploaded successfully...');
    setIsFileUploaded(true);
  };
  const clearUpload = () => {
    setFile(null);
    setIsFileUploaded(false);
  };

  const handleTitleChange = (e) => {
    setTiltle(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    if (e.target.value) {
      setError('');
    }
  };
  const reqHeaders = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  };

  const reqBody = {
    authorName: 'Anonymous',
    title: title,
    text: description,
    rating: rating,

    target: {
      typeId: 'product',
      id: productId ? productId : '',
    },
    custom: {
      typeId: REVIEW_ID,
      fields: {
        review_images: fileDataURL ? fileDataURL : '',
        vendor_name: sellerName ? sellerName : '',
        vendor_code: vendorCode,
      },
    },
  };
  const getReviewsHandler = async () => {
    if (productId) {
      try {
        const response = await commercetoolsAPIClient.get(
          `/reviews?where=target(typeId%3D%22product%22%20and%20id%3D%22${productId}%22)`,
          reqHeaders,
        );
        setGetReviews(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getReviewsHandler();
  }, [addReview, showReviewSuccessModal]);

  const createReview = async () => {
    if (rating > 0 && description) {
      try {
        const response = await commercetoolsAPIClient.post(STATIC_REVIEWS_URL, reqBody, reqHeaders);
        setAddReview(response.data);
        // localStorage.setItem('showReview', 'review');
        setShowReviewSuccessModal(true);
        setTimeout(() => {
          setOpenModal(false);
        }, 2000);
        handleReviewCallBack(true);
      } catch (error) {
        console.log(error);
      }
    } else if (rating < 1) {
      setRatingError('Please add the rating');
      return false;
    } else if (!description) {
      setError('Please provide the review');
      return false;
    }
  };

  function CallBack(closeModalValue) {
    if (closeModalValue) {
      setOpenModal(false);
    }

    return (
      <div>
        <p>Message feom child - {closeModalValue}</p>
      </div>
    );
  }
  function ReviewCallBack(ratingValue) {
    if (ratingValue > 0) {
      setRatingError('');
    }
    return (
      <div>
        <p>Message feom child - {ratingValue}</p>
      </div>
    );
  }

  useEffect(() => {
    const body = document.querySelector('body');
    if (body && document.querySelector('.blockBodyScroll')) {
      body.style.overflow = 'hidden';
    }
    return () => {
      if (body) {
        body.style.overflow = 'visible';
      }
    };
  }, []);
  const onFileChange = (file: File) => {
    if (file.type.match(imageMimeType)) {
      setFile(file);
    } else {
      alert('Image mime type is not valid');
      return;
    }
  };

  return (
    <>
      <div className="rating_modal blockBodyScroll fixed right-0 top-0 z-[5001] h-full w-full bg-gray-900 bg-opacity-75">
        <div className="bg-black fixed inset-0 h-full w-full opacity-100" onClick={() => setOpenModal(false)}></div>
        <div className=" add_Review_Popup flex min-h-screen items-center px-4 py-8">
          <div className=" rating_modal_height relative mx-auto  max-h-fit w-full max-w-fit max-w-lg rounded-md bg-white  shadow-lg">
            <div className="plpMobileFilterTextWrap">
              <div className="ml-auto  mr-auto px-[16px] lg:mt-[20px]">
                <p className="review_modal_closebtn absolute mt-[20px] lg:mt-[0px]" onClick={() => setOpenModal(false)}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                      fill="#0068A3"
                    />
                  </svg>
                </p>
              </div>
            </div>
            <div className="review_modal_content ml-[30px] mr-[30px] lg:mt-[26px] lg:ml-[47px] lg:mr-[47px]">
              <p className="quick_rfq_heading text-[28px] font-bold text-[#1E1E1E] lg:text-[32px]">Add Review</p>
              <p className="mt-[27px] text-[14px] font-normal  text-[#1E1E1E] lg:mt-[27px] lg:text-[14px]">
                Add your valuable feedback based on your experience with us
              </p>
              <div className="mt-[15px] flex text-[12px]   font-normal text-[#1E1E1E]">
                Seller : <p className="ml-[2px] font-bold">{sellerName}</p>
              </div>

              <p className="mt-[8px] text-left   text-[12px] font-normal text-[#1E1E1E]">
                Score<span className="ml-[1px] text-[#EB4747]">*</span>
              </p>
              <p></p>
              <div className=" mt-[8px]">
                <Rate rating={rating} onRating={(rate) => setRating(rate)} reviewCallBack={ReviewCallBack} />
                {ratingError ? <p className="error_login">{ratingError}</p> : ''}
              </div>
              <div className="mt-[10px]">
                <label htmlFor="title" className="review_description_heading text-left">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Add Title"
                  autoComplete="given-title"
                  className="review_modal_title bg-[#FFFFFF] p-[7px]  font-normal text-[#1E1E1E] lg:text-[12px]"
                  onChange={handleTitleChange}
                />
              </div>
              {/* <p className="add_document mt-[15px] text-[12px]  text-[#303030]">Add Document</p>
              <p className="mt-[0px] text-[12px] font-normal text-[#676767]">
                Only .jpg and .png files. 5 MB max file size.
              </p> */}
              <div className="add_document mt-[15px] text-left text-[12px]  text-[#303030]">
                <FileUpload
                  file={file}
                  name="file_upload"
                  label={formatCartMessage({ id: 'importProj.addDocument', defaultMessage: 'Add Document' })}
                  accept=".png, .jpg, .jpeg"
                  isFileUploaded={isFileUploaded}
                  onFileChange={onFileChange}
                  onUpload={handleUploadClick}
                  clearUpload={clearUpload}
                />
              </div>
              {/* <div className="modal_pdp  flex justify-between lg:mt-[5px] ">
                <div className="upload_file_pdp">
                  <input
                    className="file_upload_button  h-[44px]"
                    id="f02"
                    type="file"
                    ref={inputRef}
                    onChange={handleFileChange}
                    accept=".png, .jpg, .jpeg"
                    style={{ display: 'none' }}
                  />
                  {file ? (
                    <label htmlFor="f02" className="add-file-label">
                      <span className="browse-file">Browse</span><span className='pl-[20px]'>1 File added</span>
                    </label>
                  ) : (
                    <label htmlFor="f02" className="add-file-label">
                      <span className="browse-file">Browse</span><span className='pl-[20px]'>Add File</span>
                    </label>
                  )}

                </div>
                <button className="upload_document_button upload_button_mobile  h-[40px] lg:ml-[15px] " onClick={handleUploadClick}>
                  Upload Document
                </button>
                <button className="lg:hidden upload_document_button  h-[40px] lg:ml-[15px] " onClick={handleUploadClick}>
                  Upload
                </button>
              </div> */}
              {file ? (
                <button
                  className="file-added ml-[2px] ml-[3px] mt-[5px] p-[7px] text-[14px] text-[#0280C7] lg:ml-0"
                  onClick={() => {
                    setOpenFile(true);
                  }}
                >
                  {file?.name}
                </button>
              ) : (
                ''
              )}
              {/* {fileDataURL ? <img src={fileDataURL} alt='' /> : ''} */}
              <div className="mt-[12px]">
                <p className="review_description_heading text-left">Description</p>
                <textarea
                  required
                  name="Description"
                  id="Description"
                  placeholder="Enter a Description"
                  autoComplete="given-Description"
                  className="review_description_box quote_description_box mt-[6px]  text-[12px]"
                  onChange={handleDescriptionChange}
                />
              </div>
              {error ? <p className="error_login">{error}</p> : ''}
              <div className="flex justify-evenly">
                <button
                  className="continue-browsing cancel-review mt-[10px] h-[40px] text-center text-[14px] font-medium tracking-[1px] text-[#0280c7] lg:mt-[25px] lg:hidden lg:px-[20px]"
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="review_adding_button ml-[10px] h-[40px] lg:mt-[25px] lg:ml-[0px]"
                  onClick={createReview}
                >
                  Add Review
                </button>
              </div>
              {showReviewSuccessModal && (
                <ReviewSuccess setOpenModal={setShowReviewSuccessModal} handleCallBack={CallBack} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
