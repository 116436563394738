import { KeyboardEvent, KeyboardEventHandler, useEffect, useRef } from 'react';
import FormButton from './formButton';

export interface Props {
  /*
   * the value of the input
   */
  readonly value: string;
  /*
   * input name, must be unique on page
   */
  readonly name: string;
  /*
   * type for input, "text" by default
   */
  readonly inputType?: string;
  /*
   * the optional autoComplete attribute for the input
   */
  readonly inputAutoComplete?: string;
  /*
   * the label text displayed to the user
   */
  readonly label?: string;
  /*
   * replaces the component wrapper's className field if provided
   */
  readonly containerClassNames?: string;
  /*
   * replaces the label's className field if provided
   */
  readonly labelClassNames?: string;
  /*
   * replaces the input wrapper's className field if provided
   */
  readonly inputContainerClassNames?: string;
  /*
   * replaces the input's className field if provided
   */
  readonly inputClassNames?: string;
  /*
   * if true replaces the order of the input and label
   */
  readonly inverseLabel?: boolean;
  /*
   * button text visible in search button
   */
  readonly buttonText: string;
  /*
   * function called when search button cicked or hit enter
   */
  readonly onSearch: (searchStr: string) => void;
}

const FormSearchInput = ({
  value,
  name,
  inputType,
  inputAutoComplete,
  label,
  containerClassNames,
  labelClassNames,
  inputContainerClassNames,
  inputClassNames,
  inverseLabel,
  buttonText,
  onSearch,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current.value = value || '';
  }, []);

  const onSearchClick = () => {
    onSearch(inputRef.current.value);
  };

  const onKeyDownHandler = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') onSearchClick();
  };

  const labelElement = (
    <label htmlFor={name} className={labelClassNames || 'block text-sm font-medium text-gray-700 dark:text-light-100'}>
      {label}
    </label>
  );

  const buttonElement = (
    <button
      className="btn ml-2 w-[20%]"
      onClick={(e) => {
        e.preventDefault();
        onSearchClick();
      }}
    >
      {buttonText}
    </button>
  );

  const inputElement = (
    <div className={inputContainerClassNames || 'mt-1 flex'}>
      <span className="absolute z-50 pl-3 pt-3 text-[#0280c7]">
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 26 26  "
          className="h-6 w-6"
        >
          <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
        </svg>
      </span>
      <input
        type={inputType || 'text'}
        id={name}
        name={name}
        ref={inputRef}
        autoComplete={inputAutoComplete}
        className={
          inputClassNames ||
          'border-1 w-[80%] rounded-md border-solid border-[#ABABAB] pl-10 shadow-sm focus:border-accent-400 focus:ring-accent-400 sm:text-sm'
        }
        onKeyDown={onKeyDownHandler}
        checked={inputType === 'checkbox' ? value === 'true' : undefined}
      />
      {buttonElement}
    </div>
  );

  return (
    <div className={containerClassNames || 'col-span-full'}>
      {inverseLabel && inputElement}
      {label && labelElement}
      {!inverseLabel && inputElement}
    </div>
  );
};

export default FormSearchInput;
