import React, { useState, useRef, useEffect } from 'react';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import ProductImageQuote from './image-quote-create/image-quote-create';
import ProductDetailsQuote from './product-details-quote/product-details-quote';
import QuoteDescription from './quote-description/quote-description';
import { useAccount } from 'frontastic';
import QuoteCreateDropdown from './dropdown-quote-create/dropdown-quote-create';
import SuccessQuickRFQ from './success-quote/success-quote';
import { STATIC_GET_SELLER_DETAILS, STATIC_QUICK_RFQ } from 'helpers/constants/statisURLs';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { notifyQuoteActions } from 'components/commercetools-ui/cart/notification';

export default function QuickRFQ({ setOpenModal, product, starRating, review, variant, productQuantity, pdpWeight }) {
  const [showQuoteCreateSuccess, setShowQuoteCreateSuccess] = useState<boolean>(false);
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [showError, setShowError] = useState(false);
  const { account } = useAccount();
  const CallBack = (closeModalValue) => {
    if (closeModalValue) {
      setOpenModal(false);
    }
  };
  const descriptionCallBack = (descriptionValue) => {
    if (descriptionValue) {
      setDescription(descriptionValue);
    }
  };
  const quantityCallBack = (quantityValue) => {
    if (quantityValue) {
      setQuantity(quantityValue);
    }
  };
  const reqBody = {
    lineItems: [
      {
        productName: product?.name,
        variantSKU: variant?.sku,
        quantity: quantity,
      },
    ],
    description: description,
    shippingAddress: {
      country: 'IN',
    },
    customerId: account?.accountId,
    email: account?.['work_email'],
  };

  const quickRFQhandler = async () => {
    try {
      const response = await backendAPIClient.post(STATIC_QUICK_RFQ, reqBody);
      if (response?.data?.response?.status?.code == 200) {
        setShowQuoteCreateSuccess(true);
        //Notify to Buyer
        if (account?.['email'])
          notifyQuoteActions({
            toName: account?.firstName + ' ' + account?.lastName,
            toEmail: account?.['email'],
            subject: 'Quote has been created!',
            body: 'Quote has been created. Please wait for the seller to respond.',
          });
        //Notify to Seller
        const sellerCode = product.variants[0]?.attributes['vendor-code'];
        const sellerContactEmail = await getSellerDetails(sellerCode);
        if (sellerContactEmail)
          notifyQuoteActions({
            toName: product.variants[0]?.attributes?.['vendor-name'],
            toEmail: sellerContactEmail,
            subject: 'Quote has been created!',
            body: 'Received Quote from an buyer. Please view and approve/reject the quote.',
          });
      } else {
        setShowError(true);
      }
    } catch (error) {}
  };

  const getSellerDetails = async (sellerCode: string) => {
    try {
      const sellerDetailRes = await backendAPIClient.get(`${STATIC_GET_SELLER_DETAILS}/?vendorCode=${sellerCode}`);
      return sellerDetailRes?.data?.value?.email;
    } catch (ex) {
      console.error('Error on fetch seller details');
    }
  };

  useEffect(() => {
    const body = document.querySelector('body') as HTMLElement;
    const headerMenu = document.getElementsByClassName('wrapperMain')[0] as HTMLElement;
    if (body && document.querySelector('.blockBodyScroll')) {
      body.style.overflow = 'hidden';
      headerMenu.style.zIndex = '10';
    }
    return () => {
      if (body) {
        body.style.overflow = 'visible';
        headerMenu.style.zIndex = '5000';
      }
    };
  }, []);

  return (
    <>
      <div className="rating_modal blockBodyScroll fixed right-0 top-0  z-[5001] h-full w-full bg-gray-900 bg-opacity-75">
        <div className="bg-black fixed inset-0 h-full w-full opacity-100" onClick={() => setOpenModal(false)}></div>
        <div className=" add_Review_Popup flex min-h-screen items-center px-4 py-8">
          <div className=" rating_modal_height relative mx-auto  max-h-fit w-full max-w-fit max-w-lg rounded-md bg-white  shadow-lg">
            <div className="plpMobileFilterTextWrap">
              <div className=" ml-auto  mr-auto px-[16px] lg:mt-[20px]">
                <p className="review_modal_closebtn absolute mt-[20px] lg:mt-[0px]" onClick={() => setOpenModal(false)}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                      fill="#0068A3"
                    />
                  </svg>
                </p>
              </div>
            </div>
            <div className="review_modal_content ml-[30px] mr-[30px] lg:mt-[26px] lg:ml-[47px] lg:mr-[47px]">
              <p className="quick_rfq_heading text-[28px] font-bold text-[#1E1E1E] lg:text-[32px]">Quick RFQ</p>
              <p className="mt-[10px] text-[14px] font-normal  text-[#1E1E1E] lg:text-[14px] 2xl:mt-[27px]">
                Fill in the details to get you perfect deals
              </p>
              <div className="flex flex-col lg:mt-[17px] lg:flex-row">
                <ProductImageQuote images={[product?.images[0]]} variant={variant} />
                <ProductDetailsQuote
                  productDetails={product}
                  variant={variant}
                  starRating={starRating}
                  review={review}
                />
              </div>
              <div>
                <QuoteCreateDropdown
                  product={product}
                  variant={variant}
                  quantityCallBack={quantityCallBack}
                  productQuantity={productQuantity}
                  pdpWeight={pdpWeight}
                />
              </div>
              <div className="quote_Description">
                <QuoteDescription descriptionCallBack={descriptionCallBack} />
              </div>
              <button className="review_adding_button mt-[10px] h-[40px] 2xl:mt-[25px]" onClick={quickRFQhandler}>
                Send
              </button>
              {showError && <p className="mb-[15px] text-center text-[14px] text-[#EB4747]">Quote Request Failed</p>}
              {showQuoteCreateSuccess && (
                <SuccessQuickRFQ setOpenModal={setShowQuoteCreateSuccess} product={product} handleCallBack={CallBack} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
