import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { STATIC_POPULAR_PRODUCTS_URL } from 'helpers/constants/statisURLs';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import APISlider from './api-slider';

function MostPopularSlider() {
  const [openTab, setOpenTab] = useState(1);
  const [popularProducts, setPopularProducts] = useState([]);
  const [excipientsProducts, setExcipientsProducts] = useState([]);
  const [solventsProducts, setSolventsProducts] = useState([]);
  const router = useRouter();
  const seeAllLinks = { 1: '/API', 2: '/excipients', 3: '/Solvents' };

  const callAPI = async () => {
    const searchString = 'API';
    try {
      const res = await backendAPIClient.get(STATIC_POPULAR_PRODUCTS_URL + searchString);
      setPopularProducts(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const callEXCIPIENTS = async () => {
    const searchString = 'excipients';
    try {
      const res = await backendAPIClient.get(STATIC_POPULAR_PRODUCTS_URL + searchString);
      setExcipientsProducts(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const callSolvents = async () => {
    const searchString = 'Solvents';
    try {
      const res = await backendAPIClient.get(STATIC_POPULAR_PRODUCTS_URL + searchString);
      setSolventsProducts(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    callAPI();
    callEXCIPIENTS();
    callSolvents();
  }, []);
  return (
    <div className="mostPopularContainer  bg-[#F3F5FD] ">
      <div className="container relative mx-auto">
        <div className="flex flex-wrap lg:mb-[24px]">
          <div className="w-full">
            <h1 className="most-popular-title">Most popular</h1>
            <ul className="sliderTabsWrap mb-0 flex list-none flex-row flex-wrap justify-start" role="tablist">
              <li className="text-left">
                <a
                  className={'tab-title  block' + (openTab === 1 ? ' active-api-tab' : '')}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  API
                </a>
              </li>
               <li>
                <a
                  className={'tab-title block' + (openTab === 2 ? ' active-api-tab' : '')}
                  data-toggle="tab"
                  href="/l1-products"
                >
                  Excipients
                </a>
              </li>
              <li>
                <a
                  className={'tab-title block' + (openTab === 3 ? ' active-api-tab' : '')}
                  data-toggle="tab"
                  href="/l1-products"
                >
                  Solvents
                </a>
              </li> 
              <li className="sliderTabsWrapBtn" onClick={() => router.push(seeAllLinks[openTab])}>
                <button className="see_all_button">See All</button>
              </li>
            </ul>
            <div className="mb-6 flex w-full min-w-0 flex-col ">
              <div className="flex-auto">
                <div className="tab-content tab-space">
                  <div className={openTab === 1 ? 'block' : 'hidden'} id="link1">
                    <APISlider popularProducts={popularProducts} mostPopularTile={true} />
                  </div>
                  <div className={openTab === 2 ? 'block' : 'hidden'} id="link2">
                    <APISlider popularProducts={excipientsProducts} mostPopularTile={true} />
                  </div>
                  <div className={openTab === 3 ? 'block' : 'hidden'} id="link3">
                    <APISlider popularProducts={solventsProducts} mostPopularTile={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MostPopularSlider;
