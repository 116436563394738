import { useEffect, useMemo, useState, useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useAccount } from 'frontastic';
import { UserContext } from 'frontastic/lib/renderer';
import AddReview from '../addRatingModal';
import PDPCategory from '../pdp-category';
import ProductCertifications from './product-certifications';
import { BACKEND_AMP_BASE_URL, COMMERCETOOLS_BASE_URL } from 'helpers/constants/envVariables';
import axios from 'axios';
import Loader from 'components/commercetools-ui/loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subDays } from 'date-fns';
import { Dialog, Transition } from '@headlessui/react';
import { useQuery } from '@apollo/client';
import { LOAD_PDP_BREADCRUMB_DATA } from 'components/commercetools-ui/header/graphql-query/mega-menu-graphql';

export default function ProductDetailsSection({
  product,
  variant,
  review,
  starRating,
  handleReviewCallBack,
  handleProductQuantityCallBack,
  handleProductWeightCallBack,
  productQuantityPLP,
  sendMercQuantity,
  sendUserNotes,
  sendUserRdd,
  sendUserEdd,
}) {
  const { contextValue, setContextValue } = useContext(UserContext);
  const specifications = product?.variants[0].attributes;
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [qtyError, setQtyError] = useState('');
  const { account } = useAccount();
  const [plpWeight, setPlpWeight] = useState(contextValue?.variant);
  const [chemicalProduct, setChemicalProduct] = useState(false);
  const [packageValue, setPackageValue] = useState(
    contextValue.plpWeightChanged ? plpWeight : variant?.attributes?.['packaging-size'],
  );
  {
    handleProductWeightCallBack(packageValue);
  }
  const [minimumQuantity, setMinimumQuantity] = useState(1);
  const [productQuantity, setProductQuantity] = useState(minimumQuantity);
  const [weightChanged, setWeightChanged] = useState(1);
  const dosages: Array<any> = [...new Set(product.variants.map((v) => v.attributes['dosage']))];
  const [productDosage, setProductDosage] = useState<any>(dosages[0]);
  const dosageVariants = product.variants.filter((v) => v.attributes['dosage'] === productDosage);
  const [selectedIndexSku, setSelectedIndexSku] = useState(product?.variants[0]?.sku);
  const [loading, setLoading] = useState(false);
  const [mercVendor, setMercVendor] = useState(false);
  const [latestPrice, setLatestPrice] = useState(0);
  const [oldPrice, setOldPrice] = useState(0);
  const [percentageChange, setPercentageChange] = useState<any>(0);
  const [mercQuantity, setMercQuantity] = useState(0);
  const [mercLoader, setMercLoader] = useState(false);
  const [mercUpdatedPrice, setMercUpdatedPrice] = useState(0);
  const [mercUpdatedOldprice, setMercUpdatedOldprice] = useState(0);
  const [expectedDateOfDelivery, setExpectedDateOfDelivery] = useState(undefined);
  const [startDate, setStartDate] = useState(null);
  const [isValidDate, setIsValidDate] = useState(false);
  const [rdd, setRdd] = useState<any>();
  const [selectedPartNumber, setSelectedPartNumber] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [partNumbers, setPartNumbers] = useState([]);
  const [packagingSizeArray, setPackagingSizeArray] = useState([]);
  const [innerDiameterArray, setinnerDiameterArray] = useState([]);
  const [selectedInnerDiameterSize, setSelectedInnerDiameterSize] = useState([]);
  const [particleSizeArray, setParticleSizeArray] = useState([]);
  const [selectedParticleSize, setSelectedParticleSize] = useState([]);
  const [mroProduct, setMroProduct] = useState(false);
  // code added for merc Api changes
  const punchurl = localStorage.getItem('punchoutUrl');
  const hookUrl = localStorage.getItem('hookUrl');
  const tokenForPunchout = localStorage.getItem('token');
  const tokenCategory = localStorage.getItem('token');

  const validateDate = () => {
    if (!startDate) {
      setIsValidDate(false);
    }
  };
  const handleDateChange = (date) => {
    date = date?.toLocaleDateString('en-GB');
    sendUserRdd(date);
    setRdd(date);
    setIsValidDate(true);
  };

  const handleNotes = (event) => {
    const data = event.target.value;
    setTimeout(() => {
      sendUserNotes(data);
    }, 1000);
  };
  const calculatePercentageChange = (latestPrice, existingPrice) => {
    const change = latestPrice - existingPrice;
    const percentage = (Math.abs(change) / existingPrice) * 100;
    const percetageResult = percentage.toFixed(2);
    setPercentageChange(percetageResult);
  };

  const getCategoryData = () => {
    const productID = product?.id;
    const categoryUrl = `${COMMERCETOOLS_BASE_URL}/products/${productID}`;
    axios
      .get(categoryUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenCategory}`,
        },
      })
      .then((response) => {
        const catID = response?.data?.masterData?.current?.categories[0]?.id;
        if (catID) {
          const categorygetUrl = `${COMMERCETOOLS_BASE_URL}/categories/${catID}`;
          axios
            .get(categorygetUrl, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokenCategory}`,
              },
            })
            .then((response) => {
              //  lineItem.categoryKey = response?.data?.key
              product.categoryKey = response?.data?.key;
            })
            .catch((error) => {
              console.error('inner category call error', error);
            });
        }
      })
      .catch((error) => {
        console.error('Error from transfer cart API:', error);
      });
    return;
  };

  useEffect(() => {
    if (product?.variants[0]?.attributes['vendor-id'] == '507078') {
      const productId = product?.id;
      const sku = product?.variants[0]?.sku;
      setMercLoader(true);
      setMercVendor(true);
      handleMerckData(productId, sku);
      getCategoryData();
    } else if (product?.variants[0]?.attributes['vendor-id'] == '535594') {
      const productId = product?.id;
      const sku = product?.variants[0]?.sku;
      setMercLoader(true);
      setMercVendor(true);
      handleAngeneData(productId, sku);
      getCategoryData();
    }
  }, [selectedIndexSku]);
  const handleMerckData = (productId, sku) => {
    const vendorId = product?.variants[0]?.attributes['vendor-id'];
    const mercUrl = `${BACKEND_AMP_BASE_URL}/api/products/${productId}/variants/${selectedIndexSku}?vendorId=${vendorId}`;
    axios
      .get(mercUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenForPunchout}`,
        },
      })
      .then((response) => {
        setLatestPrice(response?.data?.latestPrice);
        setOldPrice(response?.data?.existingPrice);
        setMercQuantity(response?.data?.quantityAvailable);
        setExpectedDateOfDelivery(response?.data?.expectedDateOfDelivery);
        calculatePercentageChange(response?.data?.latestPrice, response?.data?.existingPrice);
        product.Edd = response?.data?.expectedDateOfDelivery;
        product.mercQuantity = response?.data?.quantityAvailable;
        sendMercQuantity(response?.data?.quantityAvailable);
        sendUserEdd(response?.data?.expectedDateOfDelivery);
        setMercLoader(false);
      })
      .catch((error) => {
        console.error('Error from transfer cart API:', error);
      });
    return;
  };
  const handleAngeneData = (productId, sku) => {
    const vendorId = product?.variants[0]?.attributes['vendor-id'];
    const mercUrl = `${BACKEND_AMP_BASE_URL}/api/products/${productId}/variants/${selectedIndexSku}?vendorId=${vendorId}`;
    axios
      .get(mercUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenForPunchout}`,
        },
      })
      .then((response) => {
        setLatestPrice(response?.data?.latestPrice);
        setOldPrice(response?.data?.existingPrice);
        setMercQuantity(response?.data?.quantityAvailable);
        setExpectedDateOfDelivery(response?.data?.expectedDateOfDelivery);
        calculatePercentageChange(response?.data?.latestPrice, response?.data?.existingPrice);
        product.Edd = response?.data?.expectedDateOfDelivery;
        product.mercQuantity = response?.data?.quantityAvailable;
        product.price.centAmount = response?.data?.latestPrice;
        sendMercQuantity(response?.data?.quantityAvailable);
        setMercLoader(false);
      })
      .catch((error) => {
        console.error('Error from transfer cart API:', error);
      });
    return;
  };

  const handleQuantityChange = (e) => {
    if (isNaN(e) || e == 0 || e == '' || e < minimumQuantity) {
      setQtyError(`Minimum Quantity should be ${minimumQuantity == 0 ? 1 : minimumQuantity}`);
      // setQtyError(`Minimum Quantity should be ${minimumQuantity}`);
      return 0;
    } else {
      setQtyError('');
      setProductQuantity(e);
    }
  };
  useMemo(() => {
    if (weightChanged) {
      setTimeout(() => {
        const { MOQ, 'packaging-size': packageSize } = variant?.attributes;
        const moqValue = parseInt(MOQ.split(' ')[0], 10);
        // const moqValue = 1;
        const packageSizeValue = parseInt(packageValue.split(' ')[0], 10);
        const minQty1 = Math.round(moqValue / (packageSizeValue * 1000));
        const minQty2 = Math.round(moqValue / packageSizeValue);
        const minQty3 = Math.round((moqValue * 1000) / packageSizeValue);
        const MOQINLit = MOQ.includes('L');
        const MOQINML = MOQ.includes('ml');
        const MOQINKG = MOQ.includes('kg');
        const MOQINGMS = MOQ.includes('gms');
        const PACKINLit = packageValue.includes('L');
        const PACKINML = packageValue.includes('ml');
        const PACKINKG = packageValue.includes('Kg');
        const PACKINGMS = packageValue.includes('gms');
        if ((MOQINLit && PACKINLit) || (MOQINKG && PACKINKG)) {
          setProductQuantity(minQty2);
          setMinimumQuantity(minQty2);
        } else if ((MOQINML && PACKINGMS) || (MOQINGMS && PACKINGMS)) {
          setProductQuantity(minQty2);
          setMinimumQuantity(minQty2);
        } else if ((MOQINML && PACKINLit) || (MOQINGMS && PACKINKG)) {
          setProductQuantity(minQty1);
          setMinimumQuantity(minQty1);
        } else if ((MOQINLit && PACKINML) || (MOQINKG && PACKINGMS)) {
          setProductQuantity(minQty3);
          setMinimumQuantity(minQty3);
        }
      }, 1000);
      if (productQuantity < minimumQuantity) {
        setQtyError(`Minimum Quantity should be ${minimumQuantity == 0 ? 1 : minimumQuantity}`);
        // setQtyError(`Minimum Quantity should be ${minimumQuantity}`);
      } else {
        setQtyError('');
      }
    }
  }, [weightChanged]);
  useEffect(() => {
    if (productQuantity == 0) {
      setProductQuantity(1);
    }
  }, [productQuantity]);
  const router = useRouter();
  const handleProductWeightChange = (e) => {
    router.push({ pathname: `/${product.productId}/p/${dosageVariants[e.target.selectedIndex].sku}` }, undefined, {
      scroll: false,
    });
    setSelectedIndexSku(dosageVariants[e.target.selectedIndex].sku);
    setPackageValue(e.target.value);
    setWeightChanged((current) => current + 1);
    setPlpWeight(e.target.value);
  };
  const handleAddReview = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      setShowReviewModal(true);
    }
  };
  {
    handleProductQuantityCallBack(productQuantity);
  }
  const requestForQuote = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      router.push('quotes/request-for-quote');
    }
  };

  useEffect(() => {
    if (product && product.variants) {
      const partNumbers = product.variants.map((variant) => variant.attributes['supplier-part-number']);
      setPartNumbers(partNumbers);
    }
  }, [product]);
  const handlePartNumber = (e) => {
    setSelectedPartNumber(e.target.value);

    // Find the packaging sizes for the selected part number
    const selectedPartNumberVariants = product.variants.filter(
      (variant) => variant.attributes['supplier-part-number'] === e.target.value,
    );
    const sizes = selectedPartNumberVariants.map((variant) => variant.attributes['packaging-size']);
    if (sizes !== null) {
      setPackagingSizeArray(sizes);
    } else {
      setPackagingSizeArray(product?.variants[0]?.attributes['packaging-size']);
    }
  };
  const handlePartNumberForMro = (e) => {
    setSelectedPartNumber(e.target.value);

    const selectedPartNumberVariants = product.variants.filter(
      (variant) => variant.attributes['supplier-part-number'] === e.target.value,
    );
    const lengthxinnerDiameter = selectedPartNumberVariants.map(
      (variant) => variant.attributes['lengthxinner-diameter'],
    );
    if (lengthxinnerDiameter !== null) {
      setinnerDiameterArray(lengthxinnerDiameter);
    } else {
      setinnerDiameterArray(product?.variants[0]?.attributes['lengthxinnerDiameter']);
    }
  };
  const handleInnerDiameterChange = (e) => {
    setSelectedInnerDiameterSize(e.target.value);
    const selectedInnerDiameterVariants = product.variants.filter(
      (variant) =>
        variant.attributes['supplier-part-number'] === selectedPartNumber &&
        variant.attributes['lengthxinner-diameter'] === e.target.value,
    );
    const particleSizes = selectedInnerDiameterVariants.map((variant) => variant.attributes['particle-size']);
    if (particleSizes !== null) {
      setParticleSizeArray(particleSizes);
    } else {
      setParticleSizeArray(product?.variants[0]?.attributes['particle-size']);
    }
  };
  const handleParticleSizeChange = (e) => {
    setSelectedParticleSize(e.target.value);
    const selectedPartNumberVariants = product.variants.filter(
      (variant) =>
        variant.attributes['supplier-part-number'] === selectedPartNumber &&
        variant.attributes['lengthxinner-diameter'] === selectedInnerDiameterSize &&
        variant.attributes['particle-size'] === e.target.value,
    );

    if (selectedPartNumberVariants.length > 0) {
      const selectedSKU = selectedPartNumberVariants[0].sku;
      router.push({ pathname: `/${product.productId}/p/${selectedSKU}` }, undefined, {
        scroll: false,
      });
    }
  };
  const handleSelectChange = (e) => {
    setSelectedSize(e.target.value);
    const selectedPartNumberVariants = product.variants.filter(
      (variant) =>
        variant.attributes['supplier-part-number'] === selectedPartNumber &&
        variant.attributes['packaging-size'] === e.target.value,
    );

    if (selectedPartNumberVariants.length > 0) {
      const selectedSKU = selectedPartNumberVariants[0].sku;
      router.push({ pathname: `/${product.productId}/p/${selectedSKU}` }, undefined, {
        scroll: false,
      });
    }

    setSelectedIndexSku(dosageVariants[e.target.selectedIndex]?.sku);
    setPackageValue(e.target.value);
    setWeightChanged((current) => current + 1);
    setPlpWeight(e.target.value);
  };
  const { data } = useQuery(LOAD_PDP_BREADCRUMB_DATA(product?.variants[0]?.sku));
  useEffect(() => {
    const allowedCategories = ['Biology'];
    const categoryLabcolum = 'LabColumns';
    const ancestors = data?.product?.masterData?.current?.categories.map((category) => category.ancestors);
    const flattenedAncestors = ancestors?.flat();
    const ancestorSlugs = flattenedAncestors?.map((ancestor) => ancestor.slug);
    console.log(data);
    const hasMatchingAncestors =
      ancestorSlugs !== null && ancestorSlugs?.some((slug) => allowedCategories.includes(slug));
    const hasMatchingMroAncestors = data?.product?.masterData?.current?.categories[0].slug;
    if (hasMatchingAncestors) {
      setChemicalProduct(true);
    }
    if (hasMatchingMroAncestors === categoryLabcolum) {
      setMroProduct(true);
    }
  }, [chemicalProduct, data]);

  return (
    <div>
      {mercLoader && <Loader />}
      <div className="pdp_details lg:ml-[36px]">
        <div>
          <div className="pdp_casNo casNoPdp">{variant?.attributes?.['cas-no']}</div>
        </div>
        <p className="pdp_Name tracking-[0.01em] lg:mt-1.5">{product?.name}</p>
        <div className="pdp_Category text-[16px] tracking-[1px] lg:mt-2">
          <PDPCategory productSKU={variant.sku} productName={product.name} />
        </div>

        <p className="pdp_product_description mt-[10px] lg:mt-3">{product.description}</p>
        <div className="flex justify-start">
          <ProductCertifications starRating={starRating} />
          <div className="product_rating flex">
            {review?.length > 0 ? (
              starRating
            ) : (
              <p className="first_review flex" onClick={handleAddReview}>
                Be The first one to review
              </p>
            )}
            {showReviewModal && (
              <AddReview
                setOpenModal={setShowReviewModal}
                sellerName={specifications?.['vendor-name']}
                productId={product?.id}
                handleReviewCallBack={handleReviewCallBack}
                vendorCode={specifications?.['vendor-code']}
              />
            )}
          </div>

          <div></div>
        </div>

        {variant?.attributes?.['product-certificate'] && (
          <div className="pdp_tags mt-[20px] flex justify-start">
            <p className="pdp_product_Tag ml-1">{variant?.attributes?.['product-certificate']}</p>
          </div>
        )}
        <div className="show-for-big">
          <div className="mt-[30px]">
            <div className="pdp_brand">
              {variant?.attributes?.grade ? (
                <>
                  <p className="headingLeft tracking-[1px]">Grade</p>
                  <p className="name tracking-[1px]">{variant?.attributes?.grade}</p>
                </>
              ) : null}
            </div>
            <div className="pdp_brand">
              {variant?.attributes?.purity ? (
                <>
                  <p className="headingLeft tracking-[1px]">Purity</p>
                  <p className="name tracking-[1px]">{variant?.attributes?.purity}</p>
                </>
              ) : null}
            </div>
            <div className="pdp_brand">
              {variant?.attributes?.['molecular-formula'] ? (
                <>
                  <p className="headingLeft tracking-[1px]">Molecular Formula</p>
                  <p className="name tracking-[1px]">{variant?.attributes?.['molecular-formula']}</p>
                </>
              ) : null}
            </div>
            <div className="pdp_brand">
              {variant?.attributes?.['chemical-name'] ? (
                <>
                  <p className="headingLeft tracking-[1px]">Chemical Formula</p>
                  <p className="name tracking-[1px]">{variant?.attributes?.['chemical-name']}</p>
                </>
              ) : null}
            </div>
            <div className="pdp_brand">
              {variant?.attributes?.['shelf-life'] && variant?.attributes?.['shelf-life'] !== 'NA' ? (
                <>
                  <p className="headingLeft tracking-[1px]">Shelf Life</p>
                  <p className="name tracking-[1px]">{variant?.attributes?.['shelf-life']}</p>
                </>
              ) : null}
            </div>
            <div className="pdp_brand">
              {/* {variant?.attributes?.['lengthxinner-diameter'] ? (
                <>
                  <p className="headingLeft tracking-[1px]">Inner diameter</p>
                  <p className="name tracking-[1px]">{variant?.attributes?.['lengthxinner-diameter']}</p>
                </>
              ) : null} */}
            </div>
            <div className="pdp_brand">
              {/* {variant?.attributes?.['particle-size'] ? (
                <>
                  <p className="headingLeft tracking-[1px]">Particle Size</p>
                  <p className="name tracking-[1px]">{variant?.attributes?.['particle-size']}</p>
                </>
              ) : null} */}
            </div>
            <div className="pdp_brand">
              {variant?.attributes?.['pore-size'] ? (
                <>
                  <p className="headingLeft tracking-[1px]">Pore Size</p>
                  <p className="name tracking-[1px]">{variant?.attributes?.['pore-size']}</p>
                </>
              ) : null}
            </div>
            <div className="pdp_brand">
              {variant?.attributes?.['country-of-origin'] ? (
                <>
                  <p className="headingLeft tracking-[1px]">Country of Origin</p>
                  <p className="name tracking-[1px]">
                    {/* {variant?.attributes?.['country-of-origin']?.label} */}
                    {variant?.attributes?.['country-of-origin']?.label
                      ? variant?.attributes?.['country-of-origin'].label
                      : 'INDIA'}
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="show-for-mobile mt-[30px]">
          {variant?.attributes?.purity ? (
            <p>
              Purity: <span>{variant?.attributes?.purity}</span>
            </p>
          ) : null}

          <p>
            MoleCular Formula: <span>{variant?.attributes?.['molecular-formula']}</span>
          </p>
          {variant?.attributes?.['chemical-name'] ? (
            <p>
              Chemical Formula: <span>{variant?.attributes?.['chemical-name']}</span>
            </p>
          ) : null}
          {variant?.attributes?.['shelf-life'] || variant?.attributes?.['shelf-life'] === 'NA' ? (
            <p>
              Shelf Life: <span>{variant?.attributes?.['shelf-life']}</span>
            </p>
          ) : null}

          <p>
            Country of Origin:{' '}
            <span>
              {variant?.attributes?.['country-of-origin']?.label
                ? variant?.attributes?.['country-of-origin'].label
                : 'INDIA'}
            </span>
          </p>
        </div>

        <div className="pdp_filters pdp-chev-change pdp_filter_des flex-col  sm:mt-[5px] lg:-ml-[5px] lg:mt-[5px] lg:w-auto 2xl:flex-row">
          {variant?.attributes?.dosage && (
            <div>
              <select
                id="selected-tab"
                name="selected-tab"
                className="pdp_select-tab pdp_search_filter w-[50%] 2xl:w-auto"
                onChange={(e) => {
                  setProductDosage(e.target.value);
                }}
              >
                {variant?.attributes?.dosage &&
                  dosages.map((item, index) => (
                    <option className="values" key={index} value={item} onClick={() => setProductDosage(item)}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
          )}
          {/* {/* new code for mro three drop Dropdown */}
          {mroProduct ? (
            <>
              <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                <select
                  id="selected-tab"
                  name="selected-partnumber"
                  className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                  onChange={(e) => handlePartNumberForMro(e)}
                >
                  <option value="">Select part no</option>

                  {partNumbers
                    ? partNumbers.map((partNumber, index) => (
                        <option key={index} value={partNumber}>
                          {partNumber}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                <select
                  id="selected-tab"
                  name="selected-packaingSie"
                  className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                  value={selectedInnerDiameterSize}
                  onChange={(e) => handleInnerDiameterChange(e)}
                >
                  <option value="">Select inner diam</option>

                  {innerDiameterArray
                    ? innerDiameterArray.map((size, index) => (
                        <option key={index} value={size}>
                          {size}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                <select
                  id="selected-tab"
                  name="selected-packaingSie"
                  className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                  value={selectedParticleSize}
                  onChange={(e) => handleParticleSizeChange(e)}
                >
                  <option value="">Select particle size</option>

                  {particleSizeArray
                    ? particleSizeArray.map((size, index) => (
                        <option key={index} value={size}>
                          {size}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </>
          ) : chemicalProduct ? (
            <>
              <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                <select
                  id="selected-tab"
                  name="selected-partnumber"
                  className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                  onChange={(e) => handlePartNumber(e)}
                >
                  <option value="">Select part no</option>
                  {partNumbers
                    ? partNumbers.map((partNumber, index) => (
                        <option key={index} value={partNumber}>
                          {partNumber}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="pdp-tile-weight flex" id="scroll-to-product-description">
                <select
                  id="selected-tab"
                  name="selected-packaingSie"
                  className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                  value={selectedSize}
                  onChange={handleSelectChange}
                >
                  <option value="">Select pack size</option>
                  {packagingSizeArray
                    ? packagingSizeArray.map((size, index) => (
                        <option key={index} value={size}>
                          {size}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </>
          ) : (
            <div className="pdp-tile-weight flex" id="scroll-to-product-description">
              <select
                id="selected-tab"
                name="selected-tab"
                className="pdp_search_filter  cursor-pointer 2xl:w-auto"
                onChange={(e) => handleProductWeightChange(e)}
                //value={plpWeight}
                value={packageValue}
              >
                {dosageVariants.map((item, index) => (
                  <option
                    className="values text-left"
                    key={index}
                    value={item?.attributes['packaging-size']}
                    onChange={() => {}}
                  >
                    {item?.attributes['packaging-size']}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="qty qty-width">
            <div className="grms-qty grms-qty-pdp mt-[14px] cursor-pointer bg-[white]">
              <p className="qty-p text-center">Quantity:</p>
              <button
                className="qtyminus bg-[white] "
                aria-hidden="true"
                onClick={() => productQuantity > minimumQuantity && setProductQuantity(productQuantity - 1)}
              >
                &minus;
              </button>
              <input
                className="plp-weight pdp-weight"
                type="text"
                name="qty"
                id="qty"
                step="1"
                value={productQuantity || 0}
                onChange={(e) => handleQuantityChange(e.target.value)}
                // onChange={() => {}}
              />
              <button
                className="qtyplus bg-[white]"
                aria-hidden="true"
                onClick={() => setProductQuantity(productQuantity + 1)}
              >
                +
              </button>
            </div>
          </div>
        </div>
        {/* new field added for EDD and RDD */}
        <div className="flex">
          <div className="mt-2 flex-1 pr-2">
            <p className="headingLeft datepicker-container mt-1 mb-2 tracking-[1px]">
              RDD
              <span className="ml-8 font-semibold">{rdd}</span>
              <DatePicker
                showIcon
                selected={startDate}
                onChange={handleDateChange}
                onBlur={validateDate}
                minDate={subDays(new Date(), -1)}
                placeholderText="(DD.MM.YYYY)"
                dateFormat="dd.MM.yyyy"
                inline={false}
                className={`datepicker border-2 border-blue-200 ${!isValidDate ? 'datepicker-invalid' : ''}`}
                style={{ border: '2px solid blue' }}
              />
            </p>
          </div>
          {expectedDateOfDelivery ? (
            <div className="w-1/2 flex-1">
              <p className="headingLeft mt-2 tracking-[1px]">
                EDD
                <span className="font-semibold">
                  <input type="text" value={expectedDateOfDelivery} className="border-2 border-blue-200" />
                </span>
              </p>
            </div>
          ) : null}
        </div>

        {/* new custom field added */}
        <div
          className={
            expectedDateOfDelivery ? 'tile changecat col-span-full mt-4' : 'tile changecat col-span-full mt-12'
          }
        >
          <p className="headingLeft tracking-[1px]">Notes:</p>
          <div className="relative mt-1">
            <textarea
              required
              name="description"
              id="Description"
              placeholder="Enter a Description"
              autoComplete="given-Description"
              className="review_description_box quote_description_box mt-[6px]  border-2 border-blue-200 text-[12px]"
              onChange={handleNotes}
            />
          </div>
        </div>
        {/* product Price */}
        {qtyError && <p className="request-quote-error">{qtyError}</p>}
        <div className="mt-7 flex flex-col 2xl:flex-row">
          <div className="flex items-center">
            {mercVendor ? (
              mercQuantity >= 1 ? (
                <>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[4px]"
                  >
                    <path
                      d="M5.80188 10.905L2.30188 7.40499C1.91188 7.01499 1.29188 7.01499 0.901875 7.40499C0.511875 7.79499 0.511875 8.41499 0.901875 8.80499L5.09188 12.995C5.48187 13.385 6.11188 13.385 6.50187 12.995L17.1019 2.40499C17.4919 2.01499 17.4919 1.39499 17.1019 1.00499C16.7119 0.614994 16.0919 0.614994 15.7019 1.00499L5.80188 10.905Z"
                      fill="#30AB30"
                    />
                  </svg>
                  <p className="ml-[6px] text-[16px] font-bold text-[#30AB30]">In Stock</p>
                </>
              ) : (
                <>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[4px]"
                  >
                    <path
                      d="M5.80188 10.905L2.30188 7.40499C1.91188 7.01499 1.29188 7.01499 0.901875 7.40499C0.511875 7.79499 0.511875 8.41499 0.901875 8.80499L5.09188 12.995C5.48187 13.385 6.11188 13.385 6.50187 12.995L17.1019 2.40499C17.4919 2.01499 17.4919 1.39499 17.1019 1.00499C16.7119 0.614994 16.0919 0.614994 15.7019 1.00499L5.80188 10.905Z"
                      fill="#C70039"
                    />
                  </svg>
                  <p className="ml-[6px] text-[16px] font-bold text-[#C70039]">Out of Stock</p>
                </>
              )
            ) : null}
          </div>
          <div className="product_details mt-[12px] flex lg:mt-[0px]">
            {mercVendor ? (
              <div className="price-container">
                <p className="new-price">
                  ₹
                  {latestPrice > 0 && latestPrice !== undefined && latestPrice !== oldPrice && mercQuantity > 0
                    ? mercUpdatedPrice == 0
                      ? latestPrice
                      : mercUpdatedPrice
                    : mercUpdatedPrice == 0
                    ? latestPrice
                    : mercUpdatedPrice}
                </p>
                <p className="original-price">
                  {latestPrice !== oldPrice ? (
                    mercUpdatedOldprice == 0 ? (
                      <> ₹{oldPrice}</>
                    ) : (
                      <>₹{mercUpdatedOldprice}</>
                    )
                  ) : null}
                </p>
              </div>
            ) : product?.price?.centAmount != 0 ? (
              <div className="ml-[10px] text-[28px] text-[#303030] sm:font-bold lg:ml-[10px] lg:font-bold 2xl:ml-[10px]">
                {CurrencyHelpers.formatForCurrency(product?.price)}/unit
              </div>
            ) : (
              <button className="first_review flex lg:mt-[8px]" onClick={requestForQuote}>
                Ask for price
              </button>
            )}
            {mercVendor ? (
              mercVendor && mercQuantity >= 1 ? (
                oldPrice !== latestPrice ? (
                  <div className="mt-6">
                    <>
                      <p className={oldPrice > latestPrice ? 'price-decrease' : 'price-increase'}>
                        {oldPrice > latestPrice ? (
                          <span className="arrow down"></span>
                        ) : (
                          <span className="arrow up"></span>
                        )}{' '}
                        {oldPrice > latestPrice ? 'Price decrease' : 'Price increase'} by {percentageChange} %
                      </p>
                    </>
                  </div>
                ) : null
              ) : null
            ) : null}
          </div>
        </div>
        <hr className="pdp-hr" />
      </div>
    </div>
  );
}
