import React, { useState } from 'react';
function QuoteProductDetail({ product }) {
  const quoteVariants = product?.variant?.attributes;
  const quoteImage = product?.variant?.images[0]?.url;
  const casNo = quoteVariants?.find((attr) => attr.name === 'cas-no').value['en-IN'];

  const attributeNames = [
    {
      key: 'purity',
      value: 'Purity',
    },
    {
      key: 'packaging-size',
      value: 'Packaging Size',
    },
    {
      key: 'dosage',
      value: 'Dosage',
    },
    {
      key: 'country-of-origin',
      value: 'Country of Origin',
    },
  ];

  return (
    <div className="quote-product-details-section">
      <div className="quote-product-details-img">
        <img src={quoteImage} />
        <div className="quote-verified ml-1 mr-[20px] lg:mr-[20px]">
          <svg
            width="16"
            height="21"
            className="lg:mr-[13px]"
            viewBox="0 0 16 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.9 14.1L12.6 8.39998L11.55 7.37498L6.975 11.95L4.45 9.42498L3.35 10.525L6.9 14.1ZM8 20.975C5.66667 20.3916 3.75 19.0375 2.25 16.9125C0.75 14.7875 0 12.4583 0 9.92498V3.97498L8 0.974976L16 3.97498V9.92498C16 12.4583 15.25 14.7875 13.75 16.9125C12.25 19.0375 10.3333 20.3916 8 20.975Z"
              fill="#32C902"
            />
          </svg>
          <p className="ml-1 mt-0.5 text-xs font-medium text-[#000000]">Verified</p>
        </div>
      </div>
      <div className="quote-product-details-others">
        <p className="quote-item-casno">{casNo}</p>
        <p className="">
          <span className="quote-item-name">{product?.name['en-IN']}</span>
        </p>
        <div className="quote-attributes">
          {quoteVariants?.map((attributes, i) => {
            const found = attributeNames.find((attr) => attr.key === attributes.name);
            return (
              found && (
                <div className="quote-product-attrs" key={i}>
                  <div className="quote-product-attrs-key">{found.value}</div>
                  <div className="quote-product-attrs-value">
                    {attributes.value['en-IN'] || attributes.value.label || attributes.value}
                  </div>
                  <div className="quote-product-attrs-docs">&nbsp;</div>
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default QuoteProductDetail;
