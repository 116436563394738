// Doc Examples
//import ContentfulBlog from './doc-examples/contentful/blog';

import AccountDetails from './account/details';
import Address from './address';
import Members_List from 'components/commercetools-ui/members/members-list';
import Company_Registration_PopUp from 'components/commercetools-ui/company-registration-popup/company-registration-popup';
import AccountLogin from './account/login';
import AccountOrdersHistory from './account/orders';
import AccountRegister from './account/register';
import ResetPassword from './account/reset-password';
import AccountTest from './account/test';
import Cart from './cart';
import Checkout from './checkout';
import PreviewOrderTastic from './checkout/preview-order';
import ThankYou from './checkout/thank-you';
import CategoryTeasers from './content/category-teasers';
import Markdown from './content/markdown';
import Spacer from './content/spacer';
import Tile from './content/tile';
import BlogTastic from './contentful-blog/blog-detail';
import ContentfulBlogTastic from './contentful-blog/blog-list';
// import MostPopularSlider from './mostPopularSlider/'
import ContentAbout from './contentful/about';
// import VendorDetailsBanner from './contentful/banner-vendor-details';
import BlogCarousel from './contentful/carousel';
import ContentContactUs from './contentful/contact-us';
import ContentFAQ from './contentful/faq';
import Blog from './contentful/homepage';
import ContentBuyerSeller from './contentful/homepage/buyer-seller';
import ContentGalleryTestimonials from './contentful/homepage/gallery-testimonials';
import ContentLoginBanner from './contentful/homepage/login-banner';
import ContentAllverz from './contentful/homepage/why-allverz';
import AllverzWorks from './contentful/how-allverz-works';
import ContentLoginSlider from './contentful/login-page';
import ContentBottomBanner from './contentful/plp/bottom-banner';
import ContentPLP from './contentful/plp/top-banner';
import ContentPrivacy from './contentful/privacy-policy';
import ContentRegistrationSlider from './contentful/registration-page';
import ContentResetPasswordSlider from './contentful/reset-password';
import ContentTerms from './contentful/term-of-use';
// import VendorReturnPolicy from './contentful/vendor-policy';
// import VendorRequestQuoteBanner from './contentful/vendor-request-quote';
import HelloWorld from './doc-examples/hello-world';
import SimpleButton from './doc-examples/simple-button';
import StarWarsCharacterFilter from './doc-examples/star-wars/character-filter';
import StarWarsCharacterSearch from './doc-examples/star-wars/character-search';
import StarWarsOpeningCrawl from './doc-examples/star-wars/movies';
import Footer from './footer';
import Header from './header';
import Hero from './hero';
// import ComingSoonProducts from './l1-products';
import MostPopularSlider from './mostPopularSlider';
import NewsCardTastic from './news-card';
import Newsletter from './newsletter';
import NotFound from './not-found';
import OrderListTastic from './orders/order-list';
import SampleOrderList from './orders/sample-order-list';
import PaymentTastic from './payment';
import ProductDetails from './products/details';
import ProductList from './products/product-list';
import SimilarProducts from './products/similar-products';
import ProductSlider from './products/slider';
import QuotesDetail from './quotes-detail';
import QuotesList from './quotes/quotes-list';
import RecentlyViewedSlider from './recentlyViewedSlider';
import RequestForQuote from './request-for-quote';
import Showcase from './showcase';
import QuotesSidebar from './sidebar';
import topBannerTastic from './top-header';
// import VendorList from './vendor/vendor-list';
// import SellerInformation from './vendor-details/seller-info';
// import VendorReviews from './vendor-details/vendor-reviews';
import VendorList from './vendor-list';
import Wishlist from './wishlist';
//import VendorDetails from './vendor-details';
import VendorDetailsBanner from './contentful/banner-vendor-details';
import VendorReviews from './vendor-details/vendor-reviews';
import SellerInformation from './vendor-details/seller-info';
import VendorRequestQuoteBanner from './contentful/vendor-request-quote';
import VendorReturnPolicy from './contentful/vendor-policy';
import ComingSoonProducts from './l1-products';
//import VendorDetails from './vendor-details';
import AutoLogin from 'components/commercetools-ui/account/login/autoLogin';
import AdminDashboard from './AdminDashboard';
import CompanyInfo from './companyInfo';
import BuyerRegistration from './Buyer-Registration';
import ContentBuyerRegistrationSlider from './contentful/buyer-registration';

export const tastics = {
  // Doc Examples
  'example/simple-button': SimpleButton,
  'example/hello-world': HelloWorld,
  'example/star-wars/movie': StarWarsOpeningCrawl,
  'example/star-wars/character-search': StarWarsCharacterSearch,
  'example/star-wars/character-filter': StarWarsCharacterFilter,
  //'contentful/blog': ContentfulBlog,
  'contentful/home': Blog,
  'contentful/carousel': BlogCarousel,
  'contentful/allverz': ContentAllverz,
  'contentful/buyer-seller': ContentBuyerSeller,
  'contentful/gallery-testimonials': ContentGalleryTestimonials,
  'contentful/login-banner': ContentLoginBanner,
  'contentful/top-banner': ContentPLP,
  'contentful/bottom-banner': ContentBottomBanner,
  'contentful/about': ContentAbout,
  'contentful/contact-us': ContentContactUs,
  'contentful/faq': ContentFAQ,
  'contentful/terms': ContentTerms,
  'contentful/privacy': ContentPrivacy,
  'contentful/login-page': ContentLoginSlider,
  'contentful/registration': ContentRegistrationSlider,
  'contentful/resetpassword': ContentResetPasswordSlider,
  'commercetools/ui/checkout': Checkout,
  'commercetools/ui/thank-you': ThankYou,
  'commercetools/ui/cart': Cart,
  'commercetools/ui/footer': Footer,
  'commercetools/ui/header': Header,
  'commercetools/ui/content/tile': Tile,
  'commercetools/ui/content/spacer': Spacer,
  'commercetools/ui/content/showcase': Showcase,
  'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/content/newsletter': Newsletter,
  'commercetools/ui/products/details': ProductDetails,
  'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/products/slider': ProductSlider,
  'commercetools/ui/products/similar-products': SimilarProducts,
  'commercetools/ui/wishlist': Wishlist,
  'commercetools/ui/account/test': AccountTest,
  'commercetools/ui/account/details': AccountDetails,
  'commercetools/ui/account/login': AccountLogin,
  'commercetools/ui/account/register': AccountRegister,
  'commercetools/ui/account/orders': AccountOrdersHistory,
  'commercetools/ui/account/reset-password': ResetPassword,
  'commercetools/ui/content/contentful/blog': ContentfulBlogTastic,
  'commercetools/ui/content/category-teasers': CategoryTeasers,
  'commercetools/ui/content/news-card': NewsCardTastic,
  'commercetools/ui/hero': Hero,
  'contentful/blogs': ContentfulBlogTastic,
  'contentful/blog': BlogTastic,
  'top-header': topBannerTastic,
  'commercetools/ui/how-allverz-work': AllverzWorks,
  'quotes/quotes-detail': QuotesDetail,
  mostPopularSlider: MostPopularSlider,
  recentlyViewedProducts: RecentlyViewedSlider,
  // searchPLP: AlgoliaSearchList,
  // 'mostPopularSlider' : MostPopularSlider,
  'quotes/qoutes-component': RequestForQuote,
  'quotes/qoutes-list-component': QuotesList,
  'components/sidebar-component': QuotesSidebar,
  'checkout/payment-component': PaymentTastic,
  'orders/order-list-component': OrderListTastic,
  'orders/sample-order-list-component': SampleOrderList,
  'preview-order': PreviewOrderTastic,
  'vendor/vendor-list-component': VendorList,
  // 'vendor-details':VendorDetails,
  'vendor-details': SellerInformation,
  'contentful/vendor-banner': VendorDetailsBanner,
  'vendor-reviews': VendorReviews,
  'contentful/request-quote': VendorRequestQuoteBanner,
  'contentful/vendor-refund-policy': VendorReturnPolicy,
  'l1-products': ComingSoonProducts,
  address: Address,
  'members/members-list': Members_List,
  // 'vendor/vendor-list-component': VendorList,
  // 'vendor-details':VendorDetails,
  'admin-dashboard': AdminDashboard,
  'company-information': CompanyInfo,
  'buyer-registration': BuyerRegistration,
  'contentful/buyer-registration': ContentBuyerRegistrationSlider,
  'login/punchout': AutoLogin,
  default: NotFound,
};
