import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import Checkbox from './checkbox';
import { useFormat } from 'helpers/hooks/useFormat';
import MobileMembersList from './mobile-member-list';
import axios from 'axios';
import { useAccount } from 'frontastic';
import InviteMemberPopup from './inviteMemberPopup';
import DeclineMemberPopup from './declineMemberPopup';

import { BACKEND_BASE_URL } from 'helpers/constants/envVariables';

const Members_List = () => {
  const url_string = window.location.href;
  const url = new URL(url_string);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  const { formatMessage } = useFormat({ name: 'common' });
  const [selectedIdForStatusUpdate, setSelectedIdForStatusUpdate] = useState(null);
  const [memberDetails, setMemberDetails] = useState<any>();

  const customerAccessToken = url.searchParams.get('session_id');

  const { account, update } = useAccount();

  const handleSelectAll = () => {
    if (isCheckAll) {
      setIsCheck([]);
      setSelectedIdForStatusUpdate(null);
    } else {
    }
    setIsCheckAll(!isCheckAll);
  };

  const getCompanyDetails = async () => {
    const memberList = await axios.get(`${BACKEND_BASE_URL}/customer/companyMembers?id=${account?.customerGroup?.id}`, {
      headers: {
        Authorization: `Bearer ${customerAccessToken}`,
      },
    });
    setMemberDetails(memberList?.data);
    console.log('memberList', memberList);
  };
  const memberEmails = [];
  memberDetails?.map((item) => {
    memberEmails?.push(item.email);
  });

  console.log(memberEmails);

  useEffect(() => {
    if (account?.customerGroup?.id) getCompanyDetails();
  }, [account]);

  const handleSelect = (id) => {
    if (selectedIdForStatusUpdate == id) setSelectedIdForStatusUpdate(null);
    else setSelectedIdForStatusUpdate(id);
  };

  const handleApproveMember = async () => {
    await axios.post(`${BACKEND_BASE_URL}/customer/approve/${selectedIdForStatusUpdate}`, {
      headers: {
        Authorization: `Bearer ${customerAccessToken}`,
      },
    });

    getCompanyDetails();

    await axios.post(
      `${BACKEND_BASE_URL}/cart/quote/notifications`,
      {
        toEmail: memberDetails?.find((data) => data.id === selectedIdForStatusUpdate)?.email,
        subject: 'Your membership request has been approved',
        body: `Member approved from AVL`,
        toName: memberDetails?.find((data) => data.id === selectedIdForStatusUpdate)?.firstName,
        fromName: 'AVL',
      },
      {
        headers: {
          Authorization: `Bearer ${customerAccessToken}`,
        },
      },
    );
    setSelectedIdForStatusUpdate(null);
  };

  const handleDeclineMember = async () => {
    setShowDeclinePopup(true);
  };

  const handleMemberDeclineApiCall = async (desc) => {
    await axios.post(`${BACKEND_BASE_URL}/customer/decline/${selectedIdForStatusUpdate}`, {
      headers: {
        Authorization: `Bearer ${customerAccessToken}`,
      },
    });

    await axios.post(
      `${BACKEND_BASE_URL}/cart/quote/notifications`,
      {
        toEmail: 'devtestqademo@yopmail.com',
        subject: 'Declining customer request',
        body: `Member declined from AVL due to ${desc}`,
        toName: 'Allverz admin',
        fromName: 'AVL',
      },
      {
        headers: {
          Authorization: `Bearer ${customerAccessToken}`,
        },
      },
    );

    getCompanyDetails();
    setShowDeclinePopup(false);
  };

  return (
    <>
      {showInvitePopup && <InviteMemberPopup memberEmails={memberEmails} onClose={() => setShowInvitePopup(false)} />}
      {showDeclinePopup && (
        <DeclineMemberPopup onClose={() => setShowDeclinePopup(false)} handleDecline={handleMemberDeclineApiCall} />
      )}
      {window.innerWidth > 480 ? (
        <div className="member-main-div">
          <h1>
            <NextLink href="/">
              <a className="pdpBreadcrumbItem text-[#0280C7] hover:text-gray-500">
                <span className="mr-3 cursor-pointer font-medium capitalize hover:text-gray-600">Home Page</span>
              </a>
            </NextLink>
            <span className="quote-breadcrumb-seperator">/</span>
            <span className="quote-breadcrumb-part">Admin Account</span>
            <span className="quote-breadcrumb-seperator">/</span>
            <span className="quote-breadcrumb-part">Member List</span>
          </h1>
          <div className="request-for-quote-top-banner">
            <div>
              <div className="quote-title">Members List({memberDetails?.length - 1 || 0})</div>
              <p>List of the members associated with your organisation</p>
            </div>
          </div>
          <div className="allbuttons">
            {/* {!inEdit && ( */}
            <button
              className="addMember"
              onClick={() => {
                setShowInvitePopup(true);
              }}
            >
              {formatMessage({ id: 'add-member', defaultMessage: 'Add Member' })}
            </button>
            <button
              className="approveMember"
              disabled={!selectedIdForStatusUpdate}
              style={{ backgroundColor: !selectedIdForStatusUpdate ? 'gray' : '' }}
              onClick={handleApproveMember}
            >
              {formatMessage({ id: 'approve-member', defaultMessage: 'Approve Member' })}
            </button>
            <button
              className="declineMember"
              disabled={!selectedIdForStatusUpdate}
              style={{ backgroundColor: !selectedIdForStatusUpdate ? 'gray' : '' }}
              onClick={handleDeclineMember}
            >
              {formatMessage({ id: 'decline-member', defaultMessage: 'Decline Member' })}
            </button>
            {/* )} */}
          </div>
          {/* <div className="search-member">
            <svg
              onClick={() => ''}
              className="mr-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#003769"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.0004 21L16.6504 16.65"
                stroke="#003769"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              className="search-member-field"
              type="text"
              id="message"
              name="message"
              placeholder="Search by Order and Reference no."
            />

            <button className="btn-search">Search</button>
          </div> */}
          <div className="member_table">
            <div className="tableContainer">
              <table className="main_table">
                <thead className="tablehead-order-list">
                  <tr>
                    <th className="table_head">
                      <div>
                        {' '}
                        <label>
                          {
                            <Checkbox
                              type="checkbox"
                              name="selectAll"
                              id="selectAll"
                              handleClick={handleSelectAll}
                              isChecked={isCheckAll}
                            />
                          }
                        </label>
                      </div>
                    </th>
                    <th className="table_head">First Name</th>
                    <th className="table_head">Last Name</th>
                    <th className="table_head">Email</th>
                    <th className="table_head">Location</th>
                    <th className="table_head">Role</th>
                    <th className="table_head">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {memberDetails
                    ?.filter((data) => data.role !== 'Admin')
                    ?.map((elements, index) => (
                      <tr key={index}>
                        <td>
                          <label>
                            {
                              <Checkbox
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                handleClick={() => {
                                  handleSelect(elements.id);
                                }}
                                isChecked={isCheckAll || selectedIdForStatusUpdate == elements.id}
                              />
                            }
                          </label>
                        </td>
                        <td>{elements.firstName}</td>
                        <td>{elements.lastName}</td>
                        <td>{elements.email}</td>
                        <td>{elements?.address?.city}</td>
                        <td>{elements.role}</td>
                        <td>{elements.status}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div
              className="member-list-pagination"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '150px' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="orderPerPage">Members per page:</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <select
                    className="selectPerPageDropDown "
                    //   onChange={handlePerPageSelect}
                  >
                    <option value={12}>12</option>
                    <option value={24}>24</option>
                    <option value={36}>36</option>
                    <option value={48}>48</option>
                  </select>
                </div>
              </div>
              {/* <div>{Pagination()}</div> */}
              {/* <div>{totalProducts > 12 ? Pagination() : null}</div> */}
            </div>
          </div>
        </div>
      ) : (
        <MobileMembersList
          memberDetails={memberDetails}
          handleSelect={handleSelect}
          selectedIdForStatusUpdate={selectedIdForStatusUpdate}
          handleAddMember={() => setShowInvitePopup(true)}
          handleApproveMember={handleApproveMember}
          handleDeclineMember={handleDeclineMember}
        />
      )}
    </>
  );
};

export default Members_List;
