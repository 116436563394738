import { useState, useEffect, useCallback } from 'react';
import Link from 'next/link';
import { BLOGS_PAGE_VIEWED } from 'helpers/constants/events';

function Blogs({ blogs }) {
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermMobile, setSearchTermMobile] = useState('');

  const handleBlogsAnalytics = useCallback(async () => {
    gtag('event', BLOGS_PAGE_VIEWED, {});
  }, []);
  useEffect(() => {
    handleBlogsAnalytics();
  }, [handleBlogsAnalytics]);

  const handleSearchTerm = (searchTerm) => {
    if (searchTerm) {
      dateFilterBlogs(
        blogs.content.filter((val) =>
          val?.data?.target?.fields?.title.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      dateFilterBlogs(blogs?.content);
    }
  };

  const dateFilterBlogs = (blogs) => {
    setFilteredBlogs(
      blogs.sort(
        (a, b) =>
          new Date(b?.data?.target?.fields?.publishDate).valueOf() -
          new Date(a?.data?.target?.fields?.publishDate).valueOf(),
      ),
    );
  };

  const handleSearch = (btnIdentify) => {
    handleSearchTerm(btnIdentify === 'desktop' ? searchTerm : searchTermMobile);
  };

  // const handleRead = (blog) => {
  //   setSelectedBlog(blog);
  // };

  useEffect(() => {
    blogs?.content && blogs?.content?.length > 0 && dateFilterBlogs(blogs.content);
  }, [blogs]);

  return (
    <>
      <div className="container mx-auto px-5 md:px-0 lg:px-0 ">
        <div className="blog-body">
          <div className="card-breadcrumbs">
            <a href="/" style={{ color: '#0280C7' }}>
              Home Page
            </a>
            <a>/</a>
            <a>Blogs</a>
          </div>
          <div className="blog-header flex">
            <h2 className="heading">Blogs</h2>
            <div className="search-button flex gap-2">
              <svg
                className="blog_search_Icon mx-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="#003769"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.0004 21L16.6504 16.65"
                  stroke="#003769"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input
                onChange={(event) => setSearchTerm(event?.target?.value)}
                className="blog-search"
                type="Search"
                placeholder="Search"
              />
              <button onClick={() => handleSearch('desktop')} className="card-button">
                Search
              </button>
            </div>
          </div>
          <div className="search-button-mobile gap-2">
            <svg
              className="blog_search_Icon mx-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#003769"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.0004 21L16.6504 16.65"
                stroke="#003769"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              onChange={(event) => setSearchTermMobile(event?.target?.value)}
              className="blog-search col-xs-9"
              type="Search"
              placeholder="Search"
            />
            <button onClick={() => handleSearch('mobile')} className="card-button">
              Search
            </button>
          </div>
          {!!filteredBlogs &&
            filteredBlogs?.map(
              (value, index) =>
                value?.data?.target && (
                  <div className="col-sm-12 col-xs-12 col-md-4 col-lg-3 card" key={index}>
                    <div className="h-100 card-inner">
                      <div className="card-image-container">
                        <Link href={`/blogs/blog/${value?.data?.target.sys.id}`}>
                          <img
                            loading="lazy"
                            src={value?.data?.target?.fields?.featuredImage?.fields?.file?.url}
                            className="card-image"
                            style={{
                              borderTopLeftRadius: '0.8vw',
                              borderTopRightRadius: '0.8vw',
                              objectFit: 'cover',
                            }}
                            alt="pic"
                          />
                        </Link>
                      </div>
                      <div className="box blogs-card pb-1">
                        <p className="text">{value?.data?.target?.fields?.publishDate.split('T')[0]}</p>
                        <div className="header">
                          <Link href={`/blogs/blog/${value?.data?.target.sys.id}`}>
                            <a>{value?.data?.target?.fields?.title}</a>
                          </Link>
                        </div>
                        <p className="text card-entry-text">{value?.data?.target?.fields?.entryText}</p>
                        <br />
                        <div className="card-icon flex">
                          <div className="flex gap-2">
                            <svg
                              className="heartIconBlog"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M20.8382 4.60987C20.3274 4.09888 19.721 3.69352 19.0535 3.41696C18.3861 3.14039 17.6707 2.99805 16.9482 2.99805C16.2257 2.99805 15.5103 3.14039 14.8428 3.41696C14.1754 3.69352 13.5689 4.09888 13.0582 4.60987L11.9982 5.66987L10.9382 4.60987C9.90647 3.57818 8.5072 2.99858 7.04817 2.99858C5.58913 2.99858 4.18986 3.57818 3.15817 4.60987C2.12647 5.64156 1.54688 7.04084 1.54688 8.49987C1.54687 9.95891 2.12647 11.3582 3.15817 12.3899L4.21817 13.4499L11.9982 21.2299L19.7782 13.4499L20.8382 12.3899C21.3492 11.8791 21.7545 11.2727 22.0311 10.6052C22.3076 9.93777 22.45 9.22236 22.45 8.49987C22.45 7.77738 22.3076 7.06198 22.0311 6.39452C21.7545 5.72706 21.3492 5.12063 20.8382 4.60987V4.60987Z"
                                stroke="#0280C7"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                                stroke="#0280C7"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                                stroke="#0280C7"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                                stroke="#0280C7"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.58984 13.51L15.4198 17.49"
                                stroke="#0280C7"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.4098 6.51001L8.58984 10.49"
                                stroke="#0280C7"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <Link href={`/blogs/blog/${value?.data?.target.sys.id}`}>
                            <a className="card-button">Read</a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
            )}
        </div>
      </div>
    </>
  );
}

export default Blogs;
