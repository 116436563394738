import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAccount } from 'frontastic';
import { useRouter } from 'next/router';

import { BACKEND_BASE_URL } from 'helpers/constants/envVariables';

import { useFormat } from 'helpers/hooks/useFormat';
import {
  COMPANY_ADDRESS_ICON,
  BANK_NAME_ICON,
  COMPANY_DETAIL_NUMBER_ICON,
  COMPANY_NAME_ICON,
  COMPANY_ROLE_ICON,
  EMAIL_VERIFIED_ICON,
  MAIL_ICON,
  ORG_TYPE_ICON,
  PLUS_ICON,
  PWD_ICON,
  AC_NUMBER_ICON,
} from '../../../components/commercetools-ui/account/details/icons';
import ChangePassword from '../../../components/commercetools-ui/account/details/sections/security';
import axios from 'axios';
import AccountDropdown from '../../../components/commercetools-ui/account/details/account-dropdown';
import AddAccount from '../../../components/commercetools-ui/account/details/modals/addAccount/index';

const AdminDashboard = () => {
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);

  const url_string = window.location.href;
  const url = new URL(url_string);
  //sections in edit mode
  const [inEdit, setInEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<any>();
  const stopEdit = useCallback((index: number) => {}, [inEdit]);

  //next/router
  const router = useRouter();

  const customerAccessToken = url.searchParams.get('session_id');

  const { account, update } = useAccount();

  const [data, setData] = useState({
    firstName: account?.firstName ?? '',
    lastName: account?.lastName ?? '',
    work_email: account?.work_email ?? '',
    phone_number: account?.phone_number ?? '',
    role_type: account?.role_type ?? '',
    update_product_service: account?.update_product_service ?? true,
    locale: router.locale || router.defaultLocale,
    accountHolderName: account?.accountHolderName ?? '',
    accountNumber: account?.accountNumber ?? '',
    bankName: account?.bankName ?? '',
    ifscCode: account?.ifscCode ?? '',
    vatNumber: account?.vatNumber ?? '',
    canceledCheque: account?.canceledCheque ?? '',
  });

  useEffect(() => {
    const getCompanyDetails = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_BASE_URL}/customer/companyDetails?customerId=${account?.accountId}`,
          {
            headers: {
              Authorization: `Bearer ${customerAccessToken}`,
            },
          },
        );
        if (response.status === 200) {
          setCompanyDetails(response.data.value);
        } else {
          console.error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error('Axios request error:', error);
      }
    };

    if (account?.accountId) {
      getCompanyDetails();
    }
  }, [account]);

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const sections = useMemo(
    () => [
      {
        headline: 'Contact Details',
        subline: formatAccountMessage({
          id: 'profile.desc',
          defaultMessage: 'This information will be displayed publicly so be careful what you share.',
        }),
        fields: [
          {
            id: 'firstname',
            name: 'firstName',
            label: formatMessage({ id: 'firstName', defaultMessage: 'First Name' }),
            value: account?.firstName ?? '',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: true,
            icon: COMPANY_ROLE_ICON,
          },
          {
            id: 'lastname',
            name: 'lastName',
            label: formatMessage({ id: 'lastName', defaultMessage: 'Last Name' }),
            value: account?.lastName ?? '',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: true,
            icon: COMPANY_ROLE_ICON,
          },
          {
            id: 'phone_number',
            name: 'phone_number',
            label: formatMessage({ id: 'phone_number', defaultMessage: 'Phone No' }),
            value: account?.phone_number ?? '',
            type: 'number',
            options: [],
            defaultValue: '',
            required: true,
            editable: true,
          },
          {
            id: 'work_email',
            name: 'work_email',
            label: formatMessage({ id: 'work_email', defaultMessage: 'Work Email' }),
            value: account?.work_email ?? '',
            type: 'email',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: MAIL_ICON,
          },
          {
            id: 'role_type',
            name: 'role_type',
            label: formatMessage({ id: 'role_type', defaultMessage: 'You are' }),
            value: account?.role_type ?? '',
            type: 'text',
            options: ['Buyer', 'Seller', 'Both'],
            defaultValue: '',
            required: true,
            editable: false,
          },
          {
            id: 'password',
            name: 'password',
            label: formatMessage({ id: 'password', defaultMessage: 'Password' }),
            value: '**********',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: PWD_ICON,
          },
        ],
        onSubmit: async () => {
          await update({
            firstName: data.firstName,
            lastName: data.lastName,
            phone_number: data.phone_number,
            work_email: data.work_email,
            role_type: data.role_type,
            update_product_service: data.update_product_service,
          });
        },
      },
    ],
    [formatAccountMessage],
  );
  const nonEditablesections = useMemo(
    () => [
      {
        headline: 'Company Details',
        subline: formatAccountMessage({
          id: 'account.desc',
          defaultMessage: 'Manage how information is displayed on your account.',
        }),
        fields: [
          {
            id: 'GSTIN-number',
            name: 'GSTIN-number',
            label: formatMessage({ id: 'GSTIN-number', defaultMessage: 'GSTIN Number' }),
            value: companyDetails?.gstinNumber ?? 'N/A',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_DETAIL_NUMBER_ICON,
          },
          {
            id: 'organizational-type',
            name: 'organizational-type',
            label: formatMessage({ id: 'organizational-type', defaultMessage: 'Organizational Type' }),
            value: companyDetails?.organizationalType ?? 'N/A',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: ORG_TYPE_ICON,
          },
          {
            id: 'company-name',
            name: 'company-name',
            label: formatMessage({ id: 'company-name', defaultMessage: 'Company Name' }),
            value: companyDetails?.companyName ?? 'N/A',
            type: 'email',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_NAME_ICON,
          },
          {
            id: 'company-address',
            name: 'company-address',
            label: formatMessage({ id: 'company-address', defaultMessage: 'Company Address' }),
            value: companyDetails?.companyAddress.streetName ?? 'N/A',
            type: 'textarea',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_ADDRESS_ICON,
          },
          {
            id: 'role-in-company',
            name: 'role-in-company',
            label: formatMessage({ id: 'role-in-company', defaultMessage: 'Your role in your company' }),
            value: companyDetails?.roleInCompany ?? 'N/A',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_ROLE_ICON,
          },
          {
            id: 'tan-number',
            name: 'tan-number',
            label: formatMessage({ id: 'tan-number', defaultMessage: 'TAN Number' }),
            value: companyDetails?.tanNumber ?? 'N/A',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_DETAIL_NUMBER_ICON,
          },
          {
            id: 'pan-number',
            name: 'pan-number',
            label: formatMessage({ id: 'pan-number', defaultMessage: 'PAN Number' }),
            value: companyDetails?.panNumber ?? 'N/A',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_DETAIL_NUMBER_ICON,
          },
          {
            id: 'cin-llpin',
            name: 'cin-llpin',
            label: formatMessage({ id: 'cin-llpin', defaultMessage: 'CIN/LLPIN' }),
            value: companyDetails?.cinLlpin ?? 'N/A',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_DETAIL_NUMBER_ICON,
          },
          {
            id: 'export-code',
            name: 'export-code',
            label: formatMessage({ id: 'export-code', defaultMessage: 'IMPORT & EXPORT Code' }),
            value: companyDetails?.importExportCode ?? 'N/A',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_DETAIL_NUMBER_ICON,
          },
        ],
        // onSubmit: async () => {
        //   router.replace(router.asPath, undefined, { locale: data.locale });
        // },
      },
      {
        headline: 'Account Details',
        subline: formatAccountMessage({
          id: 'account.desc',
          defaultMessage: 'Manage how information is displayed on your account.',
        }),
        fields: [
          {
            id: 'accountHolderName',
            name: 'accountHolderName',
            label: formatMessage({ id: 'accountHolderName', defaultMessage: 'A/C Holder Name' }),
            value: account?.accountHolderName ?? 'N/A',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_ROLE_ICON,
          },
          {
            id: 'accountNumber',
            name: 'accountNumber',
            label: formatMessage({ id: 'accountNumber', defaultMessage: 'Bank AC Number' }),
            value: account?.accountNumber ?? 'N/A',
            type: 'text',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: AC_NUMBER_ICON,
          },
          {
            id: 'bankName',
            name: 'bankName',
            label: formatMessage({ id: 'bankName', defaultMessage: 'Bank Name' }),
            value: account?.bankName ?? 'N/A',
            type: 'email',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: BANK_NAME_ICON,
          },
          {
            id: 'ifscCode',
            name: 'ifscCode',
            label: formatMessage({ id: 'ifscCode', defaultMessage: 'IFSC Code' }),
            value: account?.ifscCode ?? 'N/A',
            type: 'email',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_DETAIL_NUMBER_ICON,
          },
          {
            id: 'vatNumber',
            name: 'vatNumber',
            label: formatMessage({ id: 'vatNumber', defaultMessage: 'VAT Number' }),
            value: account?.vatNumber ?? 'N/A',
            type: 'email',
            options: [],
            defaultValue: '',
            required: true,
            editable: false,
            icon: COMPANY_DETAIL_NUMBER_ICON,
          },
        ],
        onSubmit: async () => {
          router.replace(router.asPath, undefined, { locale: data.locale });
        },
      },
    ],
    [formatAccountMessage, account, companyDetails],
  );

  const handleSubmit = useCallback(async () => {
    sections[0].onSubmit();
    setInEdit(false);
  }, [inEdit, stopEdit, sections]);

  const handleChange = useCallback(
    (name: string, value) => {
      setData({ ...data, [name]: value });
    },
    [data],
  );

  const openNewAccountModal = () => {
    setCreateAccountModalOpen(true);
  };

  const closeNewAccountModal = async (fields) => {
    setCreateAccountModalOpen(false);
    await update({
      accountHolderName: fields?.accountHolderName || account.accountHolderName,
      accountNumber: fields?.accountNumber || account.accountNumber,
      bankName: fields?.bankName || account.bankName,
      ifscCode: fields?.ifscCode || account.ifscCode,
      vatNumber: fields?.vatNumber || account.vatNumber,
      canceledCheque: fields?.canceledCheque || account.canceledCheque,
    });
  };

  return (
    <div className="flex w-full flex-col" style={{ marginTop: '200px' }}>
      <div className="">
        {/* <div className="flex flex-col items-center gap-8 lg:mt-10 lg:flex-row ">
                <div className="account-profile-desktop">
                  <div className="account-img">
                    {account?.firstName ? account?.firstName[0] : ''}
                    {account?.lastName ? account?.lastName[0] : ''}
                  </div>
                </div>
                <table className="account-item-wrapper ">
                    <tr className="account-section-item mt-[10px] lg:mt-[0px]">
                      <td className="account-detail-label">
                        
                        <span className="ml-1 text-[#EB4747]">*</span>
                      </td>

                      <td className="account-detail-value">
                       
                          <>
                            
                              <ChangePassword showModal={()=>{}} setShowModal={()=>{}} />
                            
                          </>
                        
                      </td>
                    </tr>
                  
                </table>
              </div> */}

        {sections.map((section, index) => (
          <div className="account-wrapper" key={index} style={{ marginRight: '0px !important' }}>
            <div className="flex items-center justify-between">
              <div>
                <h2 className="account-title">Admin Account</h2>
                <p className="account-subtitle">Your Profile related information submitted at the time of signup</p>
              </div>
              <div className="hidden lg:block">
                {!inEdit && (
                  <button className="headerAccBtn !m-0 !p-0" onClick={() => setInEdit(true)}>
                    {formatMessage({ id: 'edit-profile', defaultMessage: 'Edit Profile' })}
                  </button>
                )}
              </div>
            </div>
            <div className="account-profile-mobile mt-[30px] flex flex-col items-center">
              <div className="account-img">
                {account?.firstName ? account?.firstName[0] : ''}
                {account?.lastName ? account?.lastName[0] : ''}
              </div>
              <p className="account-name">
                Hi<span> {account?.firstName}</span> <span>{account?.lastName}</span>
              </p>
            </div>
            <div className="account-section-wrapper">
              <div className="contact-details-heading flex justify-between">
                <p className="account-heading ">{!inEdit ? section.headline : 'Basic Details'}</p>
                <button className="account-edit-icon lg:hidden" onClick={() => setInEdit(true)}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.49896 14.375V17.5H5.62396L14.8406 8.28333L11.7156 5.15833L2.49896 14.375ZM4.9323 15.8333H4.16563V15.0667L11.7156 7.51667L12.4823 8.28333L4.9323 15.8333ZM17.2573 4.69167L15.3073 2.74167C15.1406 2.575 14.9323 2.5 14.7156 2.5C14.499 2.5 14.2906 2.58333 14.1323 2.74167L12.6073 4.26667L15.7323 7.39167L17.2573 5.86667C17.5823 5.54167 17.5823 5.01667 17.2573 4.69167Z"
                      fill="#0068A3"
                    />
                  </svg>
                </button>
              </div>
              {!inEdit ? (
                <div className="flex flex-col items-center gap-8 lg:mt-10 lg:flex-row ">
                  <div className="account-profile-desktop">
                    <div className="account-img">
                      {account?.firstName ? account?.firstName[0] : ''}
                      {account?.lastName ? account?.lastName[0] : ''}
                    </div>
                  </div>
                  <table className="account-item-wrapper ">
                    {section.fields.map((field, fieldIndex) => (
                      <tr className="account-section-item mt-[10px] lg:mt-[0px]" key={fieldIndex}>
                        <td className="account-detail-label">
                          {field.label}
                          <span className="ml-1 text-[#EB4747]">*</span>
                        </td>

                        <td className="account-detail-value">
                          {field.value}
                          {field.name === 'password' && (
                            <>
                              {!account?.email.includes('drreddys.com') && (
                                <ChangePassword showModal={showModal} setShowModal={setShowModal} />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              ) : (
                <div className="mt-2 grid grid-cols-1 gap-x-10 lg:grid-cols-2">
                  {section.fields.map((field, fieldIndex) => (
                    <label className="account-editable-fields relative" key={fieldIndex}>
                      <span>
                        {field.label}
                        {field.required && <span className="ml-1 text-[#EB4747]">*</span>}
                      </span>

                      {!(field.options.length > 0) ? (
                        <>
                          {field.name === 'password' ? (
                            <>
                              <span className="absolute">{field?.icon}</span>
                              <div className="flex items-center justify-between">
                                {field.value}
                                <span
                                  onClick={() => {
                                    setInEdit(false);
                                  }}
                                >
                                  {!account?.email.includes('drreddys.com') && (
                                    <ChangePassword setShowModal={setShowModal} showModal={showModal} />
                                  )}
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              {field.name === 'work_email' && (
                                <p className="account-email-verified">{EMAIL_VERIFIED_ICON}</p>
                              )}
                              <span className="absolute">{field?.icon}</span>
                              <input
                                id={field.id}
                                //   value={field.value}
                                type={field.type}
                                defaultValue={field.value}
                                required={field.required}
                                disabled={!field.editable}
                                onChange={(e) => {}}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <div className="flex gap-4">
                          {field.options.map((option, index) => (
                            <label key={index} className="flex items-center gap-1">
                              <input
                                name={field.name}
                                type="radio"
                                value={option}
                                className="payment-radio-button"
                                checked={true}
                                onChange={(e) => {}}
                              />
                              {option}
                            </label>
                          ))}
                        </div>
                      )}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {inEdit && (
        <div className="account-wrapper account-cancel-update mt-8 flex text-sm lg:ml-auto">
          <span className="pr-4">
            <button className="headerEnquirybtn account-cancel-btn !m-0 " onClick={() => setInEdit(false)}>
              {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
            </button>
          </span>
          <span className="pl-4">
            <button className="headerAccBtn account-update-btn !m-0" onClick={() => handleSubmit()}>
              {formatMessage({ id: 'update', defaultMessage: 'Update' })}
            </button>
          </span>
        </div>
      )}

      <div>
        {nonEditablesections.map((section, index) => (
          <div className="account-wrapper accountMain" key={index}>
            <AccountDropdown section={section}>
              <div>
                {/* <h2 className="account-heading">{section.headline}</h2> */}

                <div className="mt-2 grid grid-cols-1 gap-x-10 lg:grid-cols-2">
                  {section.fields.map((field, fieldIndex) => (
                    <label className="account-noneditable-fields relative" key={fieldIndex}>
                      <span className="notruncate">
                        {field.label}
                        {field.required && <span className="ml-1 text-[#EB4747]">*</span>}
                      </span>
                      {!(field.options.length > 0) ? (
                        <>
                          <span className="absolute">{field?.icon}</span>
                          <div className={field.type === 'textarea' && 'h-32 text-left'}>{field.value}</div>
                        </>
                      ) : (
                        <>
                          <span className="absolute">{field.icon}</span>
                          <select name={field.name} onChange={(e) => {}} disabled={!inEdit}>
                            {field.options.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                    </label>
                  ))}
                </div>
                {/* {inEdit && (
                  <div className="account-wrapper account-cancel-update mt-8 flex text-sm lg:ml-auto">
                    <span className="pr-4">
                      <button className="headerEnquirybtn account-cancel-btn !m-0" onClick={() => setInEdit(false)}>
                        {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                      </button>
                    </span>
                    <span className="pl-4">
                      <button className="headerAccBtn account-update-btn !m-0" onClick={() => handleSubmit()}>
                        {formatMessage({ id: 'update', defaultMessage: 'Update' })}
                      </button>
                    </span>
                  </div>
                )} */}
                {createAccountModalOpen && <AddAccount open={createAccountModalOpen} onClose={closeNewAccountModal} />}
                <div>
                  <div>
                    {/* <h2 className="account-heading">{section.headline}</h2> */}

                    {section.headline === 'Account Details' && (
                      <button className="account-add-btn" onClick={openNewAccountModal}>
                        {PLUS_ICON}
                        Edit/Update Bank Account
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </AccountDropdown>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
