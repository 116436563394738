import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import AlgoliaSearch from '../product-list/algolia-search/search-algolia';
import axios from 'axios';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_QUICK_RFQ, STATIC_QUICK_RFQ_NUM } from 'helpers/constants/statisURLs';
import { useCart } from 'frontastic';
const searchClient = algoliasearch(process.env.ALGOLIA_APPLICATION_ID, process.env.ALGOLIA_APPLICATION_KEY);

function QuoteDetails({ selectedProduct, valuesUpdated, isValid = true, isMobile = false, clearData = false }) {
  const [casNo, setCasNo] = useState('');
  const [productName, setProductName] = useState('');
  const [prodClick, setProdClick] = useState(true);
  const [prodDetail, setProdDetail] = useState('');
  const [rfqNumbkend, setRfqNumbkend] = useState('');
  const [rfqTitle, setRfqTitle] = useState('');
  const { data: cartList } = useCart();

  const casCallBack = (casNO, productsName, productDetail) => {
    if (productsName.length > 0) {
      setCasNo(casNO);
      setProductName(productsName);
      setProdClick(true);
      setProdDetail(productDetail);
      selectedProduct(productDetail);
    }
  };

  const reqBody = {
    cartId: cartList?.cartId,
    cartVersion: cartList?.cartVersion,
    description: '',
  };

  useEffect(() => {
    if (cartList?.cartId && cartList?.cartVersion) {
      requestNumHandler();
    }
  }, [cartList]);

  useEffect(() => {
    if (clearData) setCasNo('');
  }, [clearData]);

  const requestNumHandler = async () => {
    try {
      const response = await backendAPIClient.post(STATIC_QUICK_RFQ_NUM, reqBody);
      setRfqNumbkend(response?.data);
      valuesUpdated({ quoteNumber: response?.data });
    } catch (error) {}
  };

  const handleRfqTitle = (title) => {
    valuesUpdated({ RFQTitle: rfqTitle });
  };

  const handleProdCLick = () => {
    setProdClick(false);
  };

  return (
    <>
      <div>
        {!isMobile && <p className="quote-title-inner">Request for Quote Details</p>}
        <p
          style={{
            marginTop: '6px',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '20px',
            letterSpacing: '1px',
            color: '#303030',
          }}
        >
          Not able to find what you are looking for?{' '}
          <Link href={'contact-us'}>
            <span className="quote-click">
              <u>Click Here</u>
            </span>
          </Link>
        </p>
      </div>
      <div className="wrapper" style={{ marginTop: '10px' }}>
        <div className="rfqInput">
          <label className='rfq-text'>RFQ Number</label>
          <input className='rfq-input' style={{background: '#FAFAFA'}} type="text" placeholder="123456" required value={rfqNumbkend} readOnly />
        </div>
        <div className="rfqInput">
          <label className='rfq-text'>
            RFQ Title<span className="ml-[3px] text-[#EB4747]">*</span>
          </label>
          <input
            type="text"
            placeholder="Enter RFQ Title"
            onChange={(e) => setRfqTitle(e.target.value)}
            value={rfqTitle}
            onBlur={handleRfqTitle}
            required
          />
          <div className="quote-validation-block">
            {!isValid && <span className="quote-validation">RFQ Title required</span>}
          </div>
        </div>
        <div className="rfqInput">
          <label className='rfq-text'>
            Product Name <span className="ml-[3px] text-[#EB4747]">*</span>
          </label>
          {!clearData && productName.length > 0 && prodClick ? (
            <input
              type="text"
              placeholder="Enter Product Name"
              onClick={() => handleProdCLick()}
              value={productName}
              onBlur={() => setProdClick(true)}
              readOnly
            />
          ) : (
            <AlgoliaSearch
              placeHolder="Enter Product Name"
              isRfq={true}
              handleCasCallback={casCallBack}
              isFromQuote={true}
            />
          )}
        </div>
        <div className="rfqInput">
          <label className='rfq-text'>CAS Number</label>
          <input type="text" placeholder="123456" value={casNo} readOnly />
        </div>
      </div>
    </>
  );
}

export default QuoteDetails;
