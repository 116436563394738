/* eslint-disable prettier/prettier */
import React, {useState} from 'react';
import { LineItem } from '@Types/cart/LineItem';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { IoHeartOutline } from 'react-icons/io5';
import Price from 'components/commercetools-ui/price';
import Typography from 'components/commercetools-ui/typography';

interface Props {
  lineItem: LineItem;
 
}

const ItemPreview = ({ lineItem }: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });


  return (
    <div className="cart-item !h-48">
      <div className="cart-item-main-section">

        <div className="cart-item-image">
          <Image
            src={lineItem.variant.images[0]}
            alt={lineItem.name}
            className=""
          />
        </div>

        <div className="cart-item-data">
          {/* <div> */}
          <div className="cart-item-data-one">
            <p className="item-cas-no">{lineItem.variant.attributes['cas-no']}</p>
            <p className="item-name">
              {lineItem.name || ' '}
            </p>
              {lineItem.variant.attributes?.dosage && (
                <div className="item-dosage">
                  <p className="">
                    <span>{formatProductMessage({ id: 'dosage', defaultMessage: 'Dosage' })}: </span>
                    <span className="capitalize font-bold">{lineItem.variant.attributes.dosage}</span>
                  </p>
                </div>
              )}
              {lineItem.variant.attributes['packaging-size'] && (
                <div className="item-dosage">
                  <p className="">
                    <span>{formatProductMessage({ id: 'pack-size', defaultMessage: 'Pack Size' })}: </span>
                    <span className='capitalize font-semibold'>
                        {lineItem.variant.attributes['packaging-size']}
                    </span>
                  </p>
                </div>
              )}
              {lineItem.count && (
                <div className="item-dosage">
                  <p className="">
                    <span>{formatProductMessage({ id: 'item-quantity', defaultMessage: 'Quantity' })}: </span>
                    <span className='capitalize font-semibold'>
                        {lineItem.count}
                    </span>
                  </p>
                </div>
              )}

              <div className="cart-item-price-mobile">
                <div className="cart-item-price">
                  <Typography>{CurrencyHelpers.formatForCurrency(lineItem.totalPrice)}</Typography>
                </div>
                {lineItem.discountedPrice && <Price price={lineItem.discountedPrice} className="" />}
              </div>

          
          </div>

          <div className="cart-item-data-two">
      
            <div className="hidden md:flex">
              <div className="cart-item-price">
                <Typography>{CurrencyHelpers.formatForCurrency(lineItem.totalPrice)}</Typography>
              </div>
              {lineItem.discountedPrice && <Price price={lineItem.discountedPrice} className="" />}
            </div>

          
          </div>
          <div>
            {lineItem.discountedPrice && (
              <Price price={lineItem.discountedPrice} className="" />
            )}
          </div>
        </div>

      </div>

   
    </div>
  );
};

export default ItemPreview;
