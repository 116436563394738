/* eslint-disable import/order */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import Redirect from 'helpers/redirect';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import { NextFrontasticImage } from 'frontastic/lib/image';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { Carousel } from 'react-responsive-carousel';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { UserContext } from 'frontastic/lib/renderer';
import { callingCodes } from 'helpers/constants/callingCodes';
import { PhoneNumberUtil } from 'google-libphonenumber';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export interface RegisterProps {
  logo?: NextFrontasticImage;
  loginLink?: Reference;
}

const Register: React.FC<RegisterProps> = () => {
  //const Register = ({ logo, loginLink }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatSuccessMessage } = useFormat({ name: 'success' });
  const { formatMessage } = useFormat({ name: 'common' });

  const [phoneNumber, setPhoneNumber] = useState('');

  const onlyCountries = ['in', 'us', 'au', 'cn', 'dk', 'de', 'it', 'hk', 'pk', 'ae', 'gb'];

  //account actions
  const { register, loggedIn } = useAccount();

  const [isOtpBoxOpened, setOtpBoxOpened] = useState(false);
  const [phoneNumberPlaceholder, setPhoneNumberPlaceholder] = useState('+91 98765 43210');
  const [countryCode, setCountryCode] = useState('');

  //register data
  const [data, setData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '9999999999',
    agreement: false,
    userJourney: null,
    howAllverz: '',
    companydetail: '',
    billingCountry: '',
    companyDetail: '',
    otp: '',
  });
  //Password is visible while typing
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { contextValue, setContextValue } = useContext(UserContext);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [validAgreement, setValidAgreement] = useState('');
  const [validPhoneNumber, setValidPhoneNumber] = useState('');
  const [validPassword, setValidPassword] = useState('');
  const [validConfirmPassword, setValidConfirmPassword] = useState('');
  const [validEmail, setValidEmail] = useState('');
  const [customerGroupId, setCustomerGroupId] = useState('');
  //Toggles password visibility
  const togglePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);

  const toggleConfirmPasswordVisibility = useCallback(
    () => setIsConfirmPasswordVisible(!isConfirmPasswordVisible),
    [isConfirmPasswordVisible],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setCustomerGroupId(urlParams.get('customerGroupId'));
    //setValidEmail(urlParams.get('emailAddress'));
  }, []);

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const fName = e.target.name;
    const fValue = e.target.value;
    if (fName === 'password') {
      passwordValidation(fValue);
      handlePassword(fValue);
    } else if (fName === 'confirmPassword') {
      confirmPasswordValidation(fValue);
      handleConfirmPassword(fValue);
    } else if (fName === 'phoneNumber') {
      phoneNumberValiation(fValue);
      handlePhoneNumber(fValue);
    } else if (fName === 'email') {
      emailValidation(fValue);
      handleEmail(fValue);
    } else if (fName === 'otp') {
      emailValidation(fValue);
      handleEmail(fValue);
    } else if (fName === 'firstName') {
      handleFirstname(fValue);
    } else if (fName === 'lastName') {
      handleLastname(fValue);
    } else if (fName === 'userJourney') {
      handleUserJourney(fValue);
    } else if (fName === 'companydetail') {
      handleCompanyDetail(fValue);
    } else if (fName === 'howAllverz') {
      handleResource(fValue);
    } else if (fName === 'billingCountry') {
      handlebillingCountry(fValue);
    }
    setData({ ...data, [fName]: fValue });
  };
  const handleFirstname = (value) => {
    contextValue.firstName = value;
    setContextValue({ ...contextValue });
  };

  const handleLastname = (value) => {
    contextValue.lastName = value;
    setContextValue({ ...contextValue });
  };
  const handlePassword = (value) => {
    contextValue.password = value;
    setContextValue({ ...contextValue });
  };
  const handleConfirmPassword = (value) => {
    contextValue.confirmPassword = value;
    setContextValue({ ...contextValue });
  };
  const handleEmail = (value) => {
    contextValue.email = value;
    setContextValue({ ...contextValue });
  };
  const handlePhoneNumber = (value) => {
    contextValue.phoneNumber = value;
    setContextValue({ ...contextValue });
  };
  const handleUserJourney = (value) => {
    contextValue.userJourney = value;
    setContextValue({ ...contextValue });
  };
  const handleCompanyDetail = (value) => {
    contextValue.companyDetail = value;
    setContextValue({ ...contextValue });
  };
  const handleResource = (value) => {
    contextValue.Resource = value;
    setContextValue({ ...contextValue });
  };
  const handlebillingCountry = (value) => {
    contextValue.billingCountry = value;
    setContextValue({ ...contextValue });
  };
  const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e?.target?.name === 'agreement') {
      setData({ ...data, agreement: !data.agreement });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const passwordValidation = (password: string): void => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const valid = regex.test(password);
    if (valid) {
      setValidPassword(null);
    } else {
      setValidPassword(
        'Password must have a minimum of 8 characters and include one capital letter, a number and one special character, e.g. @*!',
      );
    }
  };

  const confirmPasswordValidation = (password: string): void => {
    setValidConfirmPassword(data.password === password ? null : "Passwords don't match");
  };

  const phoneNumberValiation = (phoneNumber: string): void => {
    /*
    Phone number validation using google-libphonenumber
    */
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse('+' + countryCode + phoneNumber));
    } catch (e) {
      valid = false;
    }
    if (valid) {
      setValidPhoneNumber(null);
    } else {
      setValidPhoneNumber('Must enter valid number');
    }
  };

  const emailValidation = (email: string): void => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = regex.test(email);
    if (valid) {
      setValidEmail(null);
    } else {
      setValidEmail('Enter valid email');
    }
  };

  const validate = () => {
    return !validPassword && !validConfirmPassword && !validEmail && !validPhoneNumber && !validAgreement;
  };

  const getCustomerPaylod = () => {
    if (customerGroupId) {
      return {
        email: contextValue?.email,
        password: contextValue?.password,
        firstName: contextValue?.firstName,
        lastName: contextValue?.lastName,
        phone_number: phoneNumber,
        work_email: contextValue?.email,
        come_to_know_from: contextValue?.Resource,
        update_product_service: contextValue?.billingCountry === 'Yes' ? true : false,
        terms_condition: data.agreement,
        role_type: contextValue?.userJourney ? contextValue?.userJourney : 'Buyer',
        companyApprovalStatus: customerGroupId ? 'Approved' : null,
        customerGroup: {
          typeId: 'customer-group',
          id: customerGroupId,
        },
      };
    } else {
      return {
        email: contextValue?.email,
        password: contextValue?.password,
        firstName: contextValue?.firstName,
        lastName: contextValue?.lastName,
        phone_number: phoneNumber,
        work_email: contextValue?.email,
        come_to_know_from: contextValue?.Resource,
        update_product_service: contextValue?.billingCountry === 'Yes' ? true : false,
        terms_condition: data.agreement,
        role_type: contextValue?.userJourney ? contextValue?.userJourney : 'Buyer',
      };
    }
  };

  // Verify Email
  const verifyEmail = () => {
    console.log('clicked');
    setOtpBoxOpened(!isOtpBoxOpened);
  };
  const handleTermsClick = () => {
    window.open('/terms-of-use', '_blank');
  };
  const handlePrivacyPolicyClick = () => {
    window.open('/privacy-policy', '_blank');
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('data', data);

    //validate data
    if (!validate()) return;
    //processing starts
    if (contextValue?.email.includes('drreddys.com')) {
      setError(
        formatErrorMessage({
          id: 'account.drl.id',
          defaultMessage: "Sorry. We couldn't create your account. Please login using your Dr Reddy's ID",
        }),
      );
      setSuccess('');
      return;
    }
    setLoading(true);

    // try registering the user with given credentials
    try {
      const response = await register(getCustomerPaylod());
      if (!response.accountId) {
        setError(
          formatErrorMessage({ id: 'account.create.fail', defaultMessage: "Sorry. We couldn't create your account.." }),
        );
        setSuccess('');
      } else {
        setError('');
        console.log('Account Created');
        setSuccess(
          formatSuccessMessage({
            id: 'account.created',
            defaultMessage: 'A verification email was sent to {email} ✓',
            values: { email: data.email },
          }),
        );
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      setSuccess('');
    }
    //processing ends
    setLoading(false);
  };
  const router = useRouter();
  //loggedin user redirects to previous page
  if (loggedIn) {
    router.push(Object.keys(router.query)[0]);
    //<Redirect target='/' />
  }

  return (
    <div className="sign_in_container sign_up_container flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      {/* <div className="mt-16 flex justify-center"> */}
      <div className="sign-in sign-up-parent widthAdjustment flex  lg:mr-auto">
        {/* <div className="grid max-w-[30%] bg-secondary-100 text-center" style={{ zIndex: 1 }}>
          <div className="carouselMain">
            <Carousel
              interval={4000}
              infiniteLoop
              showStatus={false}
              stopOnHover={true}
              swipeable={true}
              showArrows={false}
              autoPlay={true}
              showThumbs={false}
            >
              <div>
                <div className="pt-20 pr-5 pl-5 pb-16">
                  <img
                    className="carouselImg"
                    src="http://images.ctfassets.net/0xp5tii40ocr/4mo0GuJUJgX60fj6BtbUvA/1653bcf10a285d1bc78ab2cb4173378f/b_image.PNG"
                  />
                  <h2>How Allverz works?</h2>
                  <p>Step1 : Save favorite items to personal lists</p>
                </div>
              </div>
              <div>
                <div className="pt-20 pr-5 pl-5 pb-16">
                  <img
                    className="carouselImg"
                    src="http://images.ctfassets.net/0xp5tii40ocr/4mo0GuJUJgX60fj6BtbUvA/1653bcf10a285d1bc78ab2cb4173378f/b_image.PNG"
                  />
                  <h2>How Allverz works?</h2>
                  <p>Step1 : Save favorite items to personal lists</p>
                </div>
              </div>
              <div>
                <div className="pt-20 pr-5 pl-5 pb-16">
                  <img
                    className="carouselImg"
                    src="http://images.ctfassets.net/0xp5tii40ocr/4mo0GuJUJgX60fj6BtbUvA/1653bcf10a285d1bc78ab2cb4173378f/b_image.PNG"
                  />
                  <h2>How Allverz works?</h2>
                  <p>Step1 : Save favorite items to personal lists</p>
                </div>
              </div>
            </Carousel>
          </div>
          <div className="pt-[50%]">
            <p>
              Register User?{' '}
              <span className="cursor-pointer bottomLinkText">
                <Link href="/login">
                Sign-in
                </Link>
              </span>
            </p>
            <p>
            Have Trouble Logging in?{' '}
              <span className="bottomLinkText">
                <Link href="#">
                Get Help
                </Link>
              </span>
            </p>
          </div>
        </div>*/}
        {/* <div className="d-inline-block w-full max-w-[60%] rounded-sm bg-white px-6 pb-32 shadow-2xl dark:bg-primary-200"> */}
        <div className="sign-in-content registration-content w-full bg-white px-3 pb-32 2xl:px-6">
          <Link href="/login">
            <span className="sign_in_return_btn ml-5 flex cursor-pointer items-center lg:ml-0">
              <svg
                className="w-4 cursor-pointer text-accent-400 hover:text-accent-500"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.8625 3.225L13.3791 1.75L5.13745 10L13.3875 18.25L14.8625 16.775L8.08745 10L14.8625 3.225Z"
                  fill="#0068A3"
                />
              </svg>
              <p className="pl-2 text-accent-400">Return to sign in</p>
            </span>
          </Link>
          <form className="space-y-0 sm:space-y-7" onSubmit={handleSubmit}>
            <div className="py-6 text-center">
              <h2 className="text-3xl font-extrabold text-neutral-700">
                {formatAccountMessage({ id: 'signupwithAllverz', defaultMessage: 'Signup with Allverz' })}
              </h2>
              <h3 className="text-md mt-3 text-neutral-600">
                {formatAccountMessage({
                  id: 'enter.signupdetails',
                  defaultMessage: 'Enter your details to Sign-up',
                })}
              </h3>
            </div>
            <div className="col-md-12">
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="firstName" className="flex text-sm font-medium text-neutral-700">
                  {formatMessage({ id: 'firstName', defaultMessage: 'FirstName' })}
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="mt-1">
                  <input
                    // id="firstName"
                    name="firstName"
                    type="text"
                    autoComplete="firstName"
                    placeholder={formatAccountMessage({ id: 'firstName.enter', defaultMessage: 'First Name' })}
                    required
                    className="firstNameImg block w-full appearance-none rounded-sm border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={handleChange}
                    value={data.firstName}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="lastName" className="flex text-sm font-medium text-neutral-700">
                  {formatAccountMessage({ id: 'lastName', defaultMessage: 'Last Name' })}
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="relative mt-1">
                  <input
                    // id="lastName"
                    name="lastName"
                    type="text"
                    autoComplete="lastName"
                    // placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    placeholder={formatAccountMessage({ id: 'lastName.enter', defaultMessage: 'Last Name' })}
                    required
                    className="firstNameImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={handleChange}
                    value={data.lastName}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="col-md-6  mt-4 sm:mt-0">
                <label htmlFor="phoneNumber" className="flex text-sm font-medium text-neutral-700">
                  {formatAccountMessage({ id: 'phoneNumber', defaultMessage: 'Phone Number' })}
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="pnumber relative mt-1 flex border border-gray-300 focus:border-accent-400 focus:outline-none focus:ring-accent-400">
                  <PhoneInput
                    country={'in'}
                    onChange={(phone) => setPhoneNumber(phone)}
                    onlyCountries={onlyCountries}
                    value={phoneNumber}
                  />
                </div>
                {validPhoneNumber && <p className="errorMessage">{validPhoneNumber}</p>}
              </div>

              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="buyerseller" className="flex text-sm font-medium text-neutral-700">
                  {formatAccountMessage({ id: 'buyerseller', defaultMessage: 'Are you a' })}
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="relative mt-1">
                  <div className=" registerSelectUser flex w-full appearance-none rounded-sm border border-gray-300 p-2 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm">
                    <div className="form-check">
                      <input
                        className="form-check-input radioBtn"
                        type="radio"
                        name="userJourney"
                        // id="flexRadioDefault1"
                        value="Buyer"
                        onChange={handleChange}
                        checked={contextValue?.userJourney == 'Buyer' ? true : null}
                      />
                      <label className="form-check-label pl-1 pr-3" htmlFor="flexRadioDefault1">
                        Buyer
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input radioBtn "
                        type="radio"
                        name="userJourney"
                        // id="flexRadioDefault2"
                        value="Seller"
                        checked={contextValue?.userJourney == 'Seller' ? true : null}
                        onChange={handleChange}
                      />
                      <label className="form-check-label pl-1 pr-3" htmlFor="flexRadioDefault2">
                        Seller
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input radioBtn"
                        type="radio"
                        name="userJourney"
                        // id="flexRadioDefault2"
                        value="Both"
                        checked={contextValue?.userJourney == 'Both' ? true : null}
                        onChange={handleChange}
                      />
                      <label className="form-check-label pl-1" htmlFor="flexRadioDefault2">
                        Both
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* col-md-12 ends */}
            <div className="col-md-12">
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="companydetail" className="flex text-sm font-medium text-neutral-700">
                  {formatAccountMessage({ id: 'companydetail', defaultMessage: 'Company Details' })}
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="relative mt-1">
                  <input
                    // id="companydetail"
                    name="companydetail"
                    type="text"
                    autoComplete="companydetail"
                    placeholder={formatAccountMessage({ id: 'companydetail', defaultMessage: 'Company Name' })}
                    required
                    className="companyDetailImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={handleChange}
                    value={contextValue?.companyDetail}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="email" className="flex text-sm font-medium text-neutral-700">
                  {formatAccountMessage({ id: 'email', defaultMessage: 'Work Email' })}
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="email relative mt-1">
                  {isOtpBoxOpened ? (
                    <div className="relative">
                      <input
                        // id="otp"
                        name="otp"
                        type="otp"
                        autoComplete="otp"
                        placeholder={formatAccountMessage({ id: 'otp', defaultMessage: 'Please Enter the OTP' })}
                        required
                        className="emailImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                        onChange={handleChange}
                        value={data.otp}
                      />
                      <button
                        onClick={verifyEmail}
                        className="verifyEmail absolute top-0 right-0 mr-2 mt-3 bg-inherit pl-2 hover:underline"
                      >
                        Verify
                      </button>
                      {console.log('isOtpBoxOpened', isOtpBoxOpened)}
                    </div>
                  ) : (
                    <div className="emailT relative">
                      <input
                        // id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder={formatAccountMessage({ id: 'email', defaultMessage: 'Email@domain.com' })}
                        required
                        className="emailText emailImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 pr-10 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                        onChange={handleChange}
                        value={data.email}
                      />
                      <button
                        onClick={verifyEmail}
                        className="verifyEmail absolute top-0 right-0 mr-1 mt-3 bg-inherit pl-1 hover:underline"
                      >
                        Verify
                      </button>
                    </div>
                  )}
                </div>
                {validEmail && <p className="errorMessage">{validEmail}</p>}
              </div>
            </div>
            {/*col-md-12 ends */}
            <div className="col-md-12">
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="password" className="flex text-sm font-medium text-neutral-700">
                  {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="relative mt-1">
                  <input
                    // id="password"
                    name="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    autoComplete="password"
                    placeholder=""
                    required
                    className="passwordImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={handleChange}
                    value={data.password}
                  />
                  <span
                    className="absolute right-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600"
                    onClick={togglePasswordVisibility}
                  >
                    {!isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
                  </span>
                </div>
                {validPassword && <p className="errorMessage">{validPassword}</p>}
              </div>
              <div className="col-md-6 mt-4 sm:mt-0">
                <label htmlFor="password" className="flex text-sm font-medium text-neutral-700">
                  {formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm Password' })}
                  <span className="d-block pl-[5px] text-[21px] text-[#dc2626]">*</span>
                </label>
                <div className="relative mt-1">
                  <input
                    // id="confirm-password"
                    name="confirmPassword"
                    type={isConfirmPasswordVisible ? 'text' : 'password'}
                    autoComplete="confirm-password"
                    placeholder=""
                    required
                    className="passwordImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={handleChange}
                    value={data.confirmPassword}
                  />
                  <span
                    className="absolute right-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {!isConfirmPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
                  </span>
                </div>
                {validConfirmPassword && <p className="errorMessage">{validConfirmPassword}</p>}
              </div>
            </div>
            {/*col-md-12 ends */}
            <div className="col-md-12">
              <div className="col-md-6 mt-4 sm:mt-0">
                <label
                  htmlFor="knowAllverz"
                  className=" flex h-auto items-center text-sm font-medium text-neutral-700 sm:h-[50px]"
                >
                  {formatAccountMessage({ id: 'knowAllverz', defaultMessage: 'How did you come to know Allverz? ' })}
                </label>
                <div className="relative mt-1">
                  <select
                    // id="howAllverz"
                    name="howAllverz"
                    className="howAllverzImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 pl-10 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={handleChange}
                    value={contextValue?.Resource}
                  >
                    <option defaultChecked value="Select Source">
                      Select Source
                    </option>
                    <option value="Google">Google</option>
                    <option value="Allverz Marketing Email">Allverz Marketing Email</option>
                    <option value="Allverz Marketing Team">Allverz Marketing Team</option>
                    <option value="Reference from a person">Reference from a person</option>
                    <option value="Advertisement">Advertisement</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="Facebook">Facebook</option>
                    <option value="CPHI Website">CPHI Website</option>
                    <option value="Pharma Exhibitions">Pharma Exhibitions</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6  mt-4 pb-8 sm:mt-0">
                <label htmlFor="recieveproduct" className="block text-sm font-medium text-neutral-700">
                  {formatAccountMessage({
                    id: 'recieveproduct',
                    defaultMessage: 'Do you want to receive product and services updates from Allverz? ',
                  })}
                </label>
                <div className="relative mt-1">
                  <select
                    // id="billing-country"
                    name="billingCountry"
                    className="allverzUpdateImg block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 pl-10 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={handleChange}
                    value={data?.billingCountry}
                  >
                    <option defaultChecked value="Select Response">
                      Select Response
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
            </div>
            {/* col-md-12 ends */}
            <div className="col-md-12 signinCheckboxMain">
              <div className="col-md-12 flex">
                <div className="col-md-1">
                  <input
                    onChange={handleAgreementChange}
                    name="agreement"
                    className="agreementCheckbox"
                    type="checkbox"
                    checked={data.agreement}
                    required
                  />
                </div>
                <div className="col-md-11 agree-select">
                  <p>
                    By Selecting, you agree to our{' '}
                    <span className="termsText" onClick={handleTermsClick}>
                      Terms of Service
                    </span>{' '}
                    &{' '}
                    <span className="termsText" onClick={handlePrivacyPolicyClick}>
                      Privacy Policy
                    </span>
                  </p>
                  {validAgreement && <p className="errorMessage">{validAgreement}</p>}
                </div>
              </div>
            </div>
            {/* col-md-12 ends */}

            {success && <p className="col-md-12 mt-1 text-sm text-green-600">{success}</p>}
            {error && <p className="col-md-12 mt-1 text-sm text-accent-400">{error}</p>}
            <div className="col-md-12 signInBtnWrapper">
              <div className="col-md-6  mt-4 sm:mt-0">
                <button
                  type="button"
                  className="flex w-full justify-center rounded-sm border border-accent-400 bg-white py-2 px-4 text-sm font-medium text-accent-400 shadow-sm transition-colors duration-200 ease-out hover:bg-accent-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                  disabled={loading}
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  {formatAccountMessage({ id: 'back', defaultMessage: 'Back' })}
                </button>
              </div>
              <div className="col-md-6  mt-4 sm:mt-0">
                <button
                  type="submit"
                  className="register flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                  disabled={loading}
                  onClick={() => handleSubmit}
                >
                  {/* {formatAccountMessage({ id: 'signup', defaultMessage: 'Sign-up' })} */}
                  {formatAccountMessage({ id: 'register', defaultMessage: 'Register' })}
                </button>
              </div>
            </div>
            {/* <div>
              <label htmlFor="email" className="block text-sm font-medium text-neutral-700">
                {formatMessage({ id: 'email', defaultMessage: 'Email' })}
              </label>
              <div className="mt-2">
                <input
                //   id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder={formatAccountMessage({ id: 'email.enter', defaultMessage: 'Enter your email' })}
                  required
                  className="block w-full appearance-none rounded-sm border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  onChange={handleChange}
                />

              </div>
              </div> */}
            {/* <div>
              <label htmlFor="password" className="block text-sm font-medium text-neutral-700">
                {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
              </label>
              <div className="relative mt-1">
                <input
                //   id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  className="block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  onChange={handleChange}
                />

              </div>
            </div> */}

            {/* <div>
              <label htmlFor="password" className="block text-sm font-medium text-neutral-700">
                {formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm Password' })}
              </label>
              <div className="relative mt-1">
                <input
                //   id="confirm-password"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  className="block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  onChange={handleChange}
                />

              </div>
            </div>  */}
            {/* <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                disabled={loading}
              >
                {formatAccountMessage({ id: 'register', defaultMessage: 'Register' })}
              </button>
              <p className="mt-4 text-center text-sm text-neutral-600">
                {formatAccountMessage({ id: 'account.doNotHave', defaultMessage: "Don't have an account?" })}{' '}
                <ReferenceLink
                  target={loginLink}
                  className="font-medium text-accent-400 underline hover:text-accent-500"

                >
                 {formatAccountMessage({ id: 'account.login.here', defaultMessage: 'Login here' })}
                </ReferenceLink>
              </p>

            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
