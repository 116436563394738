/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  UserIcon,
  InformationCircleIcon,
  ClockIcon,
  LocationMarkerIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';

const QuoteLeftMenu = () => {
  const [selectedParent, setSelectedParent] = useState('My RFQ');
  const [selectedChild, setSelectedChild] = useState('Request For Quote');
  const router = useRouter();
  const data = [
    {
      id: 1,
      title: 'My Profile',
      component: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.291 13.3333V12.0741C13.291 11.4061 13.1251 10.5001 12.627 10.0278C12.1288 9.55546 11.3392 9.55556 10.6348 9.55556H5.32227C4.61778 9.55556 3.66221 9.55546 3.16406 10.0278C2.66592 10.5001 2.66602 11.4061 2.66602 12.0741V13.3333M10.6348 4.51852C10.6348 5.90946 9.44552 7.03704 7.97852 7.03704C6.51151 7.03704 5.32227 5.90946 5.32227 4.51852C5.32227 3.12758 6.51151 2 7.97852 2C9.44552 2 10.6348 3.12758 10.6348 4.51852Z"
            stroke="#344054"
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      ),
      url: 'account',
    },
    { id: 2, title: 'Company Information', component: <InformationCircleIcon />, url: 'company-info' },
    {
      id: 3,
      title: 'Admin Dashboard',
      component: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.00065 1.33337C4.32065 1.33337 1.33398 4.32004 1.33398 8.00004C1.33398 11.68 4.32065 14.6667 8.00065 14.6667C11.6807 14.6667 14.6673 11.68 14.6673 8.00004C14.6673 4.32004 11.6807 1.33337 8.00065 1.33337ZM13.2873 7.33337H8.66732V2.71337C11.074 3.01337 12.9873 4.92671 13.2873 7.33337ZM2.66732 8.00004C2.66732 5.28671 4.70732 3.04004 7.33398 2.71337V13.2867C4.70732 12.96 2.66732 10.7134 2.66732 8.00004ZM8.66732 13.2867V8.66671H13.2873C12.9873 11.0734 11.074 12.9867 8.66732 13.2867Z"
            fill="#303030"
          />
        </svg>
      ),
      children: [
        {
          id: 1,
          title: 'Admin Account',
          url: 'admin-dashboard',
        },
        {
          id: 2,
          title: 'Member List',
          url: 'members/members-list',
        },
      ],
    },
    {
      id: 4,
      title: 'Order History',
      component: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 2C5.68667 2 3 4.68667 3 8H1L3.59333 10.5933L3.64 10.6867L6.33333 8H4.33333C4.33333 5.42 6.42 3.33333 9 3.33333C11.58 3.33333 13.6667 5.42 13.6667 8C13.6667 10.58 11.58 12.6667 9 12.6667C7.71333 12.6667 6.54667 12.14 5.70667 11.2933L4.76 12.24C5.84667 13.3267 7.34 14 9 14C12.3133 14 15 11.3133 15 8C15 4.68667 12.3133 2 9 2ZM8.33333 5.33333V8.66667L11.1667 10.3467L11.68 9.49333L9.33333 8.1V5.33333H8.33333Z"
            fill="#303030"
          />
        </svg>
      ),
      children: [
        {
          id: 1,
          title: 'All Order List',
          url: 'orders/order-list',
        },
        {
          id: 2,
          title: 'Sample Order List',
          url: 'orders/sample-order-list',
        },
      ],
    },
    { id: 5, title: 'Addresses', component: <LocationMarkerIcon />, url: 'addresses' },
    {
      id: 6,
      title: 'My RFQ',
      component: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 2C5.68667 2 3 4.68667 3 8H1L3.59333 10.5933L3.64 10.6867L6.33333 8H4.33333C4.33333 5.42 6.42 3.33333 9 3.33333C11.58 3.33333 13.6667 5.42 13.6667 8C13.6667 10.58 11.58 12.6667 9 12.6667C7.71333 12.6667 6.54667 12.14 5.70667 11.2933L4.76 12.24C5.84667 13.3267 7.34 14 9 14C12.3133 14 15 11.3133 15 8C15 4.68667 12.3133 2 9 2ZM8.33333 5.33333V8.66667L11.1667 10.3467L11.68 9.49333L9.33333 8.1V5.33333H8.33333Z"
            fill="#303030"
          />
        </svg>
      ),
      children: [
        {
          id: 1,
          title: 'RFQ Listing',
          url: 'quotes/quotes-list',
        },
        {
          id: 2,
          title: 'Request For Quote',
          url: 'quotes/request-for-quote',
        },
      ],
    },
  ];

  useEffect(() => {
    const x = localStorage.getItem('quote-left-menu-list');
    if (x === 'true') {
      setSelectedChild('RFQ Listing');
      localStorage.removeItem('quote-left-menu-list');
    }
  });

  useEffect(() => {
    const path = router.asPath.substring(1);
    const matchedParentRoute = data.find((el) => {
      return el.url === path || el.children?.find((subEl) => subEl.url === path);
    });
    setSelectedParent(matchedParentRoute?.title);
    const matchedChildRoute = matchedParentRoute?.children?.find((el) => el.url === path);
    setSelectedChild(matchedChildRoute?.title);
  }, [router.asPath]);

  const handleParentClick = (item) => {
    setSelectedParent(item.title);
    if (item.url) {
      router.push(item.url);
    }
  };

  return (
    <div className="quote-container-main lg:ml-[100px]">
      <div className="quote-part-one">
        <ul>
          {data?.map((item) => {
            const haveChildren = !!item.children;
            const isParentSelected = selectedParent === item.title;
            const selectedItemClasses = `quote-menu-item-header ${
              isParentSelected ? 'quote-menu-item-header-selected' : ''
            }`;
            const selectedTextClasses = `quote-menu-item-text ${
              isParentSelected ? 'quote-menu-item-text-selected' : ''
            }`;
            return (
              <li className="quote-menu-item" key={item.id}>
                <div className={selectedItemClasses}>
                  <span className="dashboard-icon ml-1 h-5 w-5 text-neutral-600">{item.component}</span>
                  <div className={selectedTextClasses} onClick={() => handleParentClick(item)}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {item.title}
                      {haveChildren && (
                        <span className="dashboard-icon h-5 w-5 text-neutral-600" style={{ paddingRight: '2px' }}>
                          {isParentSelected ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ margin: '6px 0px 0px -25px' }}>
                  {haveChildren && isParentSelected && (
                    <ul>
                      {item.children.map((item) => {
                        const childClasses = `quote-menu-item-text quote-menu-sub-item-text ${
                          selectedChild === item.title ? 'quote-menu-sub-item-selected' : ''
                        }`;
                        return (
                          <li key={item.title} onClick={() => setSelectedChild(item.title)} className={childClasses}>
                            <NextLink href={item.url || ''}>{item.title}</NextLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default QuoteLeftMenu;
