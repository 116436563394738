
import VendorwiseTotal from 'components/commercetools-ui/cart/vendorwiseTotal';
import React from 'react';
import ItemPreview from './order-summary-preview/item-preview';
import TermsConditions from 'components/commercetools-ui/cart/termsConditions';


function OrderSummaryItems({ cartData }) {
  const vendorWiseItems = cartData?.lineItems?.reduce((group, item) => {
    const vendorName = item.variant?.attributes['vendor-name'];
    group[vendorName] = group[vendorName] || [];
    group[vendorName].push(item);
    return group;
  }, {});
  const vendorTotal = (vendorName: string) => {
    const items = vendorWiseItems[vendorName];
    return (
      <div>
        <>
          <VendorwiseTotal items={items} />{' '}
        </>
      </div>
    );
  };
  return (
    <section aria-labelledby="cart-heading" className="cart-items-main-section">
      <div className="cart-items-section">
        <h1 className="order-preview-title">Order Summary ({cartData?.lineItems?.length})</h1>

        {vendorWiseItems &&
          Object.keys(vendorWiseItems).map((vendor, index) => (
            <>
              <div key={vendor} className="cart-vendor-header-main">
                <div className="cart-vendor-header">
                  <span className="cart-vendor-sold">Sold and Fulfilled by : </span>
                  <span style={{ fontSize: '16px', fontWeight: '700', borderBottom: '1px solid' }}>{vendor}</span>
                </div>
                <ul className="" role="list">
                  {vendorWiseItems[vendor].map((lineItem, i) => (
                    <li key={i} className="cart-vendor-li">
                      {i < cartData?.lineItems.length && <hr className="md:hidden" />}

                      <ItemPreview lineItem={lineItem} />
                    </li>
                  ))}
                </ul>
                <div className="cart-list-bottom" style={{ marginLeft: '25px' }}>
                  <div className="cart-list-bottom-terms">
                    <TermsConditions key={vendor} vendor={vendor} showVendorLink={false} />
                  </div>
                  <div className="shippingBlock" style={{ flex: '3' }}>
                    {vendorTotal(vendor)}
                  </div>
                </div>
              </div>
              {vendorWiseItems && index !== Object.keys(vendorWiseItems).length - 1 && (
                <div className="iem-separator"></div>
              )}
            </>
          ))}
      </div>
    </section>
  );
}

export default OrderSummaryItems;
