import React from 'react'

function  BehalfDetails() {
  return (
    <div>
      <p className='behalfTitle'>Team Member</p>
      <p className="behalfSelect">
        <select id="">
          <option value="">Select Team Member</option>
          <option value="">Select Team Member</option>
          <option value="">Select Team Member</option>
          <option value="">Select Team Member</option>
        </select>
      </p>
    </div>
  )
}

export default BehalfDetails
