import React from 'react';
import { Product } from '@Types/product/Product';
import Slider, { SliderProps } from 'components/commercetools-ui/slider';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mobile, tablet, desktop, largeDesktop, hugeDesktop } from 'helpers/utils/screensizes';
import APITile from './api-tile';

export interface Props {
  recentlyViewedProducts?: Product[];
  popularProducts?: any;
  mostPopularTile?: boolean;
}

export default function APISlider({ recentlyViewedProducts, popularProducts, mostPopularTile }: Props) {
  const [isMobileSize] = useMediaQuery(tablet);
  const [isTabletSize] = useMediaQuery(desktop);
  const [isDesktopSize] = useMediaQuery(largeDesktop);
  const [isLargeDesktopSize] = useMediaQuery(hugeDesktop);

  const sliderFixedMood: SliderProps = {
    slidesPerView: isMobileSize ? (isTabletSize ? (isDesktopSize ? (isLargeDesktopSize ? 5 : 4) : 4) : 2) : 2,
    arrows: isTabletSize ? true : false,

    dots: true,
  };
  const sliderConfiguration: SliderProps = sliderFixedMood;
  return (
    <div className=" mt-8">
      <div className=" overflow-x-auto ">
        {!mostPopularTile ? (
          <>
            <Slider {...sliderConfiguration}>
              {recentlyViewedProducts.slice(0, 15).map((product, index: number) => (
                //   <Tile {...product} key={index} />
                <APITile
                  key={index}
                  image={product.variants[0].images[0]}
                  title={product.name}
                  subtitle={product.variants[0].attributes['cas-no']}
                  grade={product.variants[0].attributes.grade}
                  price={product.variants[0].prices}
                  _url={product._url}
                  product={product}
                />
              ))}
            </Slider>
          </>
        ) : (
          <>
            <Slider {...sliderConfiguration}>
              {popularProducts &&
                Array.isArray(popularProducts) &&
                popularProducts
                  ?.slice(0, 15)
                  ?.map((product, index: number) => (
                    <APITile
                      key={index}
                      image={product.productProjections.masterVariant.images[0]?.url}
                      title={product.productProjections.name['en-IN']}
                      subtitle={product.productProjections.masterVariant.attributes[0].value['en-IN']}
                      grade={product.productProjections.masterVariant.attributes[2].value}
                      price={product.productProjections.masterVariant?.prices[0]?.value}
                      _url={`/${product.productProjections.masterVariant.sku}/p/${product.productProjections.slug['en-IN']}`}
                      product={product}
                    />
                  ))}
            </Slider>
          </>
        )}
      </div>
    </div>
  );
}
