import React from 'react';
import { HeartIcon } from '@heroicons/react/outline';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';

interface WishListButtonProps {
  wishlistItemCount?: number;
  wishlistLink?: Reference;
}

const WishListButton: React.FC<WishListButtonProps> = ({ wishlistItemCount, wishlistLink }) => {
  //i18n messages
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });

  return (
    <div className="flow-root">
      <ReferenceLink
        ariaLabel="wishlist button"
        target={wishlistLink}
        className="group relative -m-2 flex items-center p-2"
      >
        <svg className='headerProjectIcon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 6.5V4.5H10V6.5H14ZM4 8.5V19.5H20V8.5H4ZM20 6.5C21.11 6.5 22 7.39 22 8.5V19.5C22 20.61 21.11 21.5 20 21.5H4C2.89 21.5 2 20.61 2 19.5L2.01 8.5C2.01 7.39 2.89 6.5 4 6.5H8V4.5C8 3.39 8.89 2.5 10 2.5H14C15.11 2.5 16 3.39 16 4.5V6.5H20Z" fill="#003769"/>
        </svg>
        {wishlistItemCount > 0 && (
          <>
            <span className="absolute -top-0 -right-1 h-4 w-4 rounded-full bg-accent-400 hover:bg-accent-500">
              <span className="flex h-full w-full items-center justify-center text-xs text-white group-hover:text-white">
                {wishlistItemCount}
              </span>
            </span>
            <span className="sr-only">
              {formatWishlistMessage({
                id: 'wishlist.items.in.view',
                defaultMessage: 'items in wishlist, view wishlist',
              })}
            </span>
          </>
        )}
      </ReferenceLink>
    </div>
  );
};

export default WishListButton;
