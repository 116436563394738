import React from 'react';
import { useMsal } from '@azure/msal-react';

function AzureAuth() {
  const { instance, accounts, inProgress } = useMsal();

  if (inProgress === 'login') {
    return (
      <button
        className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-[14px] font-medium tracking-[1px] text-white shadow-sm transition-colors duration-200 ease-out disabled:bg-gray-200"
        disabled={true}
      >
        SSO Login
      </button>
    );
  } else {
    return (
      <>
        <button
          className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-[14px] font-medium tracking-[1px] text-white shadow-sm transition-colors duration-200 ease-out disabled:bg-gray-200"
          onClick={() => instance.loginRedirect()}
        >
          SSO Login
        </button>
      </>
    );
  }
}

export default AzureAuth;
