import React, { useEffect, useState } from 'react';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import VendorListItems from './vendor-list-items';
import Loader from '../loader';

const alphabets = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '0-9',
];

function VendorListing({ scrollUp }) {
  const [sortedData, setSortedData] = useState<any>();
  const [sectionedData, setSectionedData] = useState<{ [letter: string]: any[] }>();
  const [openTab, setOpenTab] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(68);
  const [loading, setLoading] = useState(true);

  const scrollToId = (elementId: string, index) => {
    // setOpenTab(index);
    const ele: HTMLElement = document.getElementById(elementId);
    ele && ele.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const reqHeaders = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  };

  const fetchData = async () => {
    const response = await commercetoolsAPIClient.get(
      `/custom-objects?limit=${limit}&offset=${offset}&where=container%3D"seller-portal"`,
      reqHeaders,
    );
    const data = await response.data;
    setSortedData(
      data.results.sort(function (a, b) {
        const textA = a.value.vendorName.toUpperCase();
        const textB = b.value.vendorName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }),
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const sectionedItems = {};
    if (sortedData) {
      sortedData.forEach((item) => {
        const firstLetter = item.value.vendorName?.charAt(0).toUpperCase();
        if (!sectionedItems[firstLetter]) {
          sectionedItems[firstLetter] = [];
        }
        sectionedItems[firstLetter].push(item);
      });
      setSectionedData(sectionedItems);
      setLoading(false);
    }
  }, [sortedData]);

  return (
    <>
      {loading && <Loader />}
      <div className="mt-12 ml-5 mr-10 md:mx-0 md:mt-0">
        <div className="vendor-list-alphabets-container md:sticky md:top-24 md:bg-[#fafafa] md:pt-24">
          {alphabets.map((item, index) => (
            <div
              className={
                index === openTab ? 'vendor-list-alphabets-list activeAlphabetTab' : 'vendor-list-alphabets-list'
              }
              key={index}
              onClick={() => scrollToId(item, index)}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="mt-10 md:mt-0 lg:pb-12">
          {sectionedData &&
            Object.entries(sectionedData).map(([letter, items]) => (
              <VendorListItems
                key={letter}
                letter={letter}
                items={items}
                setOpenTab={setOpenTab}
                index={alphabets?.indexOf(letter)}
              />
            ))}
        </div>
      </div>
    </>
  );
}

export default VendorListing;
