
import React,{useState} from 'react';
import {LocationMarkerIcon} from '@heroicons/react/outline';

const SellerAdditionalDetails= ({address,orgType}) => {
  const data = [
    { id: 1, title: 'Location', component: <LocationMarkerIcon />,content:`${address?.city} , ${address?.country}`},
    { id: 2, title: 'Business Type', component: <LocationMarkerIcon />,content:`${orgType}`},
  ]
  return (
    <div className='mt-[20px] seller-add-details'>
     {data?.map((item, index) => (
              <div key={item?.id} className='flex mt-[13px]'>
                <span className='dashboard-icon ml-1 h-5 w-5 text-neutral-600'>{item?.component}</span>
                <p className='seller-location-bsns font-bold ml-[4px]'>{item?.title} :</p>
                <p className='seller-location-bsns lowercase ml-[4px]'>{item?.content}</p>
              </div>
            ))}
    </div>
  );
};

export default SellerAdditionalDetails;