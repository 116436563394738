// import { Carousel } from 'react-responsive-carousel';

export default function ProductImageQuote({ images,variant}) {
    return (
        <div>
            {/* <Carousel> */}
                {images?.map((image) => (
                    <div key={image.id}>
                        <img className="quote_image" src={image.src} />
                    </div>
                ))}
            {/* </Carousel> */}

        </div>

    )
}