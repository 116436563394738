import { MouseEvent, useEffect, useState } from 'react';
import Link from 'next/link';
import NextLink from 'next/link';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useTranslation, Trans } from 'react-i18next';
import { useCart } from 'frontastic/provider';
import DiscountForm from '../discount-form';
import Price from '../price';
import axios from 'axios';
import Spinner from '../spinner';
import { LOADER_ICON } from '../loader/Loader-icon';
import { BACKEND_AMP_BASE_URL, categoryCodes } from 'helpers/constants/envVariables';

interface Props {
  readonly cart: Cart;
  readonly onSubmit?: (e: MouseEvent) => void;
  readonly submitButtonLabel?: string;
  readonly disableSubmitButton?: boolean;
  readonly showSubmitButton?: boolean;
  readonly showDiscountsForm?: boolean;

  termsLink?: Reference;
  cancellationLink?: Reference;
  privacyLink?: Reference;
}

const OrderSummary = ({
  cart,
  onSubmit,
  showSubmitButton = true,
  showDiscountsForm = true,
  submitButtonLabel,
  disableSubmitButton,
  termsLink,
  cancellationLink,
  privacyLink,
}: Props) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { t } = useTranslation(['checkout']);
  const [shippingAmount, setShppingAmount] = useState<number>(0);
  const [currencyCode, setCurrencyCode] = useState<string>('INR');

  const [freezeTrasfer, setFreezeTransfer] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { getShippingMethods } = useCart();

  const punchurl = localStorage.getItem('punchoutUrl');
  let hookUrl = localStorage.getItem('hookUrl');
  const tokenForPunchout = localStorage.getItem('token');
  const tokenCategory = localStorage.getItem('BearerToken');
  const sapClient = localStorage.getItem('sapClient');
  const existingLineItemsJSON = localStorage.getItem('lineItems');
  const existingLineItems = JSON.parse(existingLineItemsJSON);

  function removeCartItemsAfterTransfer() {
    const cartId = cart?.cartId;
    const removeItemUrl = `${BACKEND_AMP_BASE_URL}/aep/punchout/v1/cart/linItems?cartId=${cartId}`;
    axios
      .delete(removeItemUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenCategory}`,
        },
      })
      .then((response) => {
        console.log('remove items api call done');
      })
      .catch((error) => {
        console.error('Error from transfer cart API:', error);
      });
  }
  const handleTransferCart = async (e) => {
    e.preventDefault();
    const form = document.createElement('form');
    form.method = 'POST';
    hookUrl += `&sap-client=${sapClient}`;
    form.action = hookUrl;
    const fieldData = [];
    cart?.lineItems?.forEach((item, index) => {
      if (item?.mercQuantity == 0) {
        return;
      }
      let notes = '';
      let rdd = '';
      if (existingLineItems?.hasOwnProperty(item?.lineItemId)) {
        const matchingItem = existingLineItems[item?.lineItemId];
        notes = matchingItem?.notes;
        rdd = matchingItem?.RDD;
      }
      let longTextValue = `name:${item?.name}`;
      if (item?.variant?.attributes['supplier-part-number'] != null) {
        longTextValue += `,supplier part number:${item?.variant?.attributes['supplier-part-number']}`;
      }

      if (item?.variant?.attributes['cas-no'] != null) {
        longTextValue += `,CAS-No:${item?.variant?.attributes['cas-no']}`;
      }
      const categoryNames = Object.keys(categoryCodes);
      let matchingValue = null;
      if (item?.categoryKey) {
        if (categoryNames.includes(item.categoryKey)) {
          matchingValue = categoryCodes[item.categoryKey];
        }
      }

      // const packagingSize = item?.variant?.attributes["packaging-size"];
      const removeSpace = item?.variant?.attributes['packaging-size'].replace(/[0-9\s]+/g, '');
      let itemUnit = '';
      removeSpace == 'gms' || removeSpace == 'GMS' ? (itemUnit = 'G') : (itemUnit = removeSpace);
      fieldData.push({ name: '~target', value: '' });
      fieldData.push({ name: `NEW_ITEM-DESCRIPTION[${index + 1}]`, value: item?.name });
      fieldData.push({ name: `NEW_ITEM-MATGROUP[${index + 1}]`, value: matchingValue });
      fieldData.push({ name: `NEW_ITEM-QUANTITY[${index + 1}]`, value: item?.count });
      fieldData.push({ name: `NEW_ITEM-UNIT[${index + 1}]`, value: itemUnit });
      fieldData.push({ name: `NEW_ITEM-PRICE[${index + 1}]`, value: item?.prices?.centAmount / 100 });
      fieldData.push({ name: `NEW_ITEM-PRICEUNIT[${index + 1}]`, value: '1' });
      fieldData.push({ name: `NEW_ITEM-CURRENCY[${index + 1}]`, value: item?.prices?.currencyCode });
      fieldData.push({ name: `NEW_ITEM-LEADTIME[${index + 1}]`, value: '1' });
      fieldData.push({ name: `NEW_ITEM-VENDOR[${index + 1}]`, value: item?.variant?.attributes['vendor-id'] });
      fieldData.push({
        name: `NEW_ITEM-VENDORMAT[${index + 1}]`,
        value: item?.variant?.attributes['supplier-part-number'],
      });
      fieldData.push({ name: `NEW_ITEM-MANUFACTCODE[${index + 1}]`, value: '' });
      fieldData.push({ name: `NEW_ITEM-MANUFACTMAT[${index + 1}]`, value: '' });
      fieldData.push({ name: `NEW_ITEM-EXT_QUOTE_ID[${index + 1}]`, value: cart.cartId });
      fieldData.push({ name: `NEW_ITEM-EXT_QUOTE_ITEM[${index + 1}]`, value: index + 1 });
      fieldData.push({ name: `NEW_ITEM-LONGTEXT_1:132[${index + 1}]`, value: longTextValue });
      fieldData.push({ name: `NEW_ITEM-CUST_FIELD1[${index + 1}]`, value: '' });
      fieldData.push({ name: `NEW_ITEM-CUST_FIELD4[${index + 1}]`, value: '' });
      fieldData.push({ name: `NEW_ITEM-CUST_FIELD5[${index + 1}]`, value: item?.variant?.attributes['hsn-code'] });
      fieldData.push({ name: `NEW_ITEM-CUST_FIELD4[${index + 1}]`, value: notes });
      fieldData.push({ name: `NEW_ITEM-CUST_FIELD4[${index + 1}]`, value: rdd });
    });

    for (const data of fieldData) {
      const inputField = document.createElement('input');
      inputField.name = data.name;
      inputField.type = 'HIDDEN';
      inputField.value = data.value;
      form.appendChild(inputField);
    }

    removeCartItemsAfterTransfer();
    document.body.appendChild(form);
    form.submit();
  };
  // new ends
  // call api and send cart data json from here
  // const cartData = cart
  // const transferUrl = `${BACKEND_AMP_BASE_URL}/aep/punchout/v1/cart/transfer?HOOK_URL_CT=${hookUrl}`
  // setFreezeTransfer(true)
  // axios.post(transferUrl, cartData, {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${tokenForPunchout}`,
  //   },
  //   })
  //   .then(response => {
  //        setLoading(true)
  //        window.close()
  //   })
  //   .catch(error => {

  //         console.error('Error from transfer cart API:', error);
  //   });
  //  return

  // const handleTransferCart = async (e)=>{
  //   e.preventDefault();
  //   // call api and send cart data json from here
  //   const cartData = cart
  //   const transferUrl = 'https://dev-api.allverz.com/aep/punchout/v1/cart/transfer'
  //   const requestData = {
  //      cart,
  //      HOOK_URL_CT: hookUrl,
  //   }
  //   setFreezeTransfer(true)
  //   axios.post(transferUrl, requestData, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${tokenForPunchout}`,
  //     },
  //     })
  //     .then(response => {
  //          setLoading(true)
  //          window.close()
  //     })
  //     .catch(error => {

  //           console.error('Error from transfer cart API:', error);
  //     });
  //    return
  // }

  // useEffect(() => {
  //   cart?.lineItems?.forEach(item => {
  //         if(item?.variant?.attributes['vendor-id'] == "507078"){
  //           const productId =  item.productId
  //           const sku =  item.variant.sku
  //           // const updatedPrice = item.prices.centAmount

  //         }
  //   });
  // }, []);

  const vendorWiseItems = cart?.lineItems?.reduce((group, item) => {
    const vendorName = item.variant?.attributes['vendor-name'];
    group[vendorName] = group[vendorName] || [];
    group[vendorName].push(item);
    return group;
  }, {});

  useEffect(() => {
    if (vendorWiseItems) {
      getShippingMethods().then((data: any) => {
        setShppingAmount(data[0]?.rates[0]?.price?.centAmount * Object.keys(vendorWiseItems).length);
        setCurrencyCode(data[0]?.rates[0]?.price?.currencyCode);
      });
    }
  }, [vendorWiseItems]);

  const submitButtonClassName = `${disableSubmitButton ? 'opacity-75 pointer-events-none' : ''} ${
    !showDiscountsForm ? 'mt-7' : ''
  } w-full rounded-sm border border-transparent py-3 px-4 text-base shadow-sm font-medium text-white bg-accent-400 focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 focus:ring-offset-gray-50`;

  const interpolatedComponents = [
    <ReferenceLink key={0} className="cursor-pointer font-medium text-accent-500 hover:underline" target={termsLink} />,
    <ReferenceLink
      key={1}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={cancellationLink}
    />,
    <ReferenceLink
      key={2}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={privacyLink}
    />,
  ];

  const totalTaxes = cart?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0);

  const productPrice = cart?.lineItems?.reduce((a, b: LineItem) => {
    if (b.discountedPrice) {
      return a + b.discountedPrice.centAmount * b.count;
    } else {
      return a + b.prices?.centAmount * b.count;
    }
  }, 0);

  const discountPrice = cart?.lineItems?.reduce((a, b) => {
    return (
      a +
      b.count *
        b.discounts.reduce((x, y) => {
          return x + y.discountedAmount.centAmount;
        }, 0)
    );
  }, 0);

  return (
    <>
      <section aria-labelledby="summary-heading" className="summary-heading">
        <h1 style={{ fontSize: '20px', marginBottom: '20px', fontWeight: '700' }}>
          Shopping Cart Total ({cart?.lineItems?.length})
        </h1>
        <dl className="mt-6">
          <div className="sum-row flex items-center justify-between">
            <dt className="text-md text-gray-600 dark:text-light-100">
              {formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}
            </dt>
            <dd>
              <Price
                price={{
                  centAmount: productPrice,
                  currencyCode: cart?.sum?.currencyCode,
                }}
                className="font-medium text-gray-900 dark:text-light-100"
              />
            </dd>
          </div>

          {/* <div className="flex items-center justify-between pt-4">
            <dt className="text-md dark:text-light-100 flex items-center text-gray-600">
              <span>{formatCartMessage({ id: 'shipping.estimate', defaultMessage: 'Shipping estimate' })}</span>
            </dt>
            <dd>
              <Price
                price={{
                  centAmount: shippingAmount,
                  currencyCode: currencyCode,
                }}
                className="dark:text-light-100 font-medium text-gray-900"
              />
            </dd>
          </div> */}

          <div className="flex items-center justify-between pt-4">
            <dt className="text-md flex text-gray-600 dark:text-light-100">
              <span>{formatCartMessage({ id: 'discounts', defaultMessage: 'Discount' })}</span>
            </dt>
            <dd>
              <Price
                price={
                  {
                    fractionDigits: 0,
                    centAmount: discountPrice == 0 ? 0 : -discountPrice,
                    currencyCode: cart?.sum?.currencyCode,
                  } || {}
                }
                className="font-medium text-gray-900 dark:text-light-100"
              />
            </dd>
          </div>

          {/* {cart?.taxed && (
            <div className="flex items-center justify-between pt-4">
              <dt className="text-md dark:text-light-100 flex text-gray-600">
                <span>{cart?.taxed.taxPortions[0].name}: </span>
              </dt>
              <dd>
                <Price
                  price={
                    {
                      fractionDigits: 2,
                      centAmount: cart?.taxed?.taxPortions[0]?.amount.centAmount,
                      currencyCode: cart?.sum?.currencyCode,
                    } || {}
                  }
                  className="dark:text-light-100 font-medium text-gray-900"
                />
              </dd>
            </div>
          )}

            {cart?.taxed && (
            <div className="flex items-center justify-between pt-4">
              <dt className="text-md dark:text-light-100 flex text-gray-600">
                <span>{cart?.taxed.taxPortions[1]?.name}: </span>
              </dt>
              <dd>
                <Price
                  price={
                    {
                      fractionDigits: 2,
                      centAmount: cart?.taxed?.taxPortions[1]?.amount.centAmount,
                      currencyCode: cart?.sum?.currencyCode,
                    } || {}
                  }
                  className="dark:text-light-100 font-medium text-gray-900"
                />
              </dd>
            </div>
          )} */}

          <div className="cart-grand-total flex items-center justify-between pt-4">
            <dt className="text-base font-medium text-gray-900 dark:text-light-100">
              {formatCartMessage({ id: '', defaultMessage: 'Total Price' })}
            </dt>
            <dd>
              <Price
                price={{
                  centAmount: cart?.sum?.centAmount,
                  currencyCode: cart?.sum?.currencyCode,
                }}
                className="text-base font-medium text-gray-900 dark:text-light-100"
              />
            </dd>
          </div>
        </dl>
        {showDiscountsForm && <DiscountForm cart={cart} className="py-10" />}
        {punchurl ? (
          freezeTrasfer ? (
            <div className="cart-checkout-btn">
              <button
                type="submit"
                className="transfer-cart-btn  mt-7 w-full
                 rounded-sm border 
                border-transparent bg-accent-400 py-3 px-4 
                text-base font-medium text-white shadow-sm 
                focus:outline-none focus:ring-2 focus:ring-accent-500 
                focus:ring-offset-2 focus:ring-offset-gray-50"
                disabled
              >
                Transfer Cart
              </button>
              <div className="transfer-cart-spinner">
                <Spinner />
              </div>
            </div>
          ) : (
            <div className="cart-checkout-btn">
              <button type="submit" onClick={handleTransferCart} className={submitButtonClassName}>
                Transfer Cart
              </button>
            </div>
          )
        ) : (
          showSubmitButton && (
            <div className="cart-checkout-btn">
              <button type="submit" onClick={onSubmit} className={submitButtonClassName}>
                {submitButtonLabel || formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' })}
              </button>

              {submitButtonLabel ===
                formatCartMessage({ id: 'ContinueAndPay', defaultMessage: 'Continue and pay' }) && (
                <p className="px-1 py-5 text-center text-xs">
                  <Trans i18nKey="disclaimer" t={t} components={interpolatedComponents} />
                </p>
              )}
            </div>
          )
        )}
        <div className="flex justify-between rounded-md pt-4 " role="group">
          <NextLink href="">
            <button className="share-cart">Share Cart</button>
          </NextLink>
          <NextLink href="">
            <button className="share-cart">Save Cart</button>
          </NextLink>
        </div>
        <div style={{ backgroundColor: '#F3F5FD', marginTop: '20px' }}>
          <p className="continue-sidebar-btn">
            <Link href={``}>Continue shopping</Link>
          </p>
        </div>
        <div className="terms-sidebar px-5" style={{ fontSize: '14px' }}>
          <p className="font-bold" style={{ marginBottom: '10px', marginLeft: '-15px' }}>
            Allverz Terms and Conditions
          </p>
          <ul className="terms-list">
            <li>All prices shown exclude GST</li>
            <li>All information is subject to change without information</li>
            <li>All prices shown exclude GST</li>
            <li>All information is subject to change without information</li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default OrderSummary;
